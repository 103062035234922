"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ThemeName = void 0;
/** The simplified set of easy values for Theme.name */
var ThemeName;
(function (ThemeName) {
    ThemeName["Light"] = "Light";
    ThemeName["Dark"] = "Dark";
    ThemeName["Draft"] = "Draft";
    ThemeName["Custom"] = "Custom";
})(ThemeName = exports.ThemeName || (exports.ThemeName = {}));
