"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SessionCommentEdit = void 0;
const react_1 = __importStar(require("react"));
const reactstrap_1 = require("reactstrap");
const fontawesome_svg_core_1 = require("@fortawesome/fontawesome-svg-core");
const free_solid_svg_icons_1 = require("@fortawesome/free-solid-svg-icons");
const SbButton_1 = require("../SbButton");
const Loader_1 = require("./Loader");
const api_1 = require("@soundbite/api");
const ErrorDlg_1 = require("../ErrorDlg");
const FaPrepend_1 = require("./FaPrepend");
const ShowWhen_1 = require("./ShowWhen");
/***************************************************************************************************
 *  Constants / Global Variables
 **************************************************************************************************/
fontawesome_svg_core_1.library.add(free_solid_svg_icons_1.faArrowUp, free_solid_svg_icons_1.faCircleXmark);
/**
 * Control for taking in text and submitting it
 * @param props
 * @returns
 */
const SessionCommentEdit = (props) => {
    var _a, _b, _c, _d, _e;
    const maxCharacters = (_a = props.maxCharacters) !== null && _a !== void 0 ? _a : 150;
    const [isSaving, setIsSaving] = (0, react_1.useState)(false);
    const [editedContent, setEditedContent] = (0, react_1.useState)((_b = props.comment) === null || _b === void 0 ? void 0 : _b.content);
    const content = (_c = editedContent === null || editedContent === void 0 ? void 0 : editedContent.trim()) !== null && _c !== void 0 ? _c : "";
    const contentLength = (_d = content === null || content === void 0 ? void 0 : content.trim().length) !== null && _d !== void 0 ? _d : 0;
    (0, react_1.useEffect)(() => {
        var _a;
        setEditedContent((_a = props.comment) === null || _a === void 0 ? void 0 : _a.content);
    }, [props.comment]);
    /**
     * Callback for async adding a comment and resetting the submit box
     * @returns
     */
    function onConfirmEdit() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                // Can't submit if props had junk in them
                if (props.orgRoute == null ||
                    props.sessionRoute == null ||
                    props.comment == null) {
                    return;
                }
                // new content needs to fit parameters
                if (content.length > maxCharacters || content.length === 0) {
                    return;
                }
                // Do the update lifecycle
                setIsSaving(true);
                yield api_1.SessionCommentsService.updateSessionComment(props.orgRoute, props.sessionRoute, props.comment.route, { content });
                yield props.onCommit();
                setIsSaving(false);
            }
            catch (err) {
                ErrorDlg_1.ErrorDlg.show(err, "Error Updating Comment");
            }
        });
    }
    const onKeyDown = (event) => {
        if (event.key === "Enter") {
            onConfirmEdit();
        }
    };
    function onCancel() {
        props.onCancel();
    }
    const isWarning = contentLength > maxCharacters - 50;
    const isBeyondLimit = contentLength > maxCharacters;
    let warningClass = "";
    if (isWarning) {
        warningClass = "text-warning";
    }
    if (isBeyondLimit) {
        warningClass = "text-danger";
    }
    return (react_1.default.createElement("div", { className: "sb-comment-edit mt-2" },
        react_1.default.createElement(Loader_1.Loader, { isLoadedWhen: !isSaving },
            react_1.default.createElement(reactstrap_1.FormGroup, { className: "pr-2 mb-0" },
                react_1.default.createElement(reactstrap_1.InputGroup, { "aria-disabled": isSaving },
                    react_1.default.createElement(FaPrepend_1.FaPrepend, { icon: "pen" }),
                    react_1.default.createElement("input", { type: "text", className: "form-control", placeholder: "Updated Content...", "aria-label": "Updated Content", "aria-describedby": "basic-addon1", value: editedContent, onChange: (e) => setEditedContent(e.target.value), disabled: isSaving, title: "Updated Content", onKeyDown: onKeyDown }),
                    react_1.default.createElement(reactstrap_1.InputGroupAddon, { className: "sb-addon-btn", addonType: "append" },
                        react_1.default.createElement(SbButton_1.SbButton, { type: SbButton_1.SbButtonType.Primary, size: SbButton_1.SbButtonSize.Small, btnClassName: "sb-addon-btn", title: "Confirm Edit", onClick: onConfirmEdit, icon: free_solid_svg_icons_1.faArrowUp })),
                    react_1.default.createElement(reactstrap_1.InputGroupAddon, { className: "sb-addon-btn", addonType: "append" },
                        react_1.default.createElement(SbButton_1.SbButton, { type: SbButton_1.SbButtonType.Secondary, size: SbButton_1.SbButtonSize.Small, btnClassName: "sb-addon-btn", title: "Cancel Edit", onClick: onCancel, icon: free_solid_svg_icons_1.faCircleXmark })))),
            react_1.default.createElement("div", { className: "text-sm text-muted" }, api_1.Utils.formatRelativeDate((_e = props.comment) === null || _e === void 0 ? void 0 : _e.createdUtc)),
            react_1.default.createElement(ShowWhen_1.ShowWhen, { is: isWarning },
                react_1.default.createElement("div", { className: `${warningClass} text-sm` },
                    "Max ",
                    content.length,
                    "/",
                    maxCharacters,
                    " characters allowed")))));
};
exports.SessionCommentEdit = SessionCommentEdit;
