"use strict";
// This file is directly imported from the react-select-async-paginate project due to React version conflicts
// https://github.com/vtaits/react-select-async-paginate
// At some point, we should catch up to React 17, then it should be possible to replace these files with the real npm package again
Object.defineProperty(exports, "__esModule", { value: true });
exports.defaultShouldLoadMore = void 0;
const AVAILABLE_DELTA = 10;
const defaultShouldLoadMore = (scrollHeight, clientHeight, scrollTop) => {
    const bottomBorder = scrollHeight - clientHeight - AVAILABLE_DELTA;
    return bottomBorder < scrollTop;
};
exports.defaultShouldLoadMore = defaultShouldLoadMore;
