"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.OrganizationStore = void 0;
const mobx_1 = require("mobx");
const api_1 = require("@soundbite/api");
const ConcurrentCache_1 = require("../modules/ConcurrentCache");
const styles_1 = require("../styles");
/**
 * MobX store class containing states and actions for organizations.
 */
class OrganizationStoreClass {
    //////////[ Constructor ]/////////////////////////////////////////////////////////////////////////////
    constructor() {
        this.myOrgs = undefined; // Array containing all of the organization with which the user is associated
        this.currentOrg = undefined; // Reference to the current organization context under which the application is operating
        this.currentOrgRoute = undefined;
        this.groups = undefined;
        this.myGroups = undefined;
        this.myTargetGroups = undefined;
        //////////[ Cache Members ]///////////////////////////////////////////////////////////////////////
        this.orgCache = new ConcurrentCache_1.ConcurrentCache();
        (0, mobx_1.makeObservable)(this, {
            // Observable
            currentOrgRoute: mobx_1.observable,
            currentOrg: mobx_1.observable,
            myOrgs: mobx_1.observable,
            groups: mobx_1.observable,
            myGroups: mobx_1.observable,
            // Action
            readMyOrgsAsync: mobx_1.action,
            readOrgAsync: mobx_1.action,
            onGroupRemoved: mobx_1.action,
        });
    }
    /**
     * Creates a key used for caching operations
     * @param orgRoute - route of the organization with which the session is associated
     */
    getCacheKey(orgRoute) {
        return `${orgRoute}`;
    }
    //////////[ Actions ]/////////////////////////////////////////////////////////////////////////////
    reset() {
        this.orgCache.clear();
        this.lastOrgRoute = undefined;
        this.myOrgs = undefined;
        this.currentOrg = undefined;
        this.groups = undefined;
        this.myGroups = undefined;
    }
    refresh() {
        return __awaiter(this, void 0, void 0, function* () {
            const promises = [];
            promises.push(this.readMyOrgsAsync(false));
            if (this.lastOrgRoute != null) {
                const orgRoute = this.lastOrgRoute;
                promises.push(this.readOrgAsync(orgRoute, false));
                promises.push(this.readMyGroupsAsync(orgRoute, false));
                promises.push(this.readGroupsAsync(orgRoute, false));
            }
            yield Promise.all(promises);
        });
    }
    /**
     * Populates the myOrgs property of the store.
     * @param refresh - flag indicating whether to force a refresh of cached data
     * @returns a promise indicating success or failure of the operation
     */
    readMyOrgsAsync(allowCache = true) {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.myOrgs != null && allowCache) {
                return this.myOrgs;
            }
            this.myOrgs = undefined;
            const ret = yield api_1.OrganizationsService.readAllAsync();
            this.myOrgs = ret;
            return ret;
        });
    }
    /**
     * Gets and organization by organization route.
     * @param orgRoute - route identifying the organization to retrieve.
     * @param allowCache - (optional) flag indicating whether caching is allowed (default:true)
     */
    readOrgAsync(orgRoute, allowCache = true) {
        return __awaiter(this, void 0, void 0, function* () {
            const cacheKey = this.getCacheKey(orgRoute);
            const ret = yield this.orgCache.getAsync(cacheKey, () => api_1.OrganizationsService.readAsync(orgRoute), allowCache);
            if (ret == null) {
                throw new Error(`Could not find organization '${orgRoute}'`);
            }
            styles_1.GlobalTheme.set(ret.settings.theme, ret.details.route, true);
            return ret;
        });
    }
    /**
     * Retrieves the specified organization and sets it as the current organization
     * @param orgRoute - route identifying the organization to set as the current organization
     * @param allowCache - (optional) flag indicating whether caching is allowed (default:true)
     */
    setCurrentOrganization(orgRoute, allowCache = true) {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            console.log("setCurrentOrg");
            if (this.lastOrgRoute === orgRoute &&
                ((_a = this.currentOrg) === null || _a === void 0 ? void 0 : _a.details.route) === orgRoute &&
                allowCache) {
                return this.currentOrg;
            }
            this.currentOrg = undefined;
            const org = yield this.readOrgAsync(orgRoute, allowCache);
            this.reset();
            this.currentOrg = org;
            this.lastOrgRoute = orgRoute;
            styles_1.GlobalTheme.set(org.settings.theme, org.details.route, true);
            return org;
        });
    }
    /**
     * Responsible for updating any organization in state/cache when a group is removed.
     * @param orgRoute - route of the organization with which the group is associated
     * @param groupRoute - route of the group that was deleted
     */
    onGroupRemoved(orgRoute, groupRoute) {
        // If we're not current only that org, then abort
        if (this.lastOrgRoute !== orgRoute) {
            return;
        }
        let didFilter = false;
        // Optionally pull from groups
        if (this.groups != null) {
            didFilter = true;
            this.groups = [...this.groups.filter((g) => g.route !== groupRoute)];
        }
        // Optionally pull from my groups
        if (this.myGroups != null) {
            didFilter = true;
            this.myGroups = [...this.myGroups.filter((g) => g.route !== groupRoute)];
        }
        // If we removed anything, then we need to re-trigger
        if (didFilter) {
            const temp = this.currentOrg;
            this.currentOrg = undefined;
            this.currentOrg = temp;
        }
    }
    onGroupChanged(group) {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.lastOrgRoute != null) {
                const orgRoute = this.lastOrgRoute;
                const groupsPromise = this.readGroupsAsync(orgRoute, false);
                const myGroupsPromise = this.readMyGroupsAsync(orgRoute, false);
                yield Promise.all([groupsPromise, myGroupsPromise]);
            }
        });
    }
    readGroupsAsync(orgRoute, allowCache = true) {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.lastOrgRoute === orgRoute && this.groups != null && allowCache) {
                return this.groups;
            }
            this.lastOrgRoute = undefined;
            this.groups = undefined;
            const ret = yield api_1.GroupsService.readAllGroupsAsync(orgRoute);
            this.lastOrgRoute = orgRoute;
            this.groups = ret;
            return ret;
        });
    }
    readMyGroupsAsync(orgRoute, allowCache = true) {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.lastOrgRoute === orgRoute && this.myGroups != null && allowCache) {
                return this.myGroups;
            }
            this.lastOrgRoute = undefined;
            this.myGroups = undefined;
            const ret = yield api_1.GroupsService.readMyGroupsAsync(orgRoute);
            this.lastOrgRoute = orgRoute;
            this.myGroups = ret;
            return ret;
        });
    }
    readMyTargetGroupsAsync(orgRoute, allowCache = true) {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.lastOrgRoute === orgRoute &&
                this.myTargetGroups != null &&
                allowCache) {
                return this.myTargetGroups;
            }
            this.lastOrgRoute = undefined;
            this.myTargetGroups = undefined;
            const ret = yield api_1.GroupsService.readMyTargetGroupsAsync(orgRoute);
            this.lastOrgRoute = orgRoute;
            this.myTargetGroups = ret;
            return ret;
        });
    }
}
// Export a singleton instance of the store
exports.OrganizationStore = new OrganizationStoreClass();
