"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.EmptyCardBody = void 0;
const api_1 = require("@soundbite/api");
const react_1 = __importStar(require("react"));
const controls_1 = require("./controls");
/** Formatting for an empty card which prominently displays a graphic and some text */
class EmptyCardBody extends react_1.Component {
    render() {
        const showImage = this.props.showImage === false ? false : true;
        let wrapperClassName = "card-header border-0 sb-empty-card-body";
        if (this.props.hasBorder)
            wrapperClassName += " sb-empty-card-with-border";
        return (react_1.default.createElement("div", { className: wrapperClassName },
            react_1.default.createElement("div", { className: "align-items-center" },
                react_1.default.createElement("div", { className: "text-muted text-center" },
                    this.props.prompt,
                    react_1.default.createElement("div", { className: "mt-3" }, this.props.children)),
                react_1.default.createElement("div", null,
                    react_1.default.createElement(controls_1.ShowWhen, { is: showImage },
                        react_1.default.createElement("img", { src: api_1.SoundbiteApiConfig.imgEmptyCardArtUrl, alt: "Empty Art", className: "mx-auto d-block" }))))));
    }
}
exports.EmptyCardBody = EmptyCardBody;
