"use strict";
/***************************************************************************************************
 * Auto-Generated File - do not modify because all changes will be lost
 **************************************************************************************************/
Object.defineProperty(exports, "__esModule", { value: true });
exports.ParticipantReactionType = void 0;
/**
* Automatically generated enum for Soundbite.ParticipantReactionType
*/
var ParticipantReactionType;
(function (ParticipantReactionType) {
    ParticipantReactionType[ParticipantReactionType["None"] = 0] = "None";
    ParticipantReactionType[ParticipantReactionType["Like"] = 10] = "Like";
    ParticipantReactionType[ParticipantReactionType["Love"] = 20] = "Love";
    ParticipantReactionType[ParticipantReactionType["Laugh"] = 30] = "Laugh";
    ParticipantReactionType[ParticipantReactionType["Wow"] = 40] = "Wow";
    ParticipantReactionType[ParticipantReactionType["Sad"] = 50] = "Sad";
})(ParticipantReactionType = exports.ParticipantReactionType || (exports.ParticipantReactionType = {}));
