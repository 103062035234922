"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.OrgSyncStore = void 0;
const api_1 = require("@soundbite/api");
const mobx_1 = require("mobx");
/**
 * MobX store class containing states and actions for organizations.
 */
class SyncStoreClass {
    //////////[ Constructor ]/////////////////////////////////////////////////////////////////////////////
    constructor() {
        //////////[ Observable Data Properties ]//////////////////////////////////////////////////////////
        this.config = undefined;
        this.result = undefined;
        this.validation = undefined;
        this.nextConfig = undefined;
        (0, mobx_1.makeObservable)(this, {
            // Observables
            config: mobx_1.observable,
            result: mobx_1.observable,
            validation: mobx_1.observable,
            nextConfig: mobx_1.observable,
            // Actions
            reset: mobx_1.action,
            validateConfigAsync: mobx_1.action,
            readConfigAsync: mobx_1.action,
            updateConfigAsync: mobx_1.action,
            syncAsync: mobx_1.action,
            updateToNextConfig: mobx_1.action,
            validateNextConfig: mobx_1.action,
        });
    }
    nextStrategyConfig() {
        if (this.nextConfig == null)
            return undefined;
        const json = this.nextConfig.syncConfigJson;
        if (json == null)
            return undefined;
        return JSON.parse(json);
    }
    //////////[ Methodss ]//////////////////////////////////////////////////////////
    sortTargets(result) {
        if (result == null || result.sort == null)
            return undefined;
        const originalLength = result.length;
        result = result
            .filter((t) => t.name != null)
            .sort((a, b) => { var _a, _b; return ((_a = a.name) !== null && _a !== void 0 ? _a : "").localeCompare((_b = b === null || b === void 0 ? void 0 : b.name) !== null && _b !== void 0 ? _b : ""); });
        const newLength = result.length;
        if (newLength !== originalLength)
            console.warn("Filtered out null names from sync targets");
        return result;
    }
    reset() {
        this.config = undefined;
        this.result = undefined;
        this.validation = undefined;
        this.nextConfig = undefined;
    }
    //////////[ Actions ]/////////////////////////////////////////////////////////////////////////////
    setNextConfig(orgConfig) {
        this.nextConfig = Object.assign({}, orgConfig);
    }
    setNextStrategyConfig(strategyConfig) {
        if (strategyConfig == null && this.nextConfig == null) {
            return undefined;
        }
        const json = strategyConfig != null ? JSON.stringify(strategyConfig) : undefined;
        if (this.nextConfig != null)
            this.nextConfig.syncConfigJson = undefined;
        const nextConfig = Object.assign(Object.assign({}, this.nextConfig), { syncConfigJson: json });
        this.nextConfig = nextConfig;
        return nextConfig;
    }
    updateToNextConfig(orgRoute) {
        return __awaiter(this, void 0, void 0, function* () {
            const val = yield this.updateConfigAsync(orgRoute, this.nextConfig);
            return val;
        });
    }
    validateNextConfig(orgRoute) {
        return __awaiter(this, void 0, void 0, function* () {
            const val = yield this.validateConfigAsync(orgRoute, this.nextConfig);
            return val;
        });
    }
    /**
     * Async runs logic on the server to config orgConfig is valid
     * @param orgRoute
     * @param orgConfig If this is undefined, then it will check the current config on the server
     */
    validateConfigAsync(orgRoute, orgConfig) {
        return __awaiter(this, void 0, void 0, function* () {
            this.validation = undefined;
            const response = yield api_1.SyncService.validateAsync(orgRoute, orgConfig);
            this.validation = response;
            return response;
        });
    }
    /**
     * Reads the current config for the given org route
     * @param orgRoute
     * @param refresh Optional parameter that forces a refresh of the current org
     */
    readConfigAsync(orgRoute) {
        return __awaiter(this, void 0, void 0, function* () {
            this.config = undefined;
            const response = yield api_1.SyncService.readConfigAsync(orgRoute);
            this.config = response;
            this.nextConfig = response.sanitizedConfig;
            return response;
        });
    }
    /**
     * Updates the org sync config
     * If this errors, the current org sync config will not update
     * On success, this will update this.currentOrgSyncConfig
     * @param orgRoute
     * @param orgSyncConfig If you pass undefined, this will delete the config and disable directory sync for the org
     */
    updateConfigAsync(orgRoute, orgSyncConfig) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield api_1.SyncService.updateConfigAsync(orgRoute, orgSyncConfig);
            this.reset();
            this.config = response;
            this.nextConfig = response.sanitizedConfig;
            return response;
        });
    }
    /**
     * Runs the sync process for the given org
     * On start, this will clear this.currentOrgSyncResult
     * On success, this will update this.currentOrgSyncResult
     * @param orgRoute
     */
    syncAsync(orgRoute) {
        return __awaiter(this, void 0, void 0, function* () {
            this.result = undefined;
            const result = yield api_1.SyncService.syncAsync(orgRoute);
            this.result = result;
            return result;
        });
    }
}
// Export a singleton instance of the store
exports.OrgSyncStore = new SyncStoreClass();
