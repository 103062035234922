"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PeopleWidget = void 0;
const react_1 = __importStar(require("react"));
const mobx_react_lite_1 = require("mobx-react-lite");
const reactstrap_1 = require("reactstrap");
const api_1 = require("@soundbite/api");
const store_1 = require("../store");
const dialogs_1 = require("../dialogs");
const controls_1 = require("../components/controls");
const components_1 = require("../components");
const InfiniteTable_1 = require("../components/controls/InfiniteTable");
const InfiniteScrollDataHelper_1 = require("../modules/InfiniteScrollDataHelper");
/** Display the list of people in the given org */
exports.PeopleWidget = (0, mobx_react_lite_1.observer)((props) => {
    var _a, _b;
    let { orgRoute } = props;
    // Component state
    const [isLoading, setIsLoading] = (0, react_1.useState)(false);
    const [org, setOrg] = (0, react_1.useState)();
    const myPersonRoute = (_a = org === null || org === void 0 ? void 0 : org.details.me) === null || _a === void 0 ? void 0 : _a.route;
    const myPersonRole = (_b = org === null || org === void 0 ? void 0 : org.details.me) === null || _b === void 0 ? void 0 : _b.personRole;
    // Dynamic People data
    const people = (0, react_1.useRef)(new Map());
    const maxTake = (0, react_1.useRef)(128);
    const maxTotalItems = (0, react_1.useRef)(2147483647);
    const [count, setCount] = (0, react_1.useState)(undefined);
    // Load
    const load = (allowCache = true) => __awaiter(void 0, void 0, void 0, function* () {
        if (isLoading || orgRoute == null) {
            return;
        }
        setIsLoading(true);
        if (!allowCache) {
            setOrg(undefined);
        }
        const firstPeoplePromise = store_1.WidgetStore.people.readAllAsync(orgRoute, {
            includesCounts: true,
        });
        const newOrgPromise = store_1.WidgetStore.organizations.readOrgAsync(orgRoute, allowCache);
        const [firstPeoplePage, newOrg] = yield Promise.all([
            firstPeoplePromise,
            newOrgPromise,
        ]);
        setPeople(firstPeoplePage);
        setCount(firstPeoplePage.totalCount);
        maxTake.current = firstPeoplePage.maxTake;
        setOrg(newOrg);
        setIsLoading(false);
    });
    const setPeople = (page) => {
        api_1.PageUtils.addPageToMap(page, people.current);
    };
    const scrollData = new InfiniteScrollDataHelper_1.InfiniteScrollDataHelper((startIndex, stopIndex) => __awaiter(void 0, void 0, void 0, function* () {
        return new Promise((resolve, reject) => __awaiter(void 0, void 0, void 0, function* () {
            try {
                // Indicate
                for (let index = startIndex; index <= stopIndex; index++) {
                    people.current.set(index, null);
                }
                const page = yield store_1.WidgetStore.people.readAllAsync(orgRoute, {
                    skip: startIndex - 1,
                    take: maxTake.current,
                });
                api_1.PageUtils.addPageToMap(page, people.current);
                resolve();
            }
            catch (err) {
                components_1.ErrorDlg.show(err, "Error Loading Sessions", `Error querying for sessions ${startIndex} to ${stopIndex}:`);
                reject();
            }
        }));
    }));
    react_1.default.useEffect(() => {
        load();
        // eslint-disable-next-line
    }, [orgRoute, store_1.WidgetStore.organizations.currentOrg]);
    const onSubmitInviteUser = (invite) => __awaiter(void 0, void 0, void 0, function* () {
        if (!invite)
            return;
        yield store_1.WidgetStore.people.invitePersonAsync(orgRoute, invite);
        yield load();
    });
    // Events
    const onInvitePerson = () => {
        dialogs_1.InviteUserDialog.open(undefined, onSubmitInviteUser);
    };
    const onSubmitDeleteUser = (person) => __awaiter(void 0, void 0, void 0, function* () {
        if (person) {
            yield store_1.WidgetStore.people.deletePersonAsync(orgRoute, person.route);
            yield load();
        }
    });
    const onDeleteUser = (person) => {
        dialogs_1.DeleteUserDialog.open(person, onSubmitDeleteUser);
    };
    const onPersonClick = (person) => {
        dialogs_1.DialogManager.ShowProfileDialog(person.user, false);
    };
    const onToggleAdmin = (adminChecked, person) => {
        person.personRole = adminChecked ? api_1.PersonRole.Admin : api_1.PersonRole.Person;
        store_1.WidgetStore.people.updatePersonAsync(orgRoute, person);
        // No need to update because the UI already changes when the click happens
    };
    const onRefresh = () => __awaiter(void 0, void 0, void 0, function* () {
        yield load(false);
    });
    // Inner Components
    const Header = () => {
        return (react_1.default.createElement(reactstrap_1.CardHeader, { className: "border-0" },
            react_1.default.createElement(controls_1.ShowWhen, { is: count != null },
                react_1.default.createElement(reactstrap_1.Row, { className: "align-items-center" },
                    react_1.default.createElement(reactstrap_1.Col, null,
                        react_1.default.createElement("h1", { className: "mb-0" },
                            react_1.default.createElement("i", { className: "fas fa-user-cog d-none d-sm-inline" }),
                            count,
                            " ", org === null || org === void 0 ? void 0 :
                            org.details.name,
                            " Users")),
                    react_1.default.createElement(reactstrap_1.Col, { className: "text-right" },
                        react_1.default.createElement(reactstrap_1.Button, { outline: true, color: "secondary", className: "btn-icon btn-sm", onClick: onRefresh, title: "Refresh Users" },
                            react_1.default.createElement("span", { className: "btn-inner--icon" },
                                react_1.default.createElement("i", { className: "fas fa-sync-alt" }))),
                        react_1.default.createElement("button", { className: "btn btn-icon btn-primary btn-sm", type: "button", onClick: onInvitePerson, title: "Invite User" },
                            react_1.default.createElement("span", { className: "btn-inner--icon" },
                                react_1.default.createElement("i", { className: "fas fa-user-plus" })),
                            react_1.default.createElement("span", { className: "btn-inner--text" },
                                react_1.default.createElement("span", { className: "d-none d-sm-inline" },
                                    react_1.default.createElement(react_1.Fragment, null, "\u00A0"),
                                    "Invite"),
                                react_1.default.createElement("span", { className: "d-none d-md-inline" },
                                    react_1.default.createElement(react_1.Fragment, null, "\u00A0"),
                                    "User"))))))));
    };
    const TableHeader = () => {
        return (react_1.default.createElement("thead", { className: "thead-light" },
            react_1.default.createElement("tr", null,
                react_1.default.createElement("th", { scope: "col", className: "sort", "data-sort": "name" }, "Name"),
                react_1.default.createElement("th", { scope: "col", className: "sort d-none d-lg-table-cell", "data-sort": "role" }, "E-Mail"),
                react_1.default.createElement("th", { scope: "col", className: "sort", "data-sort": "deadline" }, "Admin"),
                react_1.default.createElement("th", { scope: "col", className: "sort", "data-sort": "action" }))));
    };
    const PersonRow = (props) => {
        const person = props.person;
        return (react_1.default.createElement("tr", { "data-person-route": person.route, "data-row-index": props.index, "data-user-route": person.user.route },
            react_1.default.createElement("th", { scope: "row" },
                react_1.default.createElement(controls_1.Avatar, { user: person.user, onClick: () => onPersonClick(person), title: "Show User Profile" })),
            react_1.default.createElement("td", { className: "d-none d-lg-table-cell" },
                react_1.default.createElement("a", { href: `mailto:${person.user.email}`, title: `Send e-mail to ${person.user.email}` }, person.user.email)),
            react_1.default.createElement("td", null,
                react_1.default.createElement(controls_1.ShowWhen, { is: myPersonRoute !== person.route },
                    react_1.default.createElement(controls_1.Toggle, { defaultValue: person.personRole === api_1.PersonRole.Admin, onToggle: (isEnabled) => onToggleAdmin(isEnabled, person), title: "User Is Organization Administrator" })),
                react_1.default.createElement(controls_1.ShowWhen, { is: myPersonRoute === person.route &&
                        myPersonRole === api_1.PersonRole.Admin }, "Admin")),
            react_1.default.createElement("td", { className: "text-right" },
                react_1.default.createElement(controls_1.ShowWhen, { is: myPersonRoute !== person.route },
                    react_1.default.createElement("button", { className: "btn btn-outline-secondary btn-sm", type: "button", onClick: () => onDeleteUser(person), title: "Delete User" },
                        react_1.default.createElement("span", { className: "btn-inner--icon" },
                            react_1.default.createElement("i", { className: "fas fa-trash" })))))));
    };
    const LoadingRow = (props) => {
        return (react_1.default.createElement("tr", { "data-row-index": props.index, style: { height: "73px" } },
            react_1.default.createElement("th", { scope: "row", className: "text-muted" },
                "Loading ",
                props.index != null ? `${props.index}...` : "..."),
            react_1.default.createElement("td", { className: "d-none d-lg-table-cell" }),
            react_1.default.createElement("td", null),
            react_1.default.createElement("td", null)));
    };
    const LastRow = () => {
        return (react_1.default.createElement("tr", { style: { height: "73px" } },
            react_1.default.createElement("th", { scope: "row", className: "text-muted" },
                "Cannot load more than ",
                maxTotalItems.current,
                " at this time"),
            react_1.default.createElement("td", { className: "d-none d-lg-table-cell" }),
            react_1.default.createElement("td", null),
            react_1.default.createElement("td", null)));
    };
    /** The Row component. This should be a table row, and noted that we don't use the style that regular `react-window` examples pass in.*/
    function TableRow({ index }) {
        const person = people.current.get(index);
        if (index >= maxTotalItems.current) {
            return react_1.default.createElement(LastRow, null);
        }
        else if (person == null) {
            return react_1.default.createElement(LoadingRow, { index: index });
        }
        else {
            return react_1.default.createElement(PersonRow, { person: person, index: index });
        }
    }
    const Table = () => {
        // Every row is loaded except for our loading indicator row.
        const isItemLoaded = (index) => {
            const person = people.current.get(index);
            return person != null;
        };
        let itemCount = count !== null && count !== void 0 ? count : 0;
        if (itemCount > maxTotalItems.current) {
            itemCount = maxTotalItems.current + 1;
        }
        return (react_1.default.createElement(controls_1.ShowWhen, { is: count != null },
            react_1.default.createElement(InfiniteTable_1.InfiniteTable, { count: itemCount, header: react_1.default.createElement(TableHeader, null), isItemLoaded: isItemLoaded, itemSize: 73, loadMore: (startIndex, endIndex) => scrollData.getMoreItems(startIndex, endIndex), minimumBatchSize: 128, row: TableRow })));
    };
    const Body = () => {
        return (react_1.default.createElement("div", { className: "table-responsive" },
            react_1.default.createElement(controls_1.ShowWhen, { is: !!org },
                react_1.default.createElement(Table, null))));
    };
    return (react_1.default.createElement(reactstrap_1.Card, null,
        react_1.default.createElement(components_1.Loader, { isLoadedWhen: !isLoading && !!org && people != null, style: { minHeight: "12rem" }, message: "Loading Users..." },
            react_1.default.createElement(Header, null),
            react_1.default.createElement(Body, null))));
});
