"use strict";
/***************************************************************************************************
 * Auto-Generated File - do not modify because all changes will be lost
 **************************************************************************************************/
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProviderType = void 0;
/**
* Automatically generated enum for Masticore.ProviderType
*/
var ProviderType;
(function (ProviderType) {
    ProviderType[ProviderType["Unknown"] = 0] = "Unknown";
    ProviderType[ProviderType["AAD"] = 1] = "AAD";
    ProviderType[ProviderType["Interact"] = 100] = "Interact";
    ProviderType[ProviderType["OKTA"] = 200] = "OKTA";
})(ProviderType = exports.ProviderType || (exports.ProviderType = {}));
