"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HttpService = void 0;
const PublicError_1 = require("./PublicError");
const SoundbiteApiConfig_1 = require("./SoundbiteApiConfig");
/**
 * Wrapper class for issuing HTTP calls for the application.  T
 */
class HttpServiceClass {
    /**
     * Issues an HTTP request with the DELETE verb to the specified url.
     * @param url - URL to which the request is sent.
     * @param options - HTTP request options used to customize the request.
     */
    delete(url, options) {
        return new Promise((resolve, reject) => {
            SoundbiteApiConfig_1.SoundbiteApiConfig.httpAdapter
                .delete(url, options)
                .then((result) => resolve(result))
                .catch((ex) => {
                const msg = ex && ex.message ? `: ${ex.message}` : ".";
                console.log(`[SB]-HTTP service DELETE failed calling ${url}`);
                reject(new PublicError_1.PublicError(ex, "Network Request Failed", `A delete request to the Soundbite API failed ${msg}`));
            });
        });
    }
    /**
     * Issues an HTTP request with the GET verb to the specified url.
     * @param url - URL to which the request is sent.
     * @param options - HTTP request options used to customize the request.
     */
    get(url, options) {
        return new Promise((resolve, reject) => {
            SoundbiteApiConfig_1.SoundbiteApiConfig.httpAdapter
                .get(url, options)
                .then((result) => resolve(result))
                .catch((ex) => {
                const msg = ex && ex.message ? `: ${ex.message}` : ".";
                console.log(`[SB]-HTTP service GET failed calling ${url}`);
                reject(new PublicError_1.PublicError(ex, "Network Request Failed", `A get request to the Soundbite API failed ${msg}`));
            });
        });
    }
    /**
     * Issues an HTTP request with the PATCH verb to the specified url.
     * @param url - URL to which the request is sent.
     * @data data - data sent in the body of the request.
     * @param options - HTTP request options used to customize the request.
     */
    patch(url, data, options) {
        return new Promise((resolve, reject) => {
            SoundbiteApiConfig_1.SoundbiteApiConfig.httpAdapter
                .patch(url, data, options)
                .then((result) => resolve(result))
                .catch((ex) => {
                const msg = ex && ex.message ? `: ${ex.message}` : ".";
                console.log(`[SB]-HTTP service PATCH failed calling ${url}`);
                if (data) {
                    console.log("[SB]-", data);
                }
                reject(new PublicError_1.PublicError(ex, "Network Request Failed", `A patch request to the Soundbite API failed ${msg}`));
            });
        });
    }
    /**
     * Issues an HTTP request with the POST verb to the specified url.
     * @param url - URL to which the request is sent.
     * @data data - data sent in the body of the request.
     * @param options - HTTP request options used to customize the request.
     */
    post(url, data, options) {
        // ASP.Net Core requires the text "null" to transmit an empty body
        data = data !== null && data !== void 0 ? data : "null";
        return new Promise((resolve, reject) => {
            SoundbiteApiConfig_1.SoundbiteApiConfig.httpAdapter
                .post(url, data, options)
                .then((result) => resolve(result))
                .catch((ex) => {
                const msg = ex && ex.message ? `: ${ex.message}` : ".";
                console.log(`[SB]-HTTP service POST failed calling ${url}`);
                if (data) {
                    console.log("[SB]-", data);
                }
                reject(new PublicError_1.PublicError(ex, "Network Request Failed", `A post request to the Soundbite API failed${msg}`));
            });
        });
    }
    /**
     * Issues an HTTP request with the PUT verb to the specified url.
     * @param url - URL to which the request is sent.
     * @data data - data sent in the body of the request.
     * @param options - HTTP request options used to customize the request.
     */
    put(url, data, options) {
        return new Promise((resolve, reject) => {
            SoundbiteApiConfig_1.SoundbiteApiConfig.httpAdapter
                .put(url, data, options)
                .then((result) => resolve(result))
                .catch((ex) => {
                const msg = ex && ex.message ? `: ${ex.message}` : ".";
                console.log(`[SB]-HTTP service PUT failed calling ${url}`);
                if (data) {
                    console.log("[SB]-", data);
                }
                reject(new PublicError_1.PublicError(ex, "Network Request Failed", `A put request to the Soundbite API failed${msg}`));
            });
        });
    }
}
exports.HttpService = new HttpServiceClass();
