"use strict";
/***************************************************************************************************
 * Auto-Generated File - do not modify because all changes will be lost
 **************************************************************************************************/
Object.defineProperty(exports, "__esModule", { value: true });
exports.ClipOperationType = void 0;
/**
* Automatically generated enum for Soundbite.ClipOperationType
*/
var ClipOperationType;
(function (ClipOperationType) {
    ClipOperationType[ClipOperationType["Unknown"] = 0] = "Unknown";
    ClipOperationType[ClipOperationType["AzureMediaSvcEncoding"] = 100] = "AzureMediaSvcEncoding";
    ClipOperationType[ClipOperationType["AzureMediaSvcTranscribe"] = 200] = "AzureMediaSvcTranscribe";
    ClipOperationType[ClipOperationType["SoundbiteMediaEncoding"] = 300] = "SoundbiteMediaEncoding";
})(ClipOperationType = exports.ClipOperationType || (exports.ClipOperationType = {}));
