"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RecorderState = void 0;
/**
 * Enumeration of the various video editor recorder states.
 */
var RecorderState;
(function (RecorderState) {
    /**
     * Denotes that the recorder is not currently recording and is not paused.
     */
    RecorderState[RecorderState["NotRecording"] = 0] = "NotRecording";
    /**
     * Denotes that the recorder is actively recording.
     */
    RecorderState[RecorderState["Recording"] = 1] = "Recording";
    /**
     * Denotes that the recorder is paused and can resume recording.
     */
    RecorderState[RecorderState["Paused"] = 2] = "Paused";
    /**
     * Denotes that the recorder is an error state.
     */
    RecorderState[RecorderState["Error"] = 99] = "Error";
})(RecorderState = exports.RecorderState || (exports.RecorderState = {}));
