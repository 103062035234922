"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DialogManager = void 0;
const React = __importStar(require("react"));
const api_1 = require("@soundbite/api");
const DialogManagerLink_1 = require("./DialogManagerLink");
const PlayDlg_1 = require("../components/PlayDlg");
const RecordDlg_1 = require("../components/RecordDlg");
const SeriesDeleteDialog_1 = require("./SeriesDeleteDialog");
const SessionDeleteDialog_1 = require("./SessionDeleteDialog");
const SessionNewDialog_1 = require("./SessionNewDialog");
const SessionReportDialog_1 = require("./SessionReportDialog");
const WidgetStore_1 = require("../store/WidgetStore");
const ProfileDialog_1 = require("./ProfileDialog");
const ConfirmDialog_1 = require("./ConfirmDialog");
const DialogStyleType_1 = require("./DialogStyleType");
/**
 * Responsible for displaying dialogs seen throughout the application.  Defining all dialogs in
 * this class allows them to be displayed anywhere in the application without having to re-wire
 * the dialog into various UI components.
 */
class DialogManager {
    //////////[ Show Dialog Methods ]/////////////////////////////////////////////////////////////////
    static ShowRecordDialog(orgRoute, sessionRoute) {
        this.showDialog(React.createElement(RecordDlg_1.RecordDlg, { orgRoute: orgRoute, sessionRoute: sessionRoute, participantRole: api_1.ParticipantRole.Host, isOpen: true, onClose: this.close }));
    }
    static ShowReportsDialog(orgRoute, session) {
        this.showDialog(React.createElement(SessionReportDialog_1.SessionReportDialog, { orgRoute: orgRoute, session: session, onClose: this.close }));
    }
    static ShowPlayerDialog(orgRoute, sessionRoute) {
        var _a, _b;
        this.showDialog(React.createElement(PlayDlg_1.PlayDlg, { orgRoute: orgRoute, currentUserRoute: (_b = (_a = WidgetStore_1.WidgetStore.organizations.currentOrg) === null || _a === void 0 ? void 0 : _a.details.me) === null || _b === void 0 ? void 0 : _b.user.route, sessionRoute: sessionRoute, openImmediately: true, onClose: this.close }));
    }
    /**
     * Displays the profile dialog for the given user, defaulting to the current user, optionally showing the settings for that user
     */
    static ShowProfileDialog(user, showSettings = true) {
        this.showDialog(React.createElement(ProfileDialog_1.ProfileDialog, { user: user, showSettings: showSettings, onClose: this.close }));
    }
    /**
     * Displays the series delete confirmation dialog.
     */
    static ShowSeriesDeleteDialog(orgRoute, series) {
        this.showDialog(React.createElement(SeriesDeleteDialog_1.SeriesDeleteDialog, { orgRoute: orgRoute, series: series, close: this.close }));
    }
    static ShowSeriesEditDialog(orgRoute, series) {
        console.warn("Show Series Edit Dialog");
    }
    /**
     * Displays the session delete confirmation dialog.
     */
    static ShowSessionDeleteDialog(orgRoute, session) {
        this.showDialog(React.createElement(SessionDeleteDialog_1.SessionDeleteDialog, { orgRoute: orgRoute, session: session, close: this.close }));
    }
    /**
     * Displays the new session dialog.
     * @param orgRoute - route of the organization with which the session is associated.
     * @param isSeries - flag indicating whether the dialog is for a session (false) or a series (true)
     */
    static ShowSessionNewDialog(orgRoute, groupRoute, isSeries = false, onClose) {
        const close = () => {
            if (onClose != null) {
                onClose();
            }
            this.close();
        };
        this.showDialog(React.createElement(SessionNewDialog_1.SessionNewDialog, { orgRoute: orgRoute, groupRoute: groupRoute, close: close, isExpanded: isSeries }));
    }
    static ShowConfirmDialog(title, description, onConfirm, buttonTitle = "Confirm", dialogStyle = DialogStyleType_1.DialogStyleType.Normal, onClose) {
        const close = () => {
            if (onClose != null) {
                onClose();
            }
            this.close();
        };
        this.showDialog(React.createElement(ConfirmDialog_1.ConfirmDialog, { buttonTitle: buttonTitle, style: dialogStyle, title: title, description: description, onConfirm: onConfirm, close: close }));
    }
    //////////[ Utility Methods ]/////////////////////////////////////////////////////////////////////
    /**
     * Closes the current dialog by setting the current dialog reference to null.
     */
    static close() {
        if (DialogManagerLink_1.DialogManagerLink.setCurrentDialogRef) {
            DialogManagerLink_1.DialogManagerLink.setCurrentDialogRef(null);
        }
    }
    /**
     * Displays the specified dialog component
     * @param component - dialog component to display
     */
    static showDialog(component) {
        if (DialogManagerLink_1.DialogManagerLink.setCurrentDialogRef) {
            DialogManagerLink_1.DialogManagerLink.setCurrentDialogRef(component);
        }
        else {
            throw "Attempted to show a dialog but the DialogManagerLink has not been established.  Ensure there is a <DialogManagerComponent> element in the application.";
        }
    }
}
exports.DialogManager = DialogManager;
