"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MegaWidget = void 0;
const react_1 = __importStar(require("react"));
const reactstrap_1 = require("reactstrap");
const api_1 = require("@soundbite/api");
const FeedWidget_1 = require("./FeedWidget");
const components_1 = require("../components");
const PastSessionsWidget_1 = require("./PastSessionsWidget");
const PendingSessionsWidget_1 = require("./PendingSessionsWidget");
const SeriesWidget_1 = require("./SeriesWidget");
const free_solid_svg_icons_1 = require("@fortawesome/free-solid-svg-icons");
var TabName;
(function (TabName) {
    TabName["Feed"] = "Feed";
    TabName["Calendar"] = "Calendar";
    TabName["Scheduled"] = "Scheduled";
    TabName["History"] = "History";
})(TabName || (TabName = {}));
/***************************************************************************************************
 *  Component
 **************************************************************************************************/
/**
 * Widget for constructing a session, including recording/uploading conte and optionally recurring sessions
 * @param props
 */
const MegaWidget = (props) => {
    //////////[ Types ]///////////////////////////////////////////////////////////////////////////////
    const tabs = [
        { name: TabName.Feed, icon: free_solid_svg_icons_1.faPodcast },
        { name: TabName.Calendar, icon: free_solid_svg_icons_1.faCalendar },
        { name: TabName.Scheduled, icon: free_solid_svg_icons_1.faCalendarDay },
        { name: TabName.History, icon: free_solid_svg_icons_1.faCalendarCheck },
    ];
    const [currentTab, setCurrentTab] = (0, react_1.useState)(getInitialTab(props.initialTab));
    /**
     * Ensures that the initial tab value is valid.  Essentially if it does not match
     * @param initialTabValue
     */
    function getInitialTab(initialTabValue) {
        let result = tabs[0].name;
        initialTabValue = initialTabValue === null || initialTabValue === void 0 ? void 0 : initialTabValue.trim().toLowerCase();
        for (var i = 0; i < tabs.length; i++) {
            if (tabs[i].name.toLowerCase() == initialTabValue) {
                result = tabs[i].name;
            }
        }
        return result;
    }
    /**
     * Header is a copy of the header from the SPA application.
     */
    function Header() {
        return (react_1.default.createElement("div", { className: "header sb-header bg-gradient-brand pb-4 pt-6 pt-md-8" },
            react_1.default.createElement("div", { className: "separator separator-bottom separator-skew zindex-100" },
                react_1.default.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", preserveAspectRatio: "none", version: "1.1", viewBox: "0 0 2560 100", x: "0", y: "0" },
                    react_1.default.createElement("polygon", { className: "fill-default", points: "2560 0 2560 100 0 100" })))));
    }
    /**
     * Renders the navigation tabs allowing users to change the current view.
     */
    function Tabs() {
        return (react_1.default.createElement(reactstrap_1.Navbar, { className: "navbar-top navbar-dark bg-gradient-brand", expand: "md" },
            react_1.default.createElement(reactstrap_1.Container, null,
                react_1.default.createElement(reactstrap_1.Nav, { className: "navbar-nav align-items-center" },
                    react_1.default.createElement("ul", { className: "sb-tabs" }, tabs.map((tab) => (react_1.default.createElement(reactstrap_1.NavItem, null,
                        react_1.default.createElement("a", { href: "#", onClick: (e) => {
                                e.preventDefault();
                                setCurrentTab(tab.name);
                            }, className: currentTab == tab.name ? "active" : "" },
                            react_1.default.createElement(components_1.IconLink, { title: tab.name, icon: tab.icon }))))))))));
    }
    function getTitle(suffix) {
        if (api_1.Utils.isNullOrEmpty(props.title)) {
            return undefined;
        }
        else {
            return props.title + (suffix !== null && suffix !== void 0 ? suffix : "");
        }
    }
    function Content() {
        if (api_1.Utils.isNullOrEmpty(props.orgRoute)) {
            return react_1.default.createElement(components_1.Loader, null);
        }
        else {
            switch (currentTab) {
                case TabName.Feed:
                    return (react_1.default.createElement(FeedWidget_1.FeedWidget, { orgRoute: props.orgRoute, groupRoute: props.groupRoute, title: getTitle(), showTitle: props.showTitle, mode: FeedWidget_1.FeedMode.Card }));
                case TabName.Calendar:
                    return (react_1.default.createElement(SeriesWidget_1.SeriesWidget, { orgRoute: props.orgRoute, groupRoute: props.groupRoute, title: getTitle(" Series"), showTitle: props.showTitle }));
                case TabName.Scheduled:
                    return (react_1.default.createElement(PendingSessionsWidget_1.PendingSessionsWidget, { orgRoute: props.orgRoute, groupRoute: props.groupRoute, title: getTitle(" History"), showTitle: props.showTitle }));
                case TabName.History:
                    return (react_1.default.createElement(PastSessionsWidget_1.PastSessionsWidget, { orgRoute: props.orgRoute, groupRoute: props.groupRoute, title: getTitle(" History"), showTitle: props.showTitle }));
                default:
                    return react_1.default.createElement("div", null,
                        "No UI for ",
                        currentTab);
            }
        }
    }
    return (react_1.default.createElement("div", { className: "sb-private-view" },
        react_1.default.createElement("div", { className: "main-content" },
            react_1.default.createElement(Tabs, null),
            react_1.default.createElement(Header, null),
            react_1.default.createElement(reactstrap_1.Container, { className: "sb-navigation-container" },
                react_1.default.createElement(Content, null)))));
};
exports.MegaWidget = MegaWidget;
