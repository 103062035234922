"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.GroupPlayerRow = void 0;
const react_1 = __importStar(require("react"));
const reactstrap_1 = require("reactstrap");
const api_1 = require("@soundbite/api");
/** Wraps a set of children with a row describing the given clip */
class GroupPlayerRow extends react_1.Component {
    render() {
        const avatarlUrl = this.props.clip.contributor.imageSrc || api_1.SoundbiteApiConfig.imgAvatarUrl;
        const displayName = api_1.Utils.userDisplay(this.props.clip.contributor);
        return (react_1.default.createElement("li", { className: "list-group-item px-0" },
            react_1.default.createElement(reactstrap_1.Row, { className: "align-items-center" },
                react_1.default.createElement(reactstrap_1.Col, { xs: "2", className: "d-none d-sm-block" },
                    react_1.default.createElement("span", { className: "avatar rounded-circle" },
                        react_1.default.createElement("img", { alt: "Avatar", src: avatarlUrl }))),
                react_1.default.createElement(reactstrap_1.Col, { xs: "4", className: "ml--2" },
                    react_1.default.createElement("h4", { className: "mb-0" }, displayName)),
                react_1.default.createElement(reactstrap_1.Col, { xs: "6" }, this.props.children))));
    }
}
exports.GroupPlayerRow = GroupPlayerRow;
