"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Logger = void 0;
// Enumeration of the various log levels
var LogLevel;
(function (LogLevel) {
    LogLevel[LogLevel["trace"] = 0] = "trace";
    LogLevel[LogLevel["info"] = 1] = "info";
    LogLevel[LogLevel["warning"] = 2] = "warning";
    LogLevel[LogLevel["error"] = 4] = "error";
})(LogLevel || (LogLevel = {}));
/**
 * Class for logging application runtime information
 */
class LoggerClass {
    /**
     * Responsible for applying all logging logic
     * @param level - log level associated with the message
     * @param msg - message to log
     * @param ex - error information (optional)
     */
    Log(level, msg, ex) {
        // Acquire current log level
        const minLogLevel = window.sbMinLogLevel | LogLevel.trace;
        // Determine whether to log the message
        if (level >= minLogLevel) {
            if (ex) {
                if (level >= LogLevel.error) {
                    console.error(`[SB]-${msg}`, ex);
                }
                else if (level >= LogLevel.warning) {
                    console.warn(`[SB]-${msg}`, ex);
                }
                else {
                    console.log(`[SB]-${msg}`, ex);
                }
            }
            else {
                if (level >= LogLevel.error) {
                    console.error(`[SB]-${msg}`);
                }
                else if (level >= LogLevel.warning) {
                    console.warn(`[SB]-${msg}`);
                }
                else {
                    console.log(`[SB]-${msg}`);
                }
            }
        }
    }
    /**
     * Log a trace level message
     * @param msg - message to log
     */
    LogTrace(msg) {
        this.Log(LogLevel.trace, msg);
    }
    /**
     * Log an informational level message
     * @param msg - message to log
     */
    LogInfo(msg) {
        this.Log(LogLevel.info, msg);
    }
    /**
     * Log a warning level message
     * @param msg - message to log
     */
    LogWarning(msg) {
        this.Log(LogLevel.warning, msg);
    }
    /**
     * Log an error level message
     * @param msg - message to log
     * @param error - error information
     */
    LogError(msg, ex) {
        this.Log(LogLevel.error, msg, ex);
    }
}
exports.Logger = new LoggerClass();
