"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PersonStore = void 0;
const api_1 = require("@soundbite/api");
const mobx_1 = require("mobx");
/**
 * MobX store class containing states and actions for people
 */
class PersonStoreClass {
    //////////[ Constructor ]/////////////////////////////////////////////////////////////////////////////
    constructor() {
        //////////[ Observable Data Properties ]//////////////////////////////////////////////////////////
        this.lastOrgRoute = undefined;
        (0, mobx_1.makeObservable)(this, {
            // Actions
            deletePersonAsync: mobx_1.action,
            invitePersonAsync: mobx_1.action,
            readAllAsync: mobx_1.action,
            reset: mobx_1.action,
            updatePersonAsync: mobx_1.action,
        });
    }
    //////////[ Actions ]/////////////////////////////////////////////////////////////////////////////
    /** Clear the state */
    reset() {
        this.lastOrgRoute = undefined;
    }
    /** Based on the last arguments, reloads the properties for this store */
    refresh() {
        return __awaiter(this, void 0, void 0, function* () {
            // NOTHING
        });
    }
    readAllAsync(orgRoute, pageRequest) {
        return __awaiter(this, void 0, void 0, function* () {
            const ret = yield api_1.PeopleService.readAllAsync(orgRoute, pageRequest);
            this.lastOrgRoute = orgRoute;
            return ret;
        });
    }
    /**
     * Stores updates to the specified person.
     * @param orgRoute - route of the organization with which the person is associated.
     * @param person - person with updates to persist.
     */
    updatePersonAsync(orgRoute, person) {
        return __awaiter(this, void 0, void 0, function* () {
            const ret = yield api_1.PeopleService.updateAsync(orgRoute, person.route, person);
            return ret;
        });
    }
    /**
     * Deletes the specified person.
     * @param orgRoute - route of the organization with which the person is associated.
     * @param personRoute - route of the person to delete.
     */
    deletePersonAsync(orgRoute, personRoute) {
        return __awaiter(this, void 0, void 0, function* () {
            yield api_1.PeopleService.delete(orgRoute, personRoute);
            if (orgRoute === this.lastOrgRoute) {
                yield this.refresh();
            }
        });
    }
    /**
     * Invites a person to an organization.
     * @param orgRoute - route of the organization to which people should be invited.
     * @param invites - person invitation information.
     */
    invitePersonAsync(orgRoute, invites) {
        return __awaiter(this, void 0, void 0, function* () {
            const ret = yield api_1.PeopleService.inviteAsync(orgRoute, invites);
            if (orgRoute === this.lastOrgRoute) {
                this.refresh();
            }
            return ret;
        });
    }
}
// Export a singleton instance of the store
exports.PersonStore = new PersonStoreClass();
