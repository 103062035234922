"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ParticipantSummary = exports.GroupParticipantWrapper = void 0;
/** @jsx jsx */
const react_1 = require("@emotion/react");
const react_2 = __importStar(require("react"));
const reactstrap_1 = require("reactstrap");
const react_fontawesome_1 = require("@fortawesome/react-fontawesome");
const api_1 = require("@soundbite/api");
const LabelInfo_1 = require("./LabelInfo");
const ShowWhen_1 = require("./ShowWhen");
const free_solid_svg_icons_1 = require("@fortawesome/free-solid-svg-icons");
const __1 = require("../..");
const mobx_react_lite_1 = require("mobx-react-lite");
/***************************************************************************************************
 *  Constants / Global Variables
 **************************************************************************************************/
const getStyles = () => {
    const ret = (0, react_1.css) `
    & > ul:last-child {
      margin-bottom: 0;
    }

    & .sb-participant-block svg {
      margin-right: 0.25rem;
    }

    .sb-pg-expandable:hover {
      background-color: ${__1.GlobalTheme.current.colors.neutrals.n300};
      cursor: pointer;
    }

    .sb-block-list {
      padding-left: 0;
    }

    .sb-block-list:empty {
      display: none;
    }

    .sb-block-list li {
      display: inline-block;
      list-style: none;
      color: ${__1.GlobalTheme.current.colors.neutrals.n800};
      background-color: ${__1.GlobalTheme.current.colors.neutrals.n100};
      border-radius: 4px;
      padding: 2px 6px;
      margin: 2px;
    }

    .sb-block-list li:first-of-type {
      margin-left: 0;
    }

    .sb-participant-summary > ul:last-child {
      margin-bottom: 0;
    }

    .sb-participant-summary .sb-participant-block i {
      margin-right: 0.25rem;
    }
  `;
    return ret;
};
let styles = undefined;
/***************************************************************************************************
 *  Component Classes
 **************************************************************************************************/
class ComponentContent {
    constructor(hostInfo, title, info) {
        this.hostInfo = hostInfo;
        this.participantTitle = title;
        this.participantInfo = info;
    }
}
class GroupParticipantWrapper {
    constructor(item) {
        this.isExpanded = false;
        this.members = [];
        this.isInitialized = false;
        this.hasMoreMembers = undefined;
        this.isLoading = false;
        this.totalCount = undefined;
        this.skipCount = 0;
        this.item = item;
    }
}
exports.GroupParticipantWrapper = GroupParticipantWrapper;
/***************************************************************************************************
 *  Component
 **************************************************************************************************/
exports.ParticipantSummary = (0, mobx_react_lite_1.observer)((props) => {
    //////////[ Properties ]//////////////////////////////////////////////////////////////////////////
    const [isExpanded, setIsExpanded] = (0, react_2.useState)(false);
    const [reRenderIndex, setReRenderIndex] = (0, react_2.useState)(0);
    const [hosts, setHosts] = (0, react_2.useState)([]);
    const [hostGroups, setHostGroups] = (0, react_2.useState)([]);
    const [participants, setParticipants] = (0, react_2.useState)([]);
    const [participantGroups, setParticipantGroups] = (0, react_2.useState)([]);
    //////////[ Initialize ]//////////////////////////////////////////////////////////////////////////
    (0, react_2.useEffect)(() => {
        if (isExpanded != props.isExpanded) {
            toggleExpanded();
        }
    }, [props.isExpanded]);
    (0, react_2.useEffect)(() => {
        var _a, _b;
        if (props.session) {
            setHosts(((_a = props.session.participants) === null || _a === void 0 ? void 0 : _a.filter((p) => p.participantRole === api_1.ParticipantRole.Host)) || []);
            setParticipants(((_b = props.session.participants) === null || _b === void 0 ? void 0 : _b.filter((p) => p.participantRole !== api_1.ParticipantRole.Host)) || []);
            setHostGroups(uniqueGroups(props.session.groups, true));
            setParticipantGroups(uniqueGroups(props.session.groups, false));
            if (props.autoExpand === true && !isExpanded && !isBig()) {
                toggleExpanded();
            }
        }
    }, [props.session]);
    //////////[ Methods ]/////////////////////////////////////////////////////////////////////////////
    /**
     * Determines whether there are an excessive number of items to show initially.
     */
    function isBig() {
        var _a, _b;
        return ((_b = (_a = props.session) === null || _a === void 0 ? void 0 : _a.participants.length) !== null && _b !== void 0 ? _b : 0) > 128;
    }
    /**
     * Determines the appropriate terminology to use in the control based on session type.
     */
    function getContent(session) {
        switch (session.sessionType) {
            case api_1.SessionType.Announcement:
                return new ComponentContent("Records announcement", "Audience", "Listens to the announcement");
            case api_1.SessionType.Survey:
                return new ComponentContent("Listens to survey results", "Audience", "Responds to the survey");
            default:
                return new ComponentContent("Can edit or delete this Session", "Participants", "Can contribute to this Session");
        }
    }
    /**
     * Toggles the state of the expansion for the participant list
     */
    function toggleExpanded() {
        if (props.onToggleExpansion) {
            props.onToggleExpansion(!isExpanded);
        }
        setIsExpanded(!isExpanded);
    }
    /**
     * Reads the unique Groups with a set of ParticipantGroup, returning them in alphabetical order
     * @param arr - array of groups to filter and sort
     * @param lookForHost - flag indicating whether to look for the host (true) or audience (false) participants.
     */
    function uniqueGroups(arr, lookForHost) {
        const filtered = (arr === null || arr === void 0 ? void 0 : arr.filter((p) => lookForHost
            ? p.participantRole === api_1.ParticipantRole.Host
            : p.participantRole !== api_1.ParticipantRole.Host)) || undefined;
        if (!filtered)
            return [];
        // Pull out the unique groups
        const participantGroups = filtered.map((i) => new GroupParticipantWrapper(i));
        const unique = new Map();
        participantGroups.forEach((i) => {
            unique.set(i.item.groupRoute, i);
        });
        // Return them sorted
        const ret = Array.from(unique.values());
        ret.sort((a, b) => a.item.group.name > b.item.group.name
            ? 1
            : a.item.group.name === b.item.group.name
                ? a.item.group.name > b.item.group.name
                    ? 1
                    : -1
                : -1);
        return ret;
    }
    function toggleGroupExpanded(groupToExpand) {
        return __awaiter(this, void 0, void 0, function* () {
            groupToExpand.isExpanded = !groupToExpand.isExpanded;
            groupToExpand.isLoading = true;
            setReRenderIndex(reRenderIndex + 1);
            fetchMembers(groupToExpand);
        });
    }
    function fetchMembers(groupToExpand) {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function* () {
            if (groupToExpand.hasMoreMembers != false &&
                __1.WidgetStore.organizations.currentOrg &&
                props.session) {
                // Acquire "next" page of group members
                const result = yield api_1.SessionsService.readPariticpantsInGroup(__1.WidgetStore.organizations.currentOrg.details.route, props.session.route, groupToExpand.item.route, undefined, {
                    includesCounts: groupToExpand.totalCount === undefined,
                    skip: groupToExpand.skipCount,
                    take: 100,
                });
                // Update group information
                groupToExpand.members = [...groupToExpand.members, ...result.result];
                groupToExpand.totalCount =
                    groupToExpand.totalCount === undefined
                        ? result.totalCount
                        : (_a = groupToExpand.totalCount) !== null && _a !== void 0 ? _a : 0;
                groupToExpand.skipCount = groupToExpand.members.length;
                groupToExpand.hasMoreMembers =
                    groupToExpand.members.length < ((_b = groupToExpand.totalCount) !== null && _b !== void 0 ? _b : 0);
                // Force re-rendering
                setReRenderIndex(reRenderIndex + 2);
            }
        });
    }
    //////////[ Event Handlers ]//////////////////////////////////////////////////////////////////////
    //////////[ Component UI Helper Methods ]/////////////////////////////////////////////////////////
    function renderComponent(session) {
        var _a, _b, _c, _d;
        const content = getContent(session);
        const showRoleWarning = ((_c = (_b = (_a = __1.WidgetStore.organizations.currentOrg) === null || _a === void 0 ? void 0 : _a.permissions) === null || _b === void 0 ? void 0 : _b.minRoleForAudience) !== null && _c !== void 0 ? _c : api_1.PersonRole.Unknown) > api_1.PersonRole.Person &&
            !props.hideAudienceRoleWarning;
        const btnMsg = "Show Audience";
        if (styles == null) {
            styles = getStyles();
        }
        return ((0, react_1.jsx)("span", { className: `sb-participant-summary ${props.className}`, css: styles },
            (0, react_1.jsx)(ShowWhen_1.ShowWhen, { is: props.isExpandable && !isExpanded },
                (0, react_1.jsx)(reactstrap_1.Button, { onClick: toggleExpanded, color: "secondary", size: "sm", type: "button", outline: true, title: "Show Participants" }, btnMsg)),
            (0, react_1.jsx)(ShowWhen_1.ShowWhen, { is: isExpanded },
                (0, react_1.jsx)("div", null,
                    RenderSection("Hosts", content.hostInfo, hosts, hostGroups),
                    RenderSection(content.participantTitle, content.participantInfo, participants, participantGroups),
                    (0, react_1.jsx)(ShowWhen_1.ShowWhen, { is: showRoleWarning },
                        (0, react_1.jsx)("small", { className: "text-muted" },
                            "This audience is only visible to",
                            " ", (_d = __1.WidgetStore.organizations.currentOrg) === null || _d === void 0 ? void 0 :
                            _d.details.name,
                            " admins"))))));
    }
    function RenderParticipant(p) {
        return ((0, react_1.jsx)("li", { key: p.person.route, className: "sb-participant-block sb-participant-state-" +
                api_1.ParticipantState[p.participantState].toLowerCase() },
            (0, react_1.jsx)(ShowWhen_1.ShowWhen, { is: p.participantState === api_1.ParticipantState.Consumed },
                (0, react_1.jsx)(react_fontawesome_1.FontAwesomeIcon, { icon: free_solid_svg_icons_1.faHeadphones })),
            api_1.Utils.userDisplay(p.person.user)));
    }
    function RenderSection(label, labelDesc, people, groups) {
        return ((0, react_1.jsx)(react_2.default.Fragment, null,
            (0, react_1.jsx)(ShowWhen_1.ShowWhen, { is: people.length > 0 || groups.length > 0 },
                (0, react_1.jsx)(LabelInfo_1.LabelInfo, { label: label, info: labelDesc }),
                (0, react_1.jsx)(ShowWhen_1.ShowWhen, { is: people.length > 0 },
                    (0, react_1.jsx)("ul", { className: "sb-block-list" }, people.map((p) => RenderParticipant(p)))),
                (0, react_1.jsx)(ShowWhen_1.ShowWhen, { is: groups.length > 0 },
                    (0, react_1.jsx)("ul", { className: "sb-block-list" }, groups.map((group) => {
                        return ((0, react_1.jsx)(react_2.default.Fragment, { key: group.item.route },
                            (0, react_1.jsx)("li", { className: "sb-pg-expandable", onClick: () => toggleGroupExpanded(group) },
                                (0, react_1.jsx)(react_fontawesome_1.FontAwesomeIcon, { icon: reRenderIndex > 0 && group.isExpanded
                                        ? free_solid_svg_icons_1.faSquareMinus
                                        : free_solid_svg_icons_1.faSquarePlus }),
                                " ",
                                group.item.group.name),
                            (0, react_1.jsx)(ShowWhen_1.ShowWhen, { is: group.isExpanded },
                                group.members.map((p, index) => RenderParticipant(p)),
                                (0, react_1.jsx)(ShowWhen_1.ShowWhen, { is: group.hasMoreMembers === true },
                                    (0, react_1.jsx)("li", { className: "sb-pg-expandable", onClick: () => fetchMembers(group) }, "Load More")))));
                    }))))));
    }
    //////////[ Build Component UI ]//////////////////////////////////////////////////////////////////
    if (props.session !== undefined && props.session !== null) {
        return renderComponent(props.session);
    }
    else {
        // Do not render the component
        return (0, react_1.jsx)(react_2.default.Fragment, null);
    }
});
