"use strict";
/***************************************************************************************************
 * Auto-Generated File - do not modify because all changes will be lost
 **************************************************************************************************/
Object.defineProperty(exports, "__esModule", { value: true });
exports.FileType = void 0;
/**
* Automatically generated enum for Masticore.FileType
*/
var FileType;
(function (FileType) {
    FileType[FileType["Unknown"] = 0] = "Unknown";
    FileType[FileType["Mp3"] = 1] = "Mp3";
    FileType[FileType["Mp4"] = 2] = "Mp4";
    FileType[FileType["Mpg"] = 3] = "Mpg";
    FileType[FileType["Wav"] = 4] = "Wav";
    FileType[FileType["Webm"] = 5] = "Webm";
    FileType[FileType["MpgAudio"] = 6] = "MpgAudio";
    FileType[FileType["Mov"] = 7] = "Mov";
    FileType[FileType["Mkv"] = 8] = "Mkv";
    FileType[FileType["Wmv"] = 9] = "Wmv";
    FileType[FileType["Avi"] = 10] = "Avi";
    FileType[FileType["Flv"] = 11] = "Flv";
    FileType[FileType["F4v"] = 12] = "F4v";
})(FileType = exports.FileType || (exports.FileType = {}));
