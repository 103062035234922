"use strict";
/***************************************************************************************************
 * Auto-Generated File - do not modify because all changes will be lost
 **************************************************************************************************/
Object.defineProperty(exports, "__esModule", { value: true });
exports.MediaProcessingState = void 0;
/**
* Automatically generated enum for Soundbite.MediaProcessingState
*/
var MediaProcessingState;
(function (MediaProcessingState) {
    MediaProcessingState[MediaProcessingState["None"] = 0] = "None";
    MediaProcessingState[MediaProcessingState["Requested"] = 10] = "Requested";
    MediaProcessingState[MediaProcessingState["Complete"] = 20] = "Complete";
    MediaProcessingState[MediaProcessingState["Failed"] = 30] = "Failed";
})(MediaProcessingState = exports.MediaProcessingState || (exports.MediaProcessingState = {}));
