"use strict";
// This file is directly imported from the react-select-async-paginate project due to React version conflicts
// https://github.com/vtaits/react-select-async-paginate
// At some point, we should catch up to React 17, then it should be possible to replace these files with the real npm package again
Object.defineProperty(exports, "__esModule", { value: true });
exports.useAsyncPaginate = exports.useAsyncPaginatePure = void 0;
const react_1 = require("react");
const useAsyncPaginateBase_1 = require("./useAsyncPaginateBase");
const useAsyncPaginatePure = (useStateParam, useCallbackParam, useAsyncPaginateBaseParam, params, deps = []) => {
    const { inputValue: inputValueParam, menuIsOpen: menuIsOpenParam, defaultInputValue: defaultInputValueParam, defaultMenuIsOpen: defaultMenuIsOpenParam, onInputChange: onInputChangeParam, onMenuClose: onMenuCloseParam, onMenuOpen: onMenuOpenParam, } = params;
    const [inputValueState, setInputValue] = useStateParam(defaultInputValueParam || "");
    const [menuIsOpenState, setMenuIsOpen] = useStateParam(!!defaultMenuIsOpenParam);
    const inputValue = typeof inputValueParam === "string" ? inputValueParam : inputValueState;
    const menuIsOpen = typeof menuIsOpenParam === "boolean" ? menuIsOpenParam : menuIsOpenState;
    const onInputChange = useCallbackParam((nextInputValue, actionMeta) => {
        if (onInputChangeParam) {
            onInputChangeParam(nextInputValue, actionMeta);
        }
        setInputValue(nextInputValue);
    }, [onInputChangeParam]);
    const onMenuClose = useCallbackParam(() => {
        if (onMenuCloseParam) {
            onMenuCloseParam();
        }
        setMenuIsOpen(false);
    }, [onMenuCloseParam]);
    const onMenuOpen = useCallbackParam(() => {
        if (onMenuOpenParam) {
            onMenuOpenParam();
        }
        setMenuIsOpen(true);
    }, [onMenuOpenParam]);
    const baseResult = useAsyncPaginateBaseParam(Object.assign(Object.assign({}, params), { inputValue,
        menuIsOpen }), deps);
    return Object.assign(Object.assign({}, baseResult), { inputValue,
        menuIsOpen,
        onInputChange,
        onMenuClose,
        onMenuOpen });
};
exports.useAsyncPaginatePure = useAsyncPaginatePure;
const useAsyncPaginate = (params, deps = []) => (0, exports.useAsyncPaginatePure)(react_1.useState, react_1.useCallback, useAsyncPaginateBase_1.useAsyncPaginateBase, params, deps);
exports.useAsyncPaginate = useAsyncPaginate;
