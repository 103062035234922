"use strict";
/***************************************************************************************************
 * Auto-Generated File - do not modify because all changes will be lost
 **************************************************************************************************/
Object.defineProperty(exports, "__esModule", { value: true });
exports.EventType = void 0;
/**
* Automatically generated enum for Masticore.Entity.EventType
*/
var EventType;
(function (EventType) {
    EventType[EventType["Unknown"] = 0] = "Unknown";
    EventType[EventType["Create"] = 1] = "Create";
    EventType[EventType["Read"] = 2] = "Read";
    EventType[EventType["Update"] = 3] = "Update";
    EventType[EventType["Delete"] = 4] = "Delete";
    EventType[EventType["Other"] = 5] = "Other";
})(EventType = exports.EventType || (exports.EventType = {}));
