"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SoloPlayer = void 0;
const react_1 = __importStar(require("react"));
const api_1 = require("@soundbite/api");
const Player_1 = require("./Player");
const controls_1 = require("./controls");
const SoloPlayer = (props) => {
    var _a, _b, _c, _d, _e, _f;
    // Not all clips are valid for display
    const allValidClips = (_c = (_b = (_a = props.session) === null || _a === void 0 ? void 0 : _a.prompts[0].clips) === null || _b === void 0 ? void 0 : _b.filter((i) => i.clipType != api_1.ClipType.RawClip && i.clipType != api_1.ClipType.RawTranscript)) !== null && _c !== void 0 ? _c : [];
    const clip = allValidClips[0];
    const displayName = api_1.Utils.userDisplay(clip.contributor);
    const imageUrl = (_d = clip.contributor.imageSrc) !== null && _d !== void 0 ? _d : api_1.SoundbiteApiConfig.imgAvatarUrl;
    const videoRef = react_1.default.useRef(null);
    const [isVideo, setIsVideo] = (0, react_1.useState)(false);
    const [isVideoLoading, setIsVideoLoading] = (0, react_1.useState)(true);
    /**
     * Handles event that fires when player establishes a media context. This helps determine whether
     * the video components should be displayed to users.
     */
    function onContext(context) {
        // Set media duration
        context.duration = clip.seconds;
        // Setup Video (if applicable)
        const isVideoValue = context.isVideo;
        if (isVideo != isVideoValue) {
            setIsVideo(isVideoValue);
        }
        if (props.onContext) {
            props.onContext(context);
        }
    }
    function onLoaded() {
        setIsVideoLoading(false);
    }
    function onLoading() {
        setIsVideoLoading(true);
    }
    return (react_1.default.createElement(controls_1.Loader, { isLoadedWhen: props.session != null },
        react_1.default.createElement(controls_1.ShowWhen, { is: isVideo, isDisplayBased: true },
            react_1.default.createElement(controls_1.Loader, { isDisplayBased: true, isLoadedWhen: !isVideoLoading || true },
                react_1.default.createElement("video", { className: "sb-video mb-2", ref: videoRef, "webkit-playsinline": true.toString(), playsInline: true, onLoadStart: onLoading, onLoadedMetadata: onLoaded }))),
        react_1.default.createElement("div", { className: "sb-soloplayer sb-cover-modal-body d-flex flex-row" },
            react_1.default.createElement(controls_1.ShowWhen, { is: clip != null },
                react_1.default.createElement("div", { className: "d-none d-sm-block align-self-center mr-1 px-0" },
                    react_1.default.createElement("span", { className: "avatar rounded-circle" },
                        react_1.default.createElement("img", { alt: "Avatar", src: imageUrl }))),
                react_1.default.createElement("div", { className: "align-self-center mx-1 px-0" },
                    react_1.default.createElement("h4", { className: "mb-0" }, displayName)),
                react_1.default.createElement(Player_1.Player, { clip: clip, allowDownload: props.allowDownload, videoElement: videoRef === null || videoRef === void 0 ? void 0 : videoRef.current, onContext: onContext, onFirstPlay: props.onFirstPlay, onMediaError: props.onMediaError, isPublic: ((_e = props.session) === null || _e === void 0 ? void 0 : _e.sessionSecurity) == api_1.SessionSecurityType.Public, autoPlay: props.autoPlay })),
            react_1.default.createElement(controls_1.ShowWhen, { is: clip === undefined },
                react_1.default.createElement("div", null,
                    react_1.default.createElement("h1", null,
                        "Unable to Load Clip for Session ", (_f = props.session) === null || _f === void 0 ? void 0 :
                        _f.name))))));
};
exports.SoloPlayer = SoloPlayer;
