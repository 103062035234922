"use strict";
/***************************************************************************************************
 * Auto-Generated File - do not modify because all changes will be lost
 **************************************************************************************************/
Object.defineProperty(exports, "__esModule", { value: true });
exports.SessionLifecycleStage = void 0;
/**
* Automatically generated enum for Soundbite.Models.SessionLifecycleStage
*/
var SessionLifecycleStage;
(function (SessionLifecycleStage) {
    SessionLifecycleStage[SessionLifecycleStage["Unknown"] = 0] = "Unknown";
    SessionLifecycleStage[SessionLifecycleStage["Draft"] = 100] = "Draft";
    SessionLifecycleStage[SessionLifecycleStage["UnderReview"] = 200] = "UnderReview";
    SessionLifecycleStage[SessionLifecycleStage["WaitingToRemind"] = 300] = "WaitingToRemind";
    SessionLifecycleStage[SessionLifecycleStage["WaitingToPublish"] = 400] = "WaitingToPublish";
    SessionLifecycleStage[SessionLifecycleStage["Published"] = 500] = "Published";
    SessionLifecycleStage[SessionLifecycleStage["Archived"] = 600] = "Archived";
    SessionLifecycleStage[SessionLifecycleStage["Hold"] = 700] = "Hold";
    SessionLifecycleStage[SessionLifecycleStage["Removed"] = 800] = "Removed";
})(SessionLifecycleStage = exports.SessionLifecycleStage || (exports.SessionLifecycleStage = {}));
