"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SummaryViewer = void 0;
/** @jsx jsx */
const react_1 = require("@emotion/react");
const react_2 = require("react");
const mobx_react_lite_1 = require("mobx-react-lite");
const api_1 = require("@soundbite/api");
const Loader_1 = require("./Loader");
const ShowWhen_1 = require("./ShowWhen");
const __1 = require("../..");
/***************************************************************************************************
 *  Constants / Global Variables
 **************************************************************************************************/
const getStyles = () => {
    const ret = (0, react_1.css) `
    & > ul:last-child {
      margin-bottom: 0;
    }

    & .sb-participant-block svg {
      margin-right: 0.25rem;
    }

    .sb-pg-expandable:hover {
      background-color: ${__1.GlobalTheme.current.colors.neutrals.n300};
      cursor: pointer;
    }

    .sb-block-list {
      padding-left: 0;
    }

    .sb-block-list:empty {
      display: none;
    }

    .sb-block-list li {
      display: inline-block;
      list-style: none;
      color: ${__1.GlobalTheme.current.colors.neutrals.n800};
      background-color: ${__1.GlobalTheme.current.colors.neutrals.n100};
      border-radius: 4px;
      padding: 2px 6px;
      margin: 2px;
    }

    .sb-block-list li:first-of-type {
      margin-left: 0;
    }

    .sb-transcript-viewer > ul:last-child {
      margin-bottom: 0;
    }

    .sb-transcript-viewer .sb-participant-block i {
      margin-right: 0.25rem;
    }

    .ttitle {
      margin-top: 8px;
    }

    .tsection {
      margin-bottom: 8px;
      margin-top: 8px;
    }
  `;
    return ret;
};
let styles = undefined;
/***************************************************************************************************
 *  State Enum
 **************************************************************************************************/
var ControlState;
(function (ControlState) {
    ControlState[ControlState["NotRequested"] = 0] = "NotRequested";
    ControlState[ControlState["Requested"] = 1] = "Requested";
    ControlState[ControlState["Retrieved"] = 2] = "Retrieved";
    ControlState[ControlState["Error"] = 3] = "Error";
    ControlState[ControlState["CannotRequest"] = 4] = "CannotRequest";
})(ControlState || (ControlState = {}));
/** React component for showing a session's transcript */
exports.SummaryViewer = (0, mobx_react_lite_1.observer)((props) => {
    //////////[ Properties ]//////////////////////////////////////////////////////////////////////////
    const [isExpanded, setIsExpanded] = (0, react_2.useState)(props.isExpanded);
    const [state, setState] = (0, react_2.useState)(ControlState.NotRequested);
    const [summary, setSummary] = (0, react_2.useState)(undefined);
    //////////[ Initialize ]//////////////////////////////////////////////////////////////////////////
    // Make sure to reset the control
    (0, react_2.useEffect)(() => {
        setState(ControlState.NotRequested);
        setSummary(undefined);
    }, [props.orgRoute, props.sessionRoute]);
    (0, react_2.useEffect)(() => {
        setIsExpanded(props.isExpanded);
        if (state != ControlState.Retrieved && props.isExpanded === true) {
            load();
        }
    }, [props.isExpanded]);
    //////////[ Methods ]/////////////////////////////////////////////////////////////////////////////
    function title() {
        switch (props.summaryType) {
            case api_1.SessionSummaryType.Paragraph:
                return "Summary";
            case api_1.SessionSummaryType.Social:
                return "Social";
            case api_1.SessionSummaryType.Tweet:
                return "Tweets";
            case api_1.SessionSummaryType.Blog:
                return "Blog";
            case api_1.SessionSummaryType.Newsletter:
                return "News";
            default:
                return "Summary";
        }
    }
    /**
     * Calls our API to acquire the summary
     */
    function load() {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function* () {
            // Make sure we have all necessary information to continue
            if (props.orgRoute && props.sessionRoute) {
                setState(ControlState.Requested);
                // Attempt to get the URL of the transcript
                try {
                    let sum = yield api_1.SessionsService.summaryAsync(props.orgRoute, props.sessionRoute, (_a = props.summaryType) !== null && _a !== void 0 ? _a : api_1.SessionSummaryType.Paragraph);
                    if (sum == null || sum.summary == null) {
                        setState(ControlState.CannotRequest);
                    }
                    else {
                        sum.summary = (_b = sum.summary) === null || _b === void 0 ? void 0 : _b.trim();
                        setSummary(sum);
                        setState(ControlState.Retrieved);
                    }
                }
                catch (ex) {
                    var msg = `Failed to retrieve session summary for session '${props.sessionRoute}' in organization ${props.orgRoute}`;
                    api_1.Logger.LogError(msg, ex);
                    setState(ControlState.Error);
                    throw new Error(msg);
                }
            }
            else {
                // We are missing key information so we cannot get the transcript
                var msg = "Failed to retrieve summary because of missing information.";
                api_1.Logger.LogError(msg);
                setState(ControlState.CannotRequest);
                throw new Error(msg);
            }
        });
    }
    /**
     * Determines the format/version of the transcript and offloads the processing
     * to the appropriate transcript text renderer.
     */
    function RenderSummary() {
        try {
            if (summary != null &&
                summary.summary != null &&
                summary.summary.trim().length > 0) {
                return ((0, react_1.jsx)("div", { style: { whiteSpace: "pre-line" } },
                    (0, react_1.jsx)("div", null, summary === null || summary === void 0 ? void 0 : summary.summary),
                    (0, react_1.jsx)("div", { className: "text-muted text-sm mt-1" }, "Powered by generative AI. May occasionally generate biased or incorrect information with limited knowledge up to the year 2021.")));
            }
            else {
                return (0, react_1.jsx)("div", null, "Summary not available");
            }
        }
        catch (_a) {
            return (0, react_1.jsx)("div", null, "Error Displaying Summary");
        }
    }
    //////////[ Event Handlers ]//////////////////////////////////////////////////////////////////////
    //////////[ Component UI Helper Methods ]/////////////////////////////////////////////////////////
    function renderComponent() {
        if (styles == null) {
            styles = getStyles();
        }
        return ((0, react_1.jsx)("span", { className: `sb-transcript-viewer ${props.className}`, css: styles },
            (0, react_1.jsx)(ShowWhen_1.ShowWhen, { is: isExpanded && state == ControlState.Requested },
                (0, react_1.jsx)(Loader_1.Loader, null)),
            (0, react_1.jsx)(ShowWhen_1.ShowWhen, { is: isExpanded && state == ControlState.Retrieved }, RenderSummary()),
            (0, react_1.jsx)(ShowWhen_1.ShowWhen, { is: isExpanded && state == ControlState.Error },
                (0, react_1.jsx)("div", null, "There was an issue loading the summary")),
            (0, react_1.jsx)(ShowWhen_1.ShowWhen, { is: isExpanded && state == ControlState.CannotRequest },
                (0, react_1.jsx)("div", null, "Session is missing information to request the summary"))));
    }
    //////////[ Build Component UI ]//////////////////////////////////////////////////////////////////
    return renderComponent();
});
