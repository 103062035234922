"use strict";
/***************************************************************************************************
 * Auto-Generated File - do not modify because all changes will be lost
 **************************************************************************************************/
Object.defineProperty(exports, "__esModule", { value: true });
exports.ParticipantRole = void 0;
/**
* Automatically generated enum for Soundbite.ParticipantRole
*/
var ParticipantRole;
(function (ParticipantRole) {
    ParticipantRole[ParticipantRole["Unknown"] = 0] = "Unknown";
    ParticipantRole[ParticipantRole["Audience"] = 25] = "Audience";
    ParticipantRole[ParticipantRole["Participant"] = 50] = "Participant";
    ParticipantRole[ParticipantRole["Host"] = 100] = "Host";
})(ParticipantRole = exports.ParticipantRole || (exports.ParticipantRole = {}));
