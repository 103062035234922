"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RecordBtn = void 0;
const react_1 = __importStar(require("react"));
const free_solid_svg_icons_1 = require("@fortawesome/free-solid-svg-icons");
const RecordDlg_1 = require("../components/RecordDlg");
const ErrorDlg_1 = require("../components/ErrorDlg");
const SbButton_1 = require("./SbButton");
/**
 * Opens the record dialog for the given org and session
 * @param props
 */
const RecordBtn = (props) => {
    //////////[ Properties ]//////////////////////////////////////////////////////////////////////////
    let [isOpen, setIsOpen] = (0, react_1.useState)(false);
    //////////[ Event Handlers ]//////////////////////////////////////////////////////////////////////
    function onError(err) {
        ErrorDlg_1.ErrorDlg.show(err, "Error Recording Session", "Likley could not make contact with server");
    }
    //////////[ Build Component UI ]//////////////////////////////////////////////////////////////////
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(SbButton_1.SbButton, { type: SbButton_1.SbButtonType.Primary, size: SbButton_1.SbButtonSize.Small, title: "Record Session", text: "Record", btnClassName: "sb-record-btn", icon: free_solid_svg_icons_1.faMicrophone, onClick: () => setIsOpen(true) }),
        react_1.default.createElement(RecordDlg_1.RecordDlg, { orgRoute: props.orgRoute, sessionRoute: props.sessionRoute, participantRole: props.participantRole, onError: onError, isOpen: isOpen, onClose: () => {
                setIsOpen(false);
            }, onSave: props.onSave })));
};
exports.RecordBtn = RecordBtn;
