"use strict";
/***************************************************************************************************
 * Auto-Generated File - do not modify because all changes will be lost
 **************************************************************************************************/
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SessionCommentsService = void 0;
const HttpService_1 = require("../../code/HttpService");
const SoundbiteApiConfig_1 = require("../../code/SoundbiteApiConfig");
/**
 * Automatically generated endpoint API for the Soundbite.Api.Controllers.SessionCommentsController
 **/
class SessionCommentsServiceClass {
    /**
    * Automatically generated API call
    */
    readAllSessionComments(orgRoute, sessionRoute, page, options) {
        var _a, _b, _c, _d;
        return __awaiter(this, void 0, void 0, function* () {
            const url = SoundbiteApiConfig_1.SoundbiteApiConfig.ApiPrefixUrl + `/organizations/${encodeURIComponent(orgRoute)}/Sessions/${encodeURIComponent(sessionRoute)}/comments?&filter=${encodeURIComponent((_a = page === null || page === void 0 ? void 0 : page.filter) !== null && _a !== void 0 ? _a : "")}&includesCounts=${encodeURIComponent((_b = page === null || page === void 0 ? void 0 : page.includesCounts) !== null && _b !== void 0 ? _b : "")}&skip=${encodeURIComponent((_c = page === null || page === void 0 ? void 0 : page.skip) !== null && _c !== void 0 ? _c : "")}&take=${encodeURIComponent((_d = page === null || page === void 0 ? void 0 : page.take) !== null && _d !== void 0 ? _d : "")}`;
            const response = yield HttpService_1.HttpService.get(url, options);
            return response;
        });
    }
    /**
    * Automatically generated API call
    */
    createSessionComment(orgRoute, sessionRoute, newComment, options) {
        return __awaiter(this, void 0, void 0, function* () {
            const url = SoundbiteApiConfig_1.SoundbiteApiConfig.ApiPrefixUrl + `/organizations/${encodeURIComponent(orgRoute)}/Sessions/${encodeURIComponent(sessionRoute)}/comments`;
            const response = yield HttpService_1.HttpService.post(url, newComment, options);
            return response;
        });
    }
    /**
    * Automatically generated API call
    */
    updateSessionComment(orgRoute, sessionRoute, commentRoute, newComment, options) {
        return __awaiter(this, void 0, void 0, function* () {
            const url = SoundbiteApiConfig_1.SoundbiteApiConfig.ApiPrefixUrl + `/organizations/${encodeURIComponent(orgRoute)}/Sessions/${encodeURIComponent(sessionRoute)}/comments/${encodeURIComponent(commentRoute)}`;
            yield HttpService_1.HttpService.put(url, newComment, options);
        });
    }
    /**
    * Automatically generated API call
    */
    deleteSessionComment(orgRoute, sessionRoute, commentRoute, options) {
        return __awaiter(this, void 0, void 0, function* () {
            const url = SoundbiteApiConfig_1.SoundbiteApiConfig.ApiPrefixUrl + `/organizations/${encodeURIComponent(orgRoute)}/Sessions/${encodeURIComponent(sessionRoute)}/comments/${encodeURIComponent(commentRoute)}`;
            yield HttpService_1.HttpService.delete(url, options);
        });
    }
}
exports.SessionCommentsService = new SessionCommentsServiceClass();
