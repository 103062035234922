"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Avatar = void 0;
const react_1 = __importStar(require("react"));
const api_1 = require("@soundbite/api");
const __1 = require("..");
/** Displays the given user in a standardized format, scaling by viewport size */
class Avatar extends react_1.Component {
    constructor() {
        super(...arguments);
        this.onClick = () => {
            if (this.props.onClick)
                this.props.onClick();
        };
    }
    render() {
        var _a, _b;
        const showName = (_a = this.props.showName) !== null && _a !== void 0 ? _a : true;
        const showTitle = this.props.showJobTitle === true && this.props.user.title != null;
        let avatarlUrl = api_1.SoundbiteApiConfig.imgAvatarUrl;
        if (this.props.user.imageSrc)
            avatarlUrl = this.props.user.imageSrc;
        let displayName = api_1.Utils.userDisplay(this.props.user);
        let outerClass = "media align-items-center";
        if (this.props.onClick)
            outerClass += " sb-clickable";
        if (this.props.className)
            outerClass += " " + this.props.className;
        const paddingRight = showName || showTitle ? "mr-3" : "mr-1";
        const sizingClassName = (_b = this.props.sizingClassName) !== null && _b !== void 0 ? _b : "d-none d-sm-block";
        return (react_1.default.createElement("div", { className: outerClass, onClick: this.onClick.bind(this), title: this.props.title },
            react_1.default.createElement("span", { className: `avatar rounded-circle ${paddingRight} ${sizingClassName}` },
                react_1.default.createElement("img", { alt: "Avatar", src: avatarlUrl })),
            react_1.default.createElement("div", { className: "media-body" },
                react_1.default.createElement(__1.ShowWhen, { is: this.props.alwaysHasEmail && showName },
                    react_1.default.createElement("span", { className: "name mb-0 text-sm" }, displayName)),
                react_1.default.createElement(__1.ShowWhen, { is: !this.props.alwaysHasEmail && showName },
                    react_1.default.createElement("div", { className: "d-md-none" },
                        react_1.default.createElement("span", { className: "name mb-0 text-sm" }, displayName)),
                    react_1.default.createElement("div", { className: "d-none d-md-block" },
                        react_1.default.createElement("span", { className: "name mb-0 text-sm" }, displayName))),
                react_1.default.createElement(__1.ShowWhen, { is: showTitle },
                    react_1.default.createElement("div", null,
                        react_1.default.createElement("span", { className: "name mb-0 text-sm text-muted font-weight-light" }, this.props.user.title))))));
    }
}
exports.Avatar = Avatar;
