"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.GroupPlayer = void 0;
const react_1 = __importStar(require("react"));
const reactstrap_1 = require("reactstrap");
const api_1 = require("@soundbite/api");
const GroupPlayerRow_1 = require("./GroupPlayerRow");
const controls_1 = require("./controls");
const SoundScrubber2_1 = require("./SoundScrubber2");
const MediaPlayerContextFactory_1 = require("./video/context/MediaPlayerContextFactory");
/***************************************************************************************************
 *  Component
 **************************************************************************************************/
/**
 * Displays a series of sessions allowing users to play through the list of content.
 */
const GroupPlayer = (props) => {
    //////////[ State ]///////////////////////////////////////////////////////////////////////////////
    var _a;
    const [contexts, setContexts] = (0, react_1.useState)([]);
    const [currentContextIndex, setCurrentContextIndex] = (0, react_1.useState)(0);
    //////////[ Variables ]///////////////////////////////////////////////////////////////////////////
    let clips = trimClipsBySessionType();
    //////////[ Initialize ]//////////////////////////////////////////////////////////////////////////
    (0, react_1.useEffect)(() => {
        const contexts = new Array(length);
        // Initialize the contexts and load media URLs
        for (let i = 0; i < contexts.length - 1; i++) {
            // There should be a 1:1 ratio of contexts to clips
            const currentClip = clips[i];
            contexts[i] = MediaPlayerContextFactory_1.MediaPlayerContextFactory.getContext(currentClip);
            contexts[i].onPlaybackComplete = () => {
                onRowEnded();
            };
            if (currentClip) {
                contexts[i].load(currentClip);
            }
        }
        setContexts(contexts);
    }, [props.session, (_a = props.session) === null || _a === void 0 ? void 0 : _a.prompts[0].clips]);
    //////////[ Methods ]/////////////////////////////////////////////////////////////////////////////
    /**
     * Responsible trimming down all cips in a session to only applicable clips
     */
    function trimClipsBySessionType() {
        var _a, _b;
        let result = ((_a = props.session) === null || _a === void 0 ? void 0 : _a.prompts[0].clips) || [];
        // For meetings trim down the clips to those other than the current user
        if (((_b = props.session) === null || _b === void 0 ? void 0 : _b.sessionType) === api_1.SessionType.Meeting) {
            result = result.filter((clip) => clip.contributor.route !== props.currentUserRoute);
        }
        return result;
    }
    //////////[ Event Handlers ]//////////////////////////////////////////////////////////////////////
    const onPlayToggle = () => {
        const context = contexts[currentContextIndex];
        if (context.isPlaying) {
            context.pause();
        }
        else {
            context.play();
        }
    };
    const onRowEnded = () => {
        // Determine if current context is NOT the last context.
        if (currentContextIndex < contexts.length - 2) {
            contexts[currentContextIndex + 1].play();
            setCurrentContextIndex(currentContextIndex + 1);
        }
    };
    //////////[ Build UI ]////////////////////////////////////////////////////////////////////////////
    return (react_1.default.createElement(reactstrap_1.Card, { className: "sb-teamplayer" },
        react_1.default.createElement(reactstrap_1.CardHeader, null,
            react_1.default.createElement(reactstrap_1.Row, null,
                react_1.default.createElement(reactstrap_1.Col, { xs: "6" },
                    react_1.default.createElement("h5", { className: "h3 mb-0" }, "Participants")),
                react_1.default.createElement(reactstrap_1.Col, null,
                    react_1.default.createElement(controls_1.PlayButton, { onToggle: onPlayToggle, context: contexts[currentContextIndex] })))),
        react_1.default.createElement(reactstrap_1.CardBody, null,
            react_1.default.createElement("ul", { className: "list-group list-group-flush list my--3" }, clips.map((clip, key) => {
                return (react_1.default.createElement(GroupPlayerRow_1.GroupPlayerRow, { key: key, clip: clip },
                    react_1.default.createElement(SoundScrubber2_1.Scrubber2, { context: contexts[key] })));
            })))));
};
exports.GroupPlayer = GroupPlayer;
