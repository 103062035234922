"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SessionCommentDelete = void 0;
const react_1 = __importStar(require("react"));
const reactstrap_1 = require("reactstrap");
const fontawesome_svg_core_1 = require("@fortawesome/fontawesome-svg-core");
const free_solid_svg_icons_1 = require("@fortawesome/free-solid-svg-icons");
const Loader_1 = require("./Loader");
const api_1 = require("@soundbite/api");
const ErrorDlg_1 = require("../ErrorDlg");
/***************************************************************************************************
 *  Constants / Global Variables
 **************************************************************************************************/
fontawesome_svg_core_1.library.add(free_solid_svg_icons_1.faPlus, free_solid_svg_icons_1.faTrash, free_solid_svg_icons_1.faComment);
/**
 * Control for taking in text and submitting it
 * @param props
 * @returns
 */
const SessionCommentDelete = (props) => {
    var _a, _b;
    const [isSaving, setIsSaving] = (0, react_1.useState)(false);
    /**
     * Callback for async adding a comment and resetting the submit box
     * @returns
     */
    function onCommitDelete() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                // Can't submit if props had junk in them
                if (props.orgRoute == null ||
                    props.sessionRoute == null ||
                    props.comment == null) {
                    return;
                }
                // Do the update lifecycle
                setIsSaving(true);
                yield api_1.SessionCommentsService.deleteSessionComment(props.orgRoute, props.sessionRoute, props.comment.route);
                yield props.onCommit();
                setIsSaving(false);
            }
            catch (err) {
                ErrorDlg_1.ErrorDlg.show(err, "Error Deleting Comment");
            }
        });
    }
    function onCancel() {
        props.onCancel();
    }
    /** Re-render when comments change */
    (0, react_1.useEffect)(() => { }, [props.comment]);
    return (react_1.default.createElement("div", { className: "sb-comment-edit mt-2" },
        react_1.default.createElement(Loader_1.Loader, { isLoadedWhen: !isSaving },
            react_1.default.createElement("div", null, (_a = props.comment) === null || _a === void 0 ? void 0 : _a.content),
            react_1.default.createElement(reactstrap_1.Row, { className: "sb-comment-subheader text-muted text-sm" },
                react_1.default.createElement(reactstrap_1.Col, null, api_1.Utils.formatRelativeDate((_b = props.comment) === null || _b === void 0 ? void 0 : _b.createdUtc)),
                react_1.default.createElement(reactstrap_1.Col, { className: "text-right" },
                    react_1.default.createElement("button", { onClick: onCommitDelete, className: "sb-link-btn text-danger", title: "Confirm Delete" }, "Delete"),
                    react_1.default.createElement("button", { onClick: onCancel, className: "sb-link-btn text-muted", title: "Cancel Delete" }, "Cancel"))))));
};
exports.SessionCommentDelete = SessionCommentDelete;
