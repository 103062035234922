"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SbButton = exports.SbButtonSize = exports.SbButtonType = void 0;
const react_1 = __importStar(require("react"));
const react_fontawesome_1 = require("@fortawesome/react-fontawesome");
/***************************************************************************************************
 *  Enums
 **************************************************************************************************/
var SbButtonType;
(function (SbButtonType) {
    SbButtonType[SbButtonType["Primary"] = 0] = "Primary";
    SbButtonType[SbButtonType["PrimaryOutline"] = 1] = "PrimaryOutline";
    SbButtonType[SbButtonType["Secondary"] = 2] = "Secondary";
    SbButtonType[SbButtonType["SecondaryOutline"] = 3] = "SecondaryOutline";
})(SbButtonType = exports.SbButtonType || (exports.SbButtonType = {}));
var SbButtonSize;
(function (SbButtonSize) {
    SbButtonSize[SbButtonSize["Small"] = 0] = "Small";
    SbButtonSize[SbButtonSize["Large"] = 1] = "Large";
})(SbButtonSize = exports.SbButtonSize || (exports.SbButtonSize = {}));
/**
 * Provides a standard way of creating buttons in Soundbite Studio.
 */
const SbButton = (props) => {
    const lastEventTime = (0, react_1.useRef)(0);
    //////////[ Methods - Utility ]///////////////////////////////////////////////////////////////////
    function getClassName() {
        const btnClassName = props.btnClassName
            ? ` ${props.btnClassName}`
            : "";
        const iconClass = props.icon ? " btn-icon" : "";
        let typeClass = "";
        let sizeClass = "btn-sm";
        switch (props.type) {
            case SbButtonType.Primary:
                typeClass = " btn-primary";
                break;
            case SbButtonType.PrimaryOutline:
                typeClass = " btn-outline-primary";
                break;
            case SbButtonType.Secondary:
                typeClass = " btn-secondary";
                break;
            case SbButtonType.SecondaryOutline:
                typeClass = " btn-outline-secondary";
                break;
        }
        switch (props.size) {
            case SbButtonSize.Large:
                sizeClass = " btn-lg";
                break;
            case SbButtonSize.Small:
            default:
                sizeClass = " btn-sm";
                break;
        }
        const result = `btn${iconClass}${typeClass}${sizeClass}${btnClassName}`;
        return result;
    }
    function TextContent() {
        if (props.children) {
            return react_1.default.createElement("span", { className: "btn-inner--text" }, props.children);
        }
        else {
            return react_1.default.createElement("span", { className: "btn-inner--text" }, props.text);
        }
    }
    function ButtonContent() {
        const hasText = props.text || props.children;
        if (props.icon && hasText) {
            return (react_1.default.createElement("span", { className: "btn-inner--icon" },
                react_1.default.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: props.icon }),
                react_1.default.createElement(TextContent, null)));
        }
        else if (props.icon) {
            return (react_1.default.createElement("span", { className: "btn-inner--icon" },
                react_1.default.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: props.icon })));
        }
        else {
            return react_1.default.createElement(TextContent, null);
        }
    }
    function onInteract(event) {
        var _a;
        const currentTime = new Date().getTime();
        const timeDiff = currentTime - lastEventTime.current;
        // Check if the time difference between the current event and the last event is less than a threshold (e.g., 300 ms)
        if (timeDiff < 300) {
            // Prevent the event from firing if the time difference is too small
            event === null || event === void 0 ? void 0 : event.preventDefault();
            event === null || event === void 0 ? void 0 : event.stopPropagation();
            return;
        }
        // Update the last event time
        lastEventTime.current = currentTime;
        (_a = props === null || props === void 0 ? void 0 : props.onClick) === null || _a === void 0 ? void 0 : _a.call(props);
    }
    //////////[ Build Component UI ]//////////////////////////////////////////////////////////////////
    return (react_1.default.createElement("button", { type: "button", className: getClassName(), title: props.title, onClick: onInteract, onTouchEnd: onInteract, onPointerUp: onInteract, disabled: props.disabled, style: props.style },
        react_1.default.createElement(ButtonContent, null)));
};
exports.SbButton = SbButton;
