"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PublicError = void 0;
const Logger_1 = require("./Logger");
/**
 * Error class for communicating issues back to the user - because exceptions are the UI of APIs
 * This wraps lower-level errors such that the technical information is still available
 * */
class PublicError extends Error {
    static Log(error, innerError) {
        // TODO: Put in the real support page
        const errorJson = (error !== null && error !== void 0 ? error : innerError) != null
            ? JSON.stringify(error, null, 2)
            : "UNKNOWN";
        Logger_1.Logger.LogError(`Unexpected error. Consider emailing Support@Soundbite.Freshdesk.Com or visiting https://soundbite.ai/ ${errorJson}`, error);
        if (innerError)
            Logger_1.Logger.LogError("Additional Error Info:", innerError);
    }
    constructor(innerError, name, message) {
        super(message);
        this.name = "Application Error";
        this.message = "Echoes of the sound of silence...";
        this.innerError = innerError;
        if (name)
            this.name = name;
        if (message)
            this.message = message;
        const apiError = this.ApiError;
        if (apiError != null && apiError.isUserSafe) {
            this.message = `${apiError.message}: ${apiError.statusCode} response trying to ${apiError.method} to ${apiError.path}`;
        }
        PublicError.Log(apiError, this.innerError);
    }
    get ApiError() {
        var _a, _b;
        const data = (_b = (_a = this.innerError) === null || _a === void 0 ? void 0 : _a.response) === null || _b === void 0 ? void 0 : _b.data;
        if (data == null) {
            return undefined;
        }
        if (!data.isApiError) {
            return undefined;
        }
        const apiError = data;
        console.error("Consider contacting Support@Soundbite.Freshdesk.Com regarding API error", apiError);
        return apiError;
    }
}
exports.PublicError = PublicError;
