"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AskAccess = void 0;
const api_1 = require("@soundbite/api");
const react_1 = __importStar(require("react"));
const reactstrap_1 = require("reactstrap");
const store_1 = require("../store");
const controls_1 = require("./controls");
const ErrorDlg_1 = require("./ErrorDlg");
/**
 * Provides a standard way of creating buttons in Soundbite Studio.
 */
const AskAccess = (props) => {
    var _a, _b;
    const [isLoading, setIsLoading] = (0, react_1.useState)(true);
    const [admins, setAdmins] = (0, react_1.useState)();
    const actionMsg = `Request ${props.roleMsg} Access`;
    (0, react_1.useEffect)(() => {
        const load = () => __awaiter(void 0, void 0, void 0, function* () {
            try {
                setIsLoading(true);
                // If we don't have an orgRoute yet, then abort
                if (props.orgRoute == null) {
                    return;
                }
                const response = yield api_1.PeopleService.readAllAsync(props.orgRoute, {
                    personRole: api_1.PersonRole.Admin,
                });
                setAdmins(response.result);
            }
            catch (err) {
                ErrorDlg_1.ErrorDlg.show(err, "Error Retrieving List of Admins");
            }
            finally {
                setIsLoading(false);
            }
        });
        load();
    }, [props.orgRoute]);
    function onRequestAccess(person) {
        var _a, _b;
        let email = person.user.email;
        if (person.user.givenName != null) {
            const receiverName = api_1.Utils.userDisplay(person.user);
            email = `${receiverName}<${person.user.email}>`;
        }
        let subject = actionMsg;
        const currentUser = (_b = (_a = store_1.WidgetStore.organizations) === null || _a === void 0 ? void 0 : _a.currentOrg) === null || _b === void 0 ? void 0 : _b.details.me;
        if (currentUser != null) {
            const currentUserName = api_1.Utils.userDisplay(currentUser === null || currentUser === void 0 ? void 0 : currentUser.user);
            subject = subject + " for " + currentUserName;
        }
        let body = "";
        if (person.user.givenName != null) {
            body += `${person.user.givenName},\r\n\r\n`;
        }
        body += `I'm reaching out to request access to create content in Soundbite. I can be added at the organization level or scoped just to a group. Let me know what you need from me to start creating content for my team?\r\n\r\n`;
        const supportEmail = "Support@Soundbite.Freshdesk.Com";
        body += `For more information on how organization and group permissions work in Soundbite, contact Soundbite Support: ${supportEmail}`;
        const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
        // Create a link element, set the href, and click it programmatically
        const link = document.createElement("a");
        link.href = mailtoLink;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
    //////////[ Build Component UI ]//////////////////////////////////////////////////////////////////
    const hasMoreThanOneAdmin = ((_a = admins === null || admins === void 0 ? void 0 : admins.length) !== null && _a !== void 0 ? _a : 0) > 1;
    return (react_1.default.createElement("div", { className: `border-0 ${(_b = props.className) !== null && _b !== void 0 ? _b : ""}` },
        react_1.default.createElement(controls_1.Loader, { isLoadedWhen: props.orgRoute != null && !isLoading },
            react_1.default.createElement("p", { className: "align-items-center text-muted" }, props.title),
            react_1.default.createElement("table", { className: "table align-items-center" },
                react_1.default.createElement("thead", { className: "thead-light" },
                    react_1.default.createElement("tr", null,
                        react_1.default.createElement("th", { scope: "col", className: "sort", "data-sort": "name" }, "Admin"),
                        react_1.default.createElement("th", { scope: "col", className: "sort", "data-sort": "action" }))),
                react_1.default.createElement("tbody", null, admins === null || admins === void 0 ? void 0 : admins.map((person) => {
                    return (react_1.default.createElement("tr", { key: person.route },
                        react_1.default.createElement("th", { scope: "row" },
                            react_1.default.createElement(controls_1.Avatar, { user: person.user, onClick: () => onRequestAccess(person), title: "Show Member Profile" })),
                        react_1.default.createElement("td", { className: "text-right" },
                            react_1.default.createElement(controls_1.ShowWhen, { is: hasMoreThanOneAdmin },
                                react_1.default.createElement(reactstrap_1.Button, { onClick: () => onRequestAccess(person), color: "primary", size: "sm", type: "button", title: actionMsg }, actionMsg)))));
                }))))));
};
exports.AskAccess = AskAccess;
