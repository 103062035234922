"use strict";
/***************************************************************************************************
 * Auto-Generated File - do not modify because all changes will be lost
 **************************************************************************************************/
Object.defineProperty(exports, "__esModule", { value: true });
exports.Recurrence = void 0;
/**
* Automatically generated enum for Masticore.Recurrence
*/
var Recurrence;
(function (Recurrence) {
    Recurrence[Recurrence["NoRepeat"] = 0] = "NoRepeat";
    Recurrence[Recurrence["Weekday"] = 1] = "Weekday";
    Recurrence[Recurrence["Daily"] = 2] = "Daily";
    Recurrence[Recurrence["Weekly"] = 3] = "Weekly";
    Recurrence[Recurrence["Monthly"] = 4] = "Monthly";
})(Recurrence = exports.Recurrence || (exports.Recurrence = {}));
