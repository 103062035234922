"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.InfiniteScrollDataHelper = void 0;
const ErrorDlg_1 = require("../components/ErrorDlg");
/**
 * Helper that ensures only one active call can occur for an infinite scroll table at a time.
 * Data from each subsequent call is stored off for processing when the current call completes.
 * Each subsequent call also overwrites any previous subsequent calls.  The theory here is that
 * when the current request completes the last subsequent call fires because it represents the
 * current position of the table and all the other requests have been scrolled by and are no
 * longer applicable.
 */
class InfiniteScrollDataHelper {
    constructor(getMoreItemsAction) {
        this.requestPromise = null;
        this.waitPromise = null;
        this.waitStart = 0;
        this.waitStop = 0;
        this.getMoreItemsAction = getMoreItemsAction;
    }
    getMoreItems(startIndex, stopIndex) {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.requestPromise == null) {
                this.requestPromise = this.getMoreItemsAction(startIndex, stopIndex);
                this.requestPromise.catch((err) => {
                    ErrorDlg_1.ErrorDlg.show(err, "Error Loading Sessions", `Error querying for sessions ${startIndex} to ${stopIndex}:`);
                });
                this.requestPromise.then(() => (this.requestPromise = null));
                return this.requestPromise;
            }
            else {
                this.waitStart = startIndex;
                this.waitStop = stopIndex;
                if (this.waitPromise == null) {
                    this.waitPromise = new Promise((resolve, reject) => __awaiter(this, void 0, void 0, function* () {
                        try {
                            yield this.requestPromise;
                            this.requestPromise = this.getMoreItemsAction(this.waitStart, this.waitStop);
                            yield this.requestPromise;
                            this.waitPromise = null;
                            this.requestPromise = null;
                            resolve();
                        }
                        catch (ex) {
                            reject(ex);
                        }
                    }));
                }
                return this.waitPromise;
            }
        });
    }
}
exports.InfiniteScrollDataHelper = InfiniteScrollDataHelper;
