"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DialogStyleType = void 0;
/**
 * Enumeration of the various dialog styles
 */
var DialogStyleType;
(function (DialogStyleType) {
    /**
     * Denotes the dialog is a normal dialog
     */
    DialogStyleType[DialogStyleType["Normal"] = 0] = "Normal";
    /**
     * Denotes that the dialog is a big ol' boy
     */
    DialogStyleType[DialogStyleType["Big"] = 1] = "Big";
    /**
     * Denotes that the dialog exposes potentially dangers operations
     */
    DialogStyleType[DialogStyleType["Danger"] = 2] = "Danger";
    /**
     * Denotes that this is the new session dialog
     */
    DialogStyleType[DialogStyleType["SessionEditor"] = 3] = "SessionEditor";
    /**
     * Denotes taht this is the big new session dialog
     */
    DialogStyleType[DialogStyleType["SessionEditorBig"] = 4] = "SessionEditorBig";
})(DialogStyleType = exports.DialogStyleType || (exports.DialogStyleType = {}));
