"use strict";
/***************************************************************************************************
 * Auto-Generated File - do not modify because all changes will be lost
 **************************************************************************************************/
Object.defineProperty(exports, "__esModule", { value: true });
exports.SyncScope = void 0;
/**
* Automatically generated enum for Masticore.DirectorySync.SyncScope
*/
var SyncScope;
(function (SyncScope) {
    SyncScope[SyncScope["Organization"] = 0] = "Organization";
    SyncScope[SyncScope["Region"] = 1] = "Region";
})(SyncScope = exports.SyncScope || (exports.SyncScope = {}));
