"use strict";
/***************************************************************************************************
 * Auto-Generated File - do not modify because all changes will be lost
 **************************************************************************************************/
Object.defineProperty(exports, "__esModule", { value: true });
exports.SecurityOpType = void 0;
/**
* Automatically generated enum for Masticore.SecurityOpType
*/
var SecurityOpType;
(function (SecurityOpType) {
    SecurityOpType[SecurityOpType["Create"] = 0] = "Create";
    SecurityOpType[SecurityOpType["Read"] = 1] = "Read";
    SecurityOpType[SecurityOpType["Update"] = 2] = "Update";
    SecurityOpType[SecurityOpType["Delete"] = 3] = "Delete";
    SecurityOpType[SecurityOpType["Manage"] = 4] = "Manage";
})(SecurityOpType = exports.SecurityOpType || (exports.SecurityOpType = {}));
