"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PlayButton = void 0;
/** @jsx jsx */
const react_1 = require("@emotion/react");
const mobx_react_lite_1 = require("mobx-react-lite");
const SbProgressButton_1 = require("./SbProgressButton");
/***************************************************************************************************
 *  Component
 **************************************************************************************************/
exports.PlayButton = (0, mobx_react_lite_1.observer)((props) => {
    //////////[ Event Handlers ]//////////////////////////////////////////////////////////////////////
    var _a, _b;
    /**
     * Handles the click event of the play button.
     */
    function onClick() {
        var _a, _b, _c;
        const isPlaying = ((_a = props === null || props === void 0 ? void 0 : props.context) === null || _a === void 0 ? void 0 : _a.isPlaying) === true;
        if (isPlaying) {
            (_b = props.context) === null || _b === void 0 ? void 0 : _b.pause();
        }
        else {
            (_c = props.context) === null || _c === void 0 ? void 0 : _c.play();
        }
        if (props.onToggle) {
            props.onToggle(isPlaying);
        }
    }
    //////////[ Build Component UI ]//////////////////////////////////////////////////////////////////
    const title = ((_a = props.context) === null || _a === void 0 ? void 0 : _a.isPlaying) === true ? "Pause" : "Play";
    return ((0, react_1.jsx)("div", { className: "sb-play-button" },
        (0, react_1.jsx)(SbProgressButton_1.SbProgressButton, { onClick: onClick, isRunning: ((_b = props.context) === null || _b === void 0 ? void 0 : _b.isPlaying) === true, allowClickOnLoading: true, disabled: props.disabled, title: title })));
});
