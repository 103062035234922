"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SessionCommentCreate = void 0;
/** @jsx jsx */
const react_1 = require("@emotion/react");
const react_2 = require("react");
const reactstrap_1 = require("reactstrap");
const fontawesome_svg_core_1 = require("@fortawesome/fontawesome-svg-core");
const free_solid_svg_icons_1 = require("@fortawesome/free-solid-svg-icons");
const SbButton_1 = require("../SbButton");
const Loader_1 = require("./Loader");
const api_1 = require("@soundbite/api");
const ErrorDlg_1 = require("../ErrorDlg");
const ShowWhen_1 = require("./ShowWhen");
/***************************************************************************************************
 *  Constants / Global Variables
 **************************************************************************************************/
fontawesome_svg_core_1.library.add(free_solid_svg_icons_1.faPlus, free_solid_svg_icons_1.faTrash, free_solid_svg_icons_1.faComment);
const getStyles = () => {
    const ret = (0, react_1.css) ``;
    return ret;
};
let styles = undefined;
/**
 * Control for taking in text and submitting it
 * @param props
 * @returns
 */
const SessionCommentCreate = (props) => {
    var _a;
    const maxCharacters = (_a = props.maxCharacters) !== null && _a !== void 0 ? _a : 150;
    const [newComment, setNewComment] = (0, react_2.useState)("");
    const [isSaving, setIsSaving] = (0, react_2.useState)(false);
    const inputRef = (0, react_2.useRef)(null);
    /**
     * Callback for async adding a comment and resetting the submit box
     * @returns
     */
    function onCommit() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const content = newComment.trim();
                // Can't submit something too long
                if (content.length > maxCharacters || content.length === 0) {
                    return;
                }
                if (props.orgRoute == null || props.sessionRoute == null) {
                    return;
                }
                setIsSaving(true);
                yield api_1.SessionCommentsService.createSessionComment(props.orgRoute, props.sessionRoute, { content });
                setNewComment("");
                setIsSaving(false);
                yield props.onCommit();
                // After we're done processing, set focus to the input
                // This focus interaction is the reason this control and its parent must use
                // isDisplayBased={true} in its attributes; perhaps figure out a better way
                if (inputRef.current) {
                    inputRef.current.focus();
                }
            }
            catch (err) {
                ErrorDlg_1.ErrorDlg.show(err, "Error Creating Comment");
            }
        });
    }
    const onKeyDown = (event) => {
        if (event.key === "Enter") {
            onCommit();
        }
    };
    /** Re-render when comments change */
    (0, react_2.useEffect)(() => { }, [newComment]);
    // Setting up render state
    if (styles == null) {
        styles = getStyles();
    }
    const isWarning = newComment.length > maxCharacters - 50;
    const isBeyondLimit = newComment.length > maxCharacters;
    let warningClass = "";
    if (isWarning) {
        warningClass = "text-warning";
    }
    if (isBeyondLimit) {
        warningClass = "text-danger";
    }
    return ((0, react_1.jsx)("div", { className: "sb-comment-create mt-2", css: styles },
        (0, react_1.jsx)(Loader_1.Loader, { isLoadedWhen: !isSaving, isDisplayBased: true },
            (0, react_1.jsx)(reactstrap_1.FormGroup, null,
                (0, react_1.jsx)(reactstrap_1.InputGroup, { "aria-disabled": isSaving },
                    (0, react_1.jsx)("input", { type: "text", className: "form-control", placeholder: "Add Comment...", "aria-label": "Title", "aria-describedby": "basic-addon1", onChange: (e) => setNewComment(e.target.value), disabled: isSaving, title: "Add Comment", value: newComment, onKeyDown: onKeyDown, maxLength: maxCharacters, ref: inputRef }),
                    (0, react_1.jsx)(reactstrap_1.InputGroupAddon, { className: "sb-addon-btn sb-add-comment-btn-addon", addonType: "append" },
                        (0, react_1.jsx)(SbButton_1.SbButton, { type: SbButton_1.SbButtonType.Primary, size: SbButton_1.SbButtonSize.Small, btnClassName: "sb-addon-btn sb-add-comment-btn", title: "Add Comment", onClick: onCommit, icon: free_solid_svg_icons_1.faArrowUp, disabled: isBeyondLimit }))),
                (0, react_1.jsx)(ShowWhen_1.ShowWhen, { is: isWarning },
                    (0, react_1.jsx)("div", { className: `${warningClass} text-sm` },
                        "Max ",
                        newComment.length,
                        "/",
                        maxCharacters,
                        " characters allowed"))))));
};
exports.SessionCommentCreate = SessionCommentCreate;
