"use strict";
/***************************************************************************************************
 * Auto-Generated File - do not modify because all changes will be lost
 **************************************************************************************************/
Object.defineProperty(exports, "__esModule", { value: true });
exports.SessionNotificationType = void 0;
/**
* Automatically generated enum for Soundbite.SessionNotificationType
*/
var SessionNotificationType;
(function (SessionNotificationType) {
    SessionNotificationType[SessionNotificationType["Unknown"] = 0] = "Unknown";
    SessionNotificationType[SessionNotificationType["Reminder"] = 10] = "Reminder";
    SessionNotificationType[SessionNotificationType["Publish"] = 20] = "Publish";
    SessionNotificationType[SessionNotificationType["HostPublish"] = 30] = "HostPublish";
})(SessionNotificationType = exports.SessionNotificationType || (exports.SessionNotificationType = {}));
