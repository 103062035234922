"use strict";
/***************************************************************************************************
 * Auto-Generated File - do not modify because all changes will be lost
 **************************************************************************************************/
Object.defineProperty(exports, "__esModule", { value: true });
exports.CollectionSyncMode = void 0;
/**
* Automatically generated enum for Masticore.DirectorySync.CollectionSyncMode
*/
var CollectionSyncMode;
(function (CollectionSyncMode) {
    CollectionSyncMode[CollectionSyncMode["None"] = 0] = "None";
    CollectionSyncMode[CollectionSyncMode["Some"] = 1] = "Some";
    CollectionSyncMode[CollectionSyncMode["All"] = 2] = "All";
})(CollectionSyncMode = exports.CollectionSyncMode || (exports.CollectionSyncMode = {}));
