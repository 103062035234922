"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.sortSessions = exports.sortBy = exports.compareLex = void 0;
const compareLex = (left = "", right = "") => {
    if (left < right) {
        return -1;
    }
    if (left > right) {
        return 1;
    }
    return 0;
};
exports.compareLex = compareLex;
/**
 * Sorts the given collection using the given functio to get the target field
 * @param collection
 * @param getFunc
 */
const sortBy = (getFunc, collection) => {
    if (collection == null)
        return undefined;
    const ret = collection.sort(function (left, right) {
        const leftVal = getFunc(left);
        const rightVal = getFunc(right);
        return (0, exports.compareLex)(leftVal, rightVal);
    });
    return ret;
};
exports.sortBy = sortBy;
/**
 * Sorts the given set of sessions, returning a new collection
 * @param sessions
 */
const sortSessions = (sessions) => {
    const ret = (0, exports.sortBy)((s) => {
        return s.publish;
    }, sessions);
    return ret;
};
exports.sortSessions = sortSessions;
//export const sortSeries = <SeriesType extends Series>(series?: SeriesType[]): SeriesType[] | undefined => {
//  const ret = sortBy((s) => { return s. }, series);
//  return ret;
//}
