"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.VideoEditorButtons = void 0;
const mobx_react_lite_1 = require("mobx-react-lite");
const react_1 = __importStar(require("react"));
const free_solid_svg_icons_1 = require("@fortawesome/free-solid-svg-icons");
const VideoEditorService_1 = require("./VideoEditorService");
const VideoEditorContext_1 = require("./VideoEditorContext");
const SbButton_1 = require("../SbButton");
/**
 * VideoEditor is the top-level editor component for users to create and manipulate videos.
 * @param props
 */
exports.VideoEditorButtons = (0, mobx_react_lite_1.observer)((props) => {
    //////////[ State ]///////////////////////////////////////////////////////////////////////////////
    const noContext = props.Context ? true : false;
    const context = props.Context
        ? props.Context
        : new VideoEditorContext_1.VideoEditorContext();
    //////////[ Initialize ]//////////////////////////////////////////////////////////////////////////
    (0, react_1.useEffect)(() => { }, [props.Context]);
    //////////[ Utility Methods ]/////////////////////////////////////////////////////////////////////
    //////////[ Event Handlers ]//////////////////////////////////////////////////////////////////////
    /**
     * Deletes the current video and allows the user to re-record a video.
     */
    function onResetVideo() {
        context.removeClipAt(0);
        context.showRecorder();
        if (props.onReset) {
            props.onReset();
        }
    }
    function onTrimStart() {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function* () {
            const clip = (_a = props.Context) === null || _a === void 0 ? void 0 : _a.videoClips[0];
            if (context.VideoElement && clip && context.VideoElement) {
                if (!context.VideoElement.paused) {
                    context.VideoElement.pause();
                }
                yield VideoEditorService_1.VideoEditorService.trimStart(clip, context.VideoElement.currentTime);
                context.VideoElement.src = (_b = clip.url) !== null && _b !== void 0 ? _b : "";
                context.VideoElement.currentTime = 0;
                context.VideoElement.load();
            }
        });
    }
    function onTrimEnd() { }
    //////////[ Build Component UI ]//////////////////////////////////////////////////////////////////
    /**
     * Renders the video editor.
     */
    function Render() {
        return (react_1.default.createElement(react_1.default.Fragment, null,
            react_1.default.createElement(SbButton_1.SbButton, { type: SbButton_1.SbButtonType.PrimaryOutline, size: SbButton_1.SbButtonSize.Large, icon: free_solid_svg_icons_1.faRedo, title: "Reset Recording", onClick: () => onResetVideo(), disabled: false })));
    }
    return Render();
});
