"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DialogButtonStyleType = void 0;
/**
 * Enumeration of the various dialog button styles
 */
var DialogButtonStyleType;
(function (DialogButtonStyleType) {
    /**
     * Denotes that the button is a primary button (more prominent)
     */
    DialogButtonStyleType[DialogButtonStyleType["Primary"] = 0] = "Primary";
    /**
     * Denotes that the button is a secondary button (less prominent)
     */
    DialogButtonStyleType[DialogButtonStyleType["Secondary"] = 1] = "Secondary";
    /**
     * Denotes that the button performs an action the user should consider (very prominent)
     */
    DialogButtonStyleType[DialogButtonStyleType["Danger"] = 3] = "Danger";
})(DialogButtonStyleType = exports.DialogButtonStyleType || (exports.DialogButtonStyleType = {}));
