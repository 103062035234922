"use strict";
/***************************************************************************************************
 * Auto-Generated File - do not modify because all changes will be lost
 **************************************************************************************************/
Object.defineProperty(exports, "__esModule", { value: true });
exports.TranscriptState = void 0;
/**
* Automatically generated enum for Soundbite.TranscriptState
*/
var TranscriptState;
(function (TranscriptState) {
    TranscriptState[TranscriptState["None"] = 0] = "None";
    TranscriptState[TranscriptState["Requested"] = 1] = "Requested";
    TranscriptState[TranscriptState["Available"] = 2] = "Available";
    TranscriptState[TranscriptState["Failed"] = 3] = "Failed";
})(TranscriptState = exports.TranscriptState || (exports.TranscriptState = {}));
