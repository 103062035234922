"use strict";
/***************************************************************************************************
 * Auto-Generated File - do not modify because all changes will be lost
 **************************************************************************************************/
Object.defineProperty(exports, "__esModule", { value: true });
exports.CodeGenScenarioType = void 0;
/**
* Automatically generated enum for Masticore.CodeGenScenarioType
*/
var CodeGenScenarioType;
(function (CodeGenScenarioType) {
    CodeGenScenarioType[CodeGenScenarioType["Normal"] = 0] = "Normal";
    CodeGenScenarioType[CodeGenScenarioType["FileUploadSingleFromFormData"] = 10] = "FileUploadSingleFromFormData";
    CodeGenScenarioType[CodeGenScenarioType["FileUploadSingleFromBlob"] = 20] = "FileUploadSingleFromBlob";
})(CodeGenScenarioType = exports.CodeGenScenarioType || (exports.CodeGenScenarioType = {}));
