"use strict";
/***************************************************************************************************
 * Auto-Generated File - do not modify because all changes will be lost
 **************************************************************************************************/
Object.defineProperty(exports, "__esModule", { value: true });
exports.PersonRole = void 0;
/**
* Automatically generated enum for Masticore.PersonRole
*/
var PersonRole;
(function (PersonRole) {
    PersonRole[PersonRole["Unknown"] = 0] = "Unknown";
    PersonRole[PersonRole["Guest"] = 25] = "Guest";
    PersonRole[PersonRole["Person"] = 50] = "Person";
    PersonRole[PersonRole["Admin"] = 100] = "Admin";
})(PersonRole = exports.PersonRole || (exports.PersonRole = {}));
