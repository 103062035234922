"use strict";
/***************************************************************************************************
 * Auto-Generated File - do not modify because all changes will be lost
 **************************************************************************************************/
Object.defineProperty(exports, "__esModule", { value: true });
exports.NotificationChannel = void 0;
/**
* Automatically generated enum for Soundbite.NotificationChannel
*/
var NotificationChannel;
(function (NotificationChannel) {
    NotificationChannel[NotificationChannel["None"] = 0] = "None";
    NotificationChannel[NotificationChannel["Email"] = 10] = "Email";
    NotificationChannel[NotificationChannel["SMS"] = 20] = "SMS";
    NotificationChannel[NotificationChannel["Teams"] = 30] = "Teams";
})(NotificationChannel = exports.NotificationChannel || (exports.NotificationChannel = {}));
