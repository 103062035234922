"use strict";
/***************************************************************************************************
 * Auto-Generated File - do not modify because all changes will be lost
 **************************************************************************************************/
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserRole = void 0;
/**
* Automatically generated enum for Masticore.UserRole
*/
var UserRole;
(function (UserRole) {
    UserRole[UserRole["Unknown"] = 0] = "Unknown";
    UserRole[UserRole["User"] = 50] = "User";
    UserRole[UserRole["God"] = 100] = "God";
})(UserRole = exports.UserRole || (exports.UserRole = {}));
