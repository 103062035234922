"use strict";
/***************************************************************************************************
 * Auto-Generated File - do not modify because all changes will be lost
 **************************************************************************************************/
Object.defineProperty(exports, "__esModule", { value: true });
exports.SyncOpType = void 0;
/**
* Automatically generated enum for Masticore.DirectorySync.SyncOpType
*/
var SyncOpType;
(function (SyncOpType) {
    SyncOpType[SyncOpType["Ignore"] = 0] = "Ignore";
    SyncOpType[SyncOpType["AddOrUpdate"] = 1] = "AddOrUpdate";
    SyncOpType[SyncOpType["UpdateOnly"] = 2] = "UpdateOnly";
    SyncOpType[SyncOpType["Remove"] = 3] = "Remove";
    SyncOpType[SyncOpType["MemberRegistration"] = 4] = "MemberRegistration";
    SyncOpType[SyncOpType["MemberRegistrationExistingUsersOnly"] = 5] = "MemberRegistrationExistingUsersOnly";
})(SyncOpType = exports.SyncOpType || (exports.SyncOpType = {}));
