"use strict";
/***************************************************************************************************
 * Auto-Generated File - do not modify because all changes will be lost
 **************************************************************************************************/
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SyncService = void 0;
const HttpService_1 = require("../../code/HttpService");
const SoundbiteApiConfig_1 = require("../../code/SoundbiteApiConfig");
/**
 * Automatically generated endpoint API for the Soundbite.Api.Controllers.SyncController
 **/
class SyncServiceClass {
    /**
    * Automatically generated API call
    */
    validateAsync(orgRoute, orgConfig, options) {
        return __awaiter(this, void 0, void 0, function* () {
            const url = SoundbiteApiConfig_1.SoundbiteApiConfig.ApiPrefixUrl + `/organizations/${encodeURIComponent(orgRoute)}/sync/validate`;
            const response = yield HttpService_1.HttpService.post(url, orgConfig !== null && orgConfig !== void 0 ? orgConfig : null, options);
            return response;
        });
    }
    /**
    * Automatically generated API call
    */
    readConfigAsync(orgRoute, options) {
        return __awaiter(this, void 0, void 0, function* () {
            const url = SoundbiteApiConfig_1.SoundbiteApiConfig.ApiPrefixUrl + `/organizations/${encodeURIComponent(orgRoute)}/sync/config`;
            const response = yield HttpService_1.HttpService.get(url, options);
            return response;
        });
    }
    /**
    * Automatically generated API call
    */
    updateConfigAsync(orgRoute, orgConfig, options) {
        return __awaiter(this, void 0, void 0, function* () {
            const url = SoundbiteApiConfig_1.SoundbiteApiConfig.ApiPrefixUrl + `/organizations/${encodeURIComponent(orgRoute)}/sync/config`;
            const response = yield HttpService_1.HttpService.post(url, orgConfig !== null && orgConfig !== void 0 ? orgConfig : null, options);
            return response;
        });
    }
    /**
    * Automatically generated API call
    */
    syncAsync(orgRoute, options) {
        return __awaiter(this, void 0, void 0, function* () {
            const url = SoundbiteApiConfig_1.SoundbiteApiConfig.ApiPrefixUrl + `/organizations/${encodeURIComponent(orgRoute)}/sync`;
            const response = yield HttpService_1.HttpService.post(url, null, options);
            return response;
        });
    }
    /**
    * Automatically generated API call
    */
    readResults(orgRoute, page, options) {
        var _a, _b, _c, _d;
        return __awaiter(this, void 0, void 0, function* () {
            const url = SoundbiteApiConfig_1.SoundbiteApiConfig.ApiPrefixUrl + `/organizations/${encodeURIComponent(orgRoute)}/sync/results?&filter=${encodeURIComponent((_a = page === null || page === void 0 ? void 0 : page.filter) !== null && _a !== void 0 ? _a : "")}&includesCounts=${encodeURIComponent((_b = page === null || page === void 0 ? void 0 : page.includesCounts) !== null && _b !== void 0 ? _b : "")}&skip=${encodeURIComponent((_c = page === null || page === void 0 ? void 0 : page.skip) !== null && _c !== void 0 ? _c : "")}&take=${encodeURIComponent((_d = page === null || page === void 0 ? void 0 : page.take) !== null && _d !== void 0 ? _d : "")}`;
            const response = yield HttpService_1.HttpService.get(url, options);
            return response;
        });
    }
    /**
    * Automatically generated API call
    */
    readUsersAsync(orgRoute, page, options) {
        var _a, _b, _c;
        return __awaiter(this, void 0, void 0, function* () {
            const url = SoundbiteApiConfig_1.SoundbiteApiConfig.ApiPrefixUrl + `/organizations/${encodeURIComponent(orgRoute)}/sync/users/page?&filter=${encodeURIComponent((_a = page === null || page === void 0 ? void 0 : page.filter) !== null && _a !== void 0 ? _a : "")}&skipToken=${encodeURIComponent((_b = page === null || page === void 0 ? void 0 : page.skipToken) !== null && _b !== void 0 ? _b : "")}&take=${encodeURIComponent((_c = page === null || page === void 0 ? void 0 : page.take) !== null && _c !== void 0 ? _c : "")}`;
            const response = yield HttpService_1.HttpService.get(url, options);
            return response;
        });
    }
    /**
    * Automatically generated API call
    */
    readUserAsync(orgRoute, userId, options) {
        return __awaiter(this, void 0, void 0, function* () {
            const url = SoundbiteApiConfig_1.SoundbiteApiConfig.ApiPrefixUrl + `/organizations/${encodeURIComponent(orgRoute)}/sync/users/${encodeURIComponent(userId)}`;
            const response = yield HttpService_1.HttpService.get(url, options);
            return response;
        });
    }
    /**
    * Automatically generated API call
    */
    readGroupsAsync(orgRoute, page, options) {
        var _a, _b, _c;
        return __awaiter(this, void 0, void 0, function* () {
            const url = SoundbiteApiConfig_1.SoundbiteApiConfig.ApiPrefixUrl + `/organizations/${encodeURIComponent(orgRoute)}/sync/groups/page?&filter=${encodeURIComponent((_a = page === null || page === void 0 ? void 0 : page.filter) !== null && _a !== void 0 ? _a : "")}&skipToken=${encodeURIComponent((_b = page === null || page === void 0 ? void 0 : page.skipToken) !== null && _b !== void 0 ? _b : "")}&take=${encodeURIComponent((_c = page === null || page === void 0 ? void 0 : page.take) !== null && _c !== void 0 ? _c : "")}`;
            const response = yield HttpService_1.HttpService.get(url, options);
            return response;
        });
    }
    /**
    * Automatically generated API call
    */
    readGroupAsync(orgRoute, groupId, options) {
        return __awaiter(this, void 0, void 0, function* () {
            const url = SoundbiteApiConfig_1.SoundbiteApiConfig.ApiPrefixUrl + `/organizations/${encodeURIComponent(orgRoute)}/sync/groups/${encodeURIComponent(groupId)}`;
            const response = yield HttpService_1.HttpService.get(url, options);
            return response;
        });
    }
}
exports.SyncService = new SyncServiceClass();
