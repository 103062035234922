"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SeriesStore = void 0;
const mobx_1 = require("mobx");
const api_1 = require("@soundbite/api");
const SessionStore_1 = require("./SessionStore");
/**
 * MobX store class containing states and actions for series
 */
class SeriesStoreClass {
    //////////[ Constructor ]/////////////////////////////////////////////////////////////////////////////
    constructor() {
        //////////[ Observable Data Properties ]//////////////////////////////////////////////////////////
        /* None */
        //////////[ Cache Members ]///////////////////////////////////////////////////////////////////////
        this.seriesCache = {};
        (0, mobx_1.makeObservable)(this, {});
    }
    /**
     * Responsible for clearing ALL cached data in the store.
     */
    clearCache() {
        this.seriesCache = {};
    }
    /**
     * Creates a key used for caching operations
     * @param orgRoute - route of the organization with which the session is associated
     * @param seriesRoute - route of the series
     */
    getCacheKey(orgRoute, seriesRoute) {
        return `${orgRoute}-${seriesRoute}`;
    }
    //////////[ Actions ]/////////////////////////////////////////////////////////////////////////////
    /**
     * Gets the specified series.
     * @param orgRoute - route of the organization with which the series is associated.
     * @param seriesRoute - route of the series to acquire.
     * @param allowCache - (optional) flag indicating whether caching is allowed (default:true)
     */
    readSeriesAsync(orgRoute, seriesRoute, allowCache = true) {
        return __awaiter(this, void 0, void 0, function* () {
            let series = allowCache
                ? this.seriesCache[this.getCacheKey(orgRoute, seriesRoute)]
                : null;
            if (!series) {
                series = yield api_1.SeriesService.readAsync(orgRoute, seriesRoute);
                this.seriesCache[this.getCacheKey(orgRoute, seriesRoute)] = series;
            }
            return series;
        });
    }
    /**
     * Updates the specified series.
     * @param orgRoute - route of the organization with which the series is associated.
     * @param series - updated series to save.
     */
    updateSeriesAsync(orgRoute, series) {
        return __awaiter(this, void 0, void 0, function* () {
            // Not Implemented?
        });
    }
    /**
     * Deletes the specified series.
     * @param orgRoute - route of the organization with which the series is assocaited.
     * @param seriesRoute - route of the series to delete.
     */
    deleteSeriesAsync(orgRoute, seriesRoute) {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function* () {
            yield api_1.SeriesService.deleteAsync(orgRoute, seriesRoute);
            // Remove from local
            SessionStore_1.SessionStore.orgSeries = (_a = SessionStore_1.SessionStore.orgSeries) === null || _a === void 0 ? void 0 : _a.filter((i) => i.route !== seriesRoute);
            SessionStore_1.SessionStore.groupSeries = (_b = SessionStore_1.SessionStore.groupSeries) === null || _b === void 0 ? void 0 : _b.filter((i) => i.route !== seriesRoute);
            delete this.seriesCache[this.getCacheKey(orgRoute, seriesRoute)];
        });
    }
}
// Export a singleton instance of the store
exports.SeriesStore = new SeriesStoreClass();
