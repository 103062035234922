"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.WidgetStore = void 0;
const mobx_1 = require("mobx");
const api_1 = require("@soundbite/api");
const DialogManager_1 = require("../dialogs/DialogManager");
const SessionStore_1 = require("./SessionStore");
const GroupStore_1 = require("./GroupStore");
const MemberStore_1 = require("./MemberStore");
const OrganizationStore_1 = require("./OrganizationStore");
const SyncStore_1 = require("./SyncStore");
const PersonStore_1 = require("./PersonStore");
const SeriesStore_1 = require("./SeriesStore");
const UserStore_1 = require("./UserStore");
const enums_1 = require("../enums");
const styles_1 = require("../styles");
/**
 * Acts as the primary integration and extensibility point for the Widget framework.  Essentially
 * this exposes properties that reference our default MobX store implementations for various aspects
 * of the application.  Clients can opt to implement custom MobX store implementations and assign
 * them to the properties in this class to "override" our default functionality.
 *
 * This class also exposes a series of "Show" methods intended to allow for the intercept of showing
 * various screens in the application.  By assigning a new method implementation to these properties
 * clients can override default navigation and dialog display in the application.
 */
class WidgetStoreClass {
    //////////[ Constructor ]/////////////////////////////////////////////////////////////////////////
    constructor() {
        //////////[ Properties ]//////////////////////////////////////////////////////////////////////////
        // Properties
        this.isInitialized = false;
        this.TokenRefreshTimeout = null;
        this.authState = enums_1.AuthState.Initializing; // Stores the application authentication state
        // Store references
        this.members = MemberStore_1.MemberStore;
        this.organizations = OrganizationStore_1.OrganizationStore;
        this.sync = SyncStore_1.OrgSyncStore;
        this.people = PersonStore_1.PersonStore;
        this.series = SeriesStore_1.SeriesStore;
        this.sessions = SessionStore_1.SessionStore;
        this.groups = GroupStore_1.GroupStore;
        this.users = UserStore_1.UserStore;
        this.showDeleteSession = this.showDeleteSessionHandler;
        this.showDeleteSeries = this.showDeleteSeriesHandler;
        this.showNewSession = this.showNewSessionHandler;
        this.showEditSession = this.showEditSessionHandler;
        this.showEditSeries = this.showEditSeriesHandler;
        this.showPlayer = this.showPlayerHandler;
        this.showRecorder = this.showRecorderHandler;
        this.showReports = this.showReportsHandler;
        (0, mobx_1.makeObservable)(this, {
            authState: mobx_1.observable,
            isInitialized: mobx_1.observable,
            initialize: mobx_1.action,
        });
    }
    //////////[ Methods ]/////////////////////////////////////////////////////////////////////////////
    /**
     * Gets true if the current user is the given user role or higher; otherwise, false
     * @param userRole
     */
    isUserRole(userRole) {
        var _a, _b;
        const currentRole = (_b = (_a = this.users.currentUser) === null || _a === void 0 ? void 0 : _a.userRole) !== null && _b !== void 0 ? _b : api_1.UserRole.Unknown;
        if (currentRole === api_1.UserRole.Unknown) {
            return false;
        }
        const isRoleOrBetter = currentRole >= userRole;
        return isRoleOrBetter;
    }
    /**
     * Get true if the given user is the given person role or higher for the current org; otherwise, false
     * @param personRole
     */
    isPersonRole(personRole) {
        var _a, _b, _c;
        const currentRole = (_c = (_b = (_a = this.organizations.currentOrg) === null || _a === void 0 ? void 0 : _a.details.me) === null || _b === void 0 ? void 0 : _b.personRole) !== null && _c !== void 0 ? _c : api_1.PersonRole.Unknown;
        if (currentRole === api_1.PersonRole.Unknown) {
            return false;
        }
        const isRoleOrBetter = this.isUserRole(api_1.UserRole.God) || currentRole >= personRole;
        return isRoleOrBetter;
    }
    /**
     * Get true if the given user is the given member role or higher for the current group; otherwise, false
     * @param memberRole
     */
    isMemberRole(memberRole, groupDetails) {
        // Not having the group yet implies we can't make the comparison whatsoever, so we must err on the side of saying they are NOT
        if (groupDetails == null) {
            return false;
        }
        const isGodOrAdmin = this.isUserRole(api_1.UserRole.God) || this.isPersonRole(api_1.PersonRole.Admin);
        const isRoleOrBetter = isGodOrAdmin || groupDetails.memberRole >= memberRole;
        return isRoleOrBetter;
    }
    /**
     * Responsible for populating the widget store with any available info from the app payload.
     * Method will setup token to be passed to all API calls if included in the app payload.
     * @param appPayload
     * @param refreshTimeout - number of minutes to wait before refreshing the API token
     */
    initialize(appPayload, refreshTimeout = 50) {
        var _a, _b;
        if (appPayload == null) {
            return;
        }
        if (appPayload.organization) {
            this.organizations.currentOrg = appPayload.organization;
        }
        if (appPayload.organizations) {
            this.organizations.myOrgs = appPayload.organizations;
        }
        if (appPayload.user) {
            this.users.currentUser = appPayload.user;
        }
        const orgRoute = appPayload === null || appPayload === void 0 ? void 0 : appPayload.organization.details.route;
        const orgTheme = (_b = (_a = appPayload.organization) === null || _a === void 0 ? void 0 : _a.settings) === null || _b === void 0 ? void 0 : _b.theme;
        if (orgTheme != null) {
            styles_1.GlobalTheme.set(orgTheme, orgRoute, true);
        }
        else {
            styles_1.GlobalTheme.clear(orgRoute);
        }
        if (appPayload.token) {
            const token = appPayload.token;
            api_1.SoundbiteApiConfig.getToken = () => Promise.resolve(token);
            if (this.TokenRefreshTimeout) {
                clearTimeout(this.TokenRefreshTimeout);
            }
            this.authState = !!this.organizations.currentOrg
                ? enums_1.AuthState.Authorized
                : enums_1.AuthState.OrganizationSelect;
            // Setup a timeout that refreshes the token periodically
            this.TokenRefreshTimeout = setTimeout(() => {
                if (this.organizations.currentOrg) {
                    const orgRoute = this.organizations.currentOrg.details.route;
                    const url = api_1.SoundbiteApiConfig.ApiPrefixUrl +
                        `/getToken/${encodeURIComponent(orgRoute)}`;
                    api_1.SoundbiteApiConfig.httpAdapter
                        .get(url)
                        .then((data) => {
                        var token = data.token;
                        api_1.SoundbiteApiConfig.getToken = () => Promise.resolve(token);
                        api_1.Logger.LogInfo("Retrieved new token.");
                    })
                        .catch((err) => {
                        api_1.Logger.LogError("Failed to retrieve new token.  Current token may expire.", err);
                    });
                }
            }, refreshTimeout * 60 * 1000);
        }
        else {
            // Token is not available which indicates that authentication is required
            this.authState = enums_1.AuthState.AuthRequired;
        }
        this.isInitialized = true;
        api_1.Logger.LogInfo("WidgetStore initialized");
    }
    reset() {
        // Reset all stores that keep local state
        // This should be a very similar list as refresh()
        this.organizations.reset();
        this.people.reset();
        this.sessions.reset();
        this.groups.reset();
        this.sync.reset();
    }
    refresh() {
        return __awaiter(this, void 0, void 0, function* () {
            // TODO: Consider making something to sync the orgRoute across these stores
            // They could theoretically fall out of sync
            // Refresh all stores that keep local state
            // This should be the same list as Reset()
            this.organizations.refresh();
            this.people.refresh();
            this.sessions.refresh();
            this.groups.refresh();
        });
    }
    //////////[ Dialog Handlers ]/////////////////////////////////////////////////////////////////////
    showPlayerHandler(orgRoute, sessionRoute) {
        DialogManager_1.DialogManager.ShowPlayerDialog(orgRoute, sessionRoute);
    }
    showRecorderHandler(orgRoute, sessionRoute) {
        DialogManager_1.DialogManager.ShowRecordDialog(orgRoute, sessionRoute);
    }
    showReportsHandler(orgRoute, session) {
        DialogManager_1.DialogManager.ShowReportsDialog(orgRoute, session);
    }
    showDeleteSeriesHandler(orgRoute, series) {
        DialogManager_1.DialogManager.ShowSeriesDeleteDialog(orgRoute, series);
    }
    showDeleteSessionHandler(orgRoute, session) {
        DialogManager_1.DialogManager.ShowSessionDeleteDialog(orgRoute, session);
    }
    showEditSessionHandler(orgRoute, session) {
        alert("showing new session, woohoo!");
    }
    showEditSeriesHandler(orgRoute, series) {
        DialogManager_1.DialogManager.ShowSeriesEditDialog(orgRoute, series);
    }
    showNewSessionHandler(orgRoute, groupRoute, isSeries = false, onClose) {
        DialogManager_1.DialogManager.ShowSessionNewDialog(orgRoute, groupRoute, isSeries, onClose);
    }
}
exports.WidgetStore = new WidgetStoreClass();
