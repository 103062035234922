"use strict";
/***************************************************************************************************
 * Auto-Generated File - do not modify because all changes will be lost
 **************************************************************************************************/
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SessionsService = void 0;
const HttpService_1 = require("../../code/HttpService");
const SoundbiteApiConfig_1 = require("../../code/SoundbiteApiConfig");
const index_1 = require("../enums/index");
/**
 * Automatically generated endpoint API for the Soundbite.Api.Controllers.SessionsController
 **/
class SessionsServiceClass {
    /**
    * Automatically generated API call
    * DO NOT USE; please check the documentation for its replacement
    * @deprecated
    */
    createAsync_Obsolete(orgRoute, newSession, options) {
        return __awaiter(this, void 0, void 0, function* () {
            const url = SoundbiteApiConfig_1.SoundbiteApiConfig.ApiPrefixUrl + `/organizations/${encodeURIComponent(orgRoute)}/Sessions`;
            const response = yield HttpService_1.HttpService.post(url, newSession, options);
            yield SoundbiteApiConfig_1.SoundbiteApiConfig.clientOpsHandler(response === null || response === void 0 ? void 0 : response.clientOps);
            return response.result;
        });
    }
    /**
    * Automatically generated API call
    */
    createAsync(orgRoute, newSession, options) {
        return __awaiter(this, void 0, void 0, function* () {
            const url = SoundbiteApiConfig_1.SoundbiteApiConfig.ApiPrefixUrl + `/organizations/${encodeURIComponent(orgRoute)}/Sessions/New`;
            const response = yield HttpService_1.HttpService.post(url, newSession, options);
            return response;
        });
    }
    /**
    * Automatically generated API call
    * DO NOT USE; please check the documentation for its replacement
    * @deprecated
    */
    readAllAsync(orgRoute, options) {
        return __awaiter(this, void 0, void 0, function* () {
            const url = SoundbiteApiConfig_1.SoundbiteApiConfig.ApiPrefixUrl + `/organizations/${encodeURIComponent(orgRoute)}/Sessions`;
            const response = yield HttpService_1.HttpService.get(url, options);
            return response;
        });
    }
    /**
    * Automatically generated API call
    */
    readPendingAsync(orgRoute, page, options) {
        var _a, _b, _c, _d;
        return __awaiter(this, void 0, void 0, function* () {
            const url = SoundbiteApiConfig_1.SoundbiteApiConfig.ApiPrefixUrl + `/organizations/${encodeURIComponent(orgRoute)}/Sessions/Pending?&filter=${encodeURIComponent((_a = page === null || page === void 0 ? void 0 : page.filter) !== null && _a !== void 0 ? _a : "")}&includesCounts=${encodeURIComponent((_b = page === null || page === void 0 ? void 0 : page.includesCounts) !== null && _b !== void 0 ? _b : "")}&skip=${encodeURIComponent((_c = page === null || page === void 0 ? void 0 : page.skip) !== null && _c !== void 0 ? _c : "")}&take=${encodeURIComponent((_d = page === null || page === void 0 ? void 0 : page.take) !== null && _d !== void 0 ? _d : "")}`;
            const response = yield HttpService_1.HttpService.get(url, options);
            return response;
        });
    }
    /**
    * Automatically generated API call
    */
    readPastAsync(orgRoute, page, options) {
        var _a, _b, _c, _d;
        return __awaiter(this, void 0, void 0, function* () {
            const url = SoundbiteApiConfig_1.SoundbiteApiConfig.ApiPrefixUrl + `/organizations/${encodeURIComponent(orgRoute)}/Sessions/Past?&filter=${encodeURIComponent((_a = page === null || page === void 0 ? void 0 : page.filter) !== null && _a !== void 0 ? _a : "")}&includesCounts=${encodeURIComponent((_b = page === null || page === void 0 ? void 0 : page.includesCounts) !== null && _b !== void 0 ? _b : "")}&skip=${encodeURIComponent((_c = page === null || page === void 0 ? void 0 : page.skip) !== null && _c !== void 0 ? _c : "")}&take=${encodeURIComponent((_d = page === null || page === void 0 ? void 0 : page.take) !== null && _d !== void 0 ? _d : "")}`;
            const response = yield HttpService_1.HttpService.get(url, options);
            return response;
        });
    }
    /**
    * Automatically generated API call
    * DO NOT USE; please check the documentation for its replacement
    * @deprecated
    */
    readFeedAsync(orgRoute, options) {
        return __awaiter(this, void 0, void 0, function* () {
            const url = SoundbiteApiConfig_1.SoundbiteApiConfig.ApiPrefixUrl + `/organizations/${encodeURIComponent(orgRoute)}/Feed`;
            const response = yield HttpService_1.HttpService.get(url, options);
            return response;
        });
    }
    /**
    * Automatically generated API call
    * DO NOT USE; please check the documentation for its replacement
    * @deprecated
    */
    readPublicFeedAsync(orgRoute, options) {
        return __awaiter(this, void 0, void 0, function* () {
            const url = SoundbiteApiConfig_1.SoundbiteApiConfig.ApiPrefixUrl + `/organizations/${encodeURIComponent(orgRoute)}/Public/Feed`;
            const response = yield HttpService_1.HttpService.get(url, options);
            return response;
        });
    }
    /**
    * Automatically generated API call
    * DO NOT USE; please check the documentation for its replacement
    * @deprecated
    */
    readAllGroupSessionsAsync(orgRoute, groupRoute, options) {
        return __awaiter(this, void 0, void 0, function* () {
            const url = SoundbiteApiConfig_1.SoundbiteApiConfig.ApiPrefixUrl + `/Organizations/${encodeURIComponent(orgRoute)}/Groups/${encodeURIComponent(groupRoute)}/Sessions`;
            const response = yield HttpService_1.HttpService.get(url, options);
            return response;
        });
    }
    /**
    * Automatically generated API call
    */
    readGroupPendingAsync(orgRoute, groupRoute, page, options) {
        var _a, _b, _c, _d;
        return __awaiter(this, void 0, void 0, function* () {
            const url = SoundbiteApiConfig_1.SoundbiteApiConfig.ApiPrefixUrl + `/organizations/${encodeURIComponent(orgRoute)}/Groups/${encodeURIComponent(groupRoute)}/Sessions/Pending?&filter=${encodeURIComponent((_a = page === null || page === void 0 ? void 0 : page.filter) !== null && _a !== void 0 ? _a : "")}&includesCounts=${encodeURIComponent((_b = page === null || page === void 0 ? void 0 : page.includesCounts) !== null && _b !== void 0 ? _b : "")}&skip=${encodeURIComponent((_c = page === null || page === void 0 ? void 0 : page.skip) !== null && _c !== void 0 ? _c : "")}&take=${encodeURIComponent((_d = page === null || page === void 0 ? void 0 : page.take) !== null && _d !== void 0 ? _d : "")}`;
            const response = yield HttpService_1.HttpService.get(url, options);
            return response;
        });
    }
    /**
    * Automatically generated API call
    */
    readGroupPastAsync(orgRoute, groupRoute, page, options) {
        var _a, _b, _c, _d;
        return __awaiter(this, void 0, void 0, function* () {
            const url = SoundbiteApiConfig_1.SoundbiteApiConfig.ApiPrefixUrl + `/organizations/${encodeURIComponent(orgRoute)}/Groups/${encodeURIComponent(groupRoute)}/Sessions/Past?&filter=${encodeURIComponent((_a = page === null || page === void 0 ? void 0 : page.filter) !== null && _a !== void 0 ? _a : "")}&includesCounts=${encodeURIComponent((_b = page === null || page === void 0 ? void 0 : page.includesCounts) !== null && _b !== void 0 ? _b : "")}&skip=${encodeURIComponent((_c = page === null || page === void 0 ? void 0 : page.skip) !== null && _c !== void 0 ? _c : "")}&take=${encodeURIComponent((_d = page === null || page === void 0 ? void 0 : page.take) !== null && _d !== void 0 ? _d : "")}`;
            const response = yield HttpService_1.HttpService.get(url, options);
            return response;
        });
    }
    /**
    * Automatically generated API call
    * DO NOT USE; please check the documentation for its replacement
    * @deprecated
    */
    readGroupFeedAsync(orgRoute, groupRoute, options) {
        return __awaiter(this, void 0, void 0, function* () {
            const url = SoundbiteApiConfig_1.SoundbiteApiConfig.ApiPrefixUrl + `/Organizations/${encodeURIComponent(orgRoute)}/Groups/${encodeURIComponent(groupRoute)}/Feed`;
            const response = yield HttpService_1.HttpService.get(url, options);
            return response;
        });
    }
    /**
    * Automatically generated API call
    * DO NOT USE; please check the documentation for its replacement
    * @deprecated
    */
    readGroupPublicFeedAsync(orgRoute, groupRoute, options) {
        return __awaiter(this, void 0, void 0, function* () {
            const url = SoundbiteApiConfig_1.SoundbiteApiConfig.ApiPrefixUrl + `/Organizations/${encodeURIComponent(orgRoute)}/Public/Groups/${encodeURIComponent(groupRoute)}/Feed`;
            const response = yield HttpService_1.HttpService.get(url, options);
            return response;
        });
    }
    /**
    * Automatically generated API call
    * DO NOT USE; please check the documentation for its replacement
    * @deprecated
    */
    readRecentlyPublishedAsync(orgRoute, skip = 0, take = 10, options) {
        return __awaiter(this, void 0, void 0, function* () {
            const url = SoundbiteApiConfig_1.SoundbiteApiConfig.ApiPrefixUrl + `/organizations/${encodeURIComponent(orgRoute)}/Sessions/Published?skip=${encodeURIComponent(skip)}&take=${encodeURIComponent(take)}`;
            const response = yield HttpService_1.HttpService.get(url, options);
            return response;
        });
    }
    /**
    * Automatically generated API call
    */
    readAsync(orgRoute, sessionRoute, options) {
        return __awaiter(this, void 0, void 0, function* () {
            const url = SoundbiteApiConfig_1.SoundbiteApiConfig.ApiPrefixUrl + `/organizations/${encodeURIComponent(orgRoute)}/Sessions/${encodeURIComponent(sessionRoute)}`;
            const response = yield HttpService_1.HttpService.get(url, options);
            return response;
        });
    }
    /**
    * Automatically generated API call
    */
    readReactionsAsync(orgRoute, sessionRoute, options) {
        return __awaiter(this, void 0, void 0, function* () {
            const url = SoundbiteApiConfig_1.SoundbiteApiConfig.ApiPrefixUrl + `/organizations/${encodeURIComponent(orgRoute)}/Sessions/${encodeURIComponent(sessionRoute)}/reactions`;
            const response = yield HttpService_1.HttpService.get(url, options);
            return response;
        });
    }
    /**
    * Automatically generated API call
    */
    summaryAsync(orgRoute, sessionRoute, summaryType = index_1.SessionSummaryType.Paragraph, options) {
        return __awaiter(this, void 0, void 0, function* () {
            const url = SoundbiteApiConfig_1.SoundbiteApiConfig.ApiPrefixUrl + `/organizations/${encodeURIComponent(orgRoute)}/Sessions/${encodeURIComponent(sessionRoute)}/summary?summaryType=${encodeURIComponent(summaryType)}`;
            const response = yield HttpService_1.HttpService.get(url, options);
            return response;
        });
    }
    /**
    * Automatically generated API call
    */
    readPublicAsync(orgRoute, sessionRoute, options) {
        return __awaiter(this, void 0, void 0, function* () {
            const url = SoundbiteApiConfig_1.SoundbiteApiConfig.ApiPrefixUrl + `/organizations/${encodeURIComponent(orgRoute)}/Public/Sessions/${encodeURIComponent(sessionRoute)}`;
            const response = yield HttpService_1.HttpService.get(url, options);
            return response;
        });
    }
    /**
    * Automatically generated API call
    */
    updateAsync(orgRoute, sessionRoute, session, options) {
        return __awaiter(this, void 0, void 0, function* () {
            const url = SoundbiteApiConfig_1.SoundbiteApiConfig.ApiPrefixUrl + `/organizations/${encodeURIComponent(orgRoute)}/Sessions/${encodeURIComponent(sessionRoute)}`;
            const response = yield HttpService_1.HttpService.put(url, session, options);
            return response;
        });
    }
    /**
    * Automatically generated API call
    */
    updateStateAsync(orgRoute, sessionRoute, partUpdate, options) {
        return __awaiter(this, void 0, void 0, function* () {
            const url = SoundbiteApiConfig_1.SoundbiteApiConfig.ApiPrefixUrl + `/organizations/${encodeURIComponent(orgRoute)}/Sessions/${encodeURIComponent(sessionRoute)}/state`;
            yield HttpService_1.HttpService.put(url, partUpdate, options);
        });
    }
    /**
    * Automatically generated API call
    */
    updateReactionAsync(orgRoute, sessionRoute, reactionType = index_1.ParticipantReactionType.None, options) {
        return __awaiter(this, void 0, void 0, function* () {
            const url = SoundbiteApiConfig_1.SoundbiteApiConfig.ApiPrefixUrl + `/organizations/${encodeURIComponent(orgRoute)}/Sessions/${encodeURIComponent(sessionRoute)}/reaction`;
            yield HttpService_1.HttpService.put(url, reactionType, options);
        });
    }
    /**
    * Automatically generated API call
    */
    acknowledgePublicSession(orgRoute, sessionRoute, userRoute, options) {
        return __awaiter(this, void 0, void 0, function* () {
            const url = SoundbiteApiConfig_1.SoundbiteApiConfig.ApiPrefixUrl + `/organizations/${encodeURIComponent(orgRoute)}/public/Sessions/${encodeURIComponent(sessionRoute)}/acknowledge?userRoute=${encodeURIComponent(userRoute !== null && userRoute !== void 0 ? userRoute : "")}`;
            yield HttpService_1.HttpService.put(url, null, options);
        });
    }
    /**
    * Automatically generated API call
    */
    deleteAsync(orgRoute, sessionRoute, options) {
        return __awaiter(this, void 0, void 0, function* () {
            const url = SoundbiteApiConfig_1.SoundbiteApiConfig.ApiPrefixUrl + `/organizations/${encodeURIComponent(orgRoute)}/Sessions/${encodeURIComponent(sessionRoute)}`;
            yield HttpService_1.HttpService.delete(url, options);
        });
    }
    /**
    * Automatically generated API call
    */
    setReminderCalendarEntryId(orgRoute, sessionRoute, reminderCalendarEventId, options) {
        return __awaiter(this, void 0, void 0, function* () {
            const url = SoundbiteApiConfig_1.SoundbiteApiConfig.ApiPrefixUrl + `/organizations/${encodeURIComponent(orgRoute)}/Sessions/${encodeURIComponent(sessionRoute)}/SetReminderCalendarEntryId`;
            yield HttpService_1.HttpService.post(url, reminderCalendarEventId, options);
        });
    }
    /**
    * Automatically generated API call
    */
    readParticipants(orgRoute, sessionRoute, includeRoles, page, options) {
        var _a, _b, _c, _d;
        return __awaiter(this, void 0, void 0, function* () {
            const url = SoundbiteApiConfig_1.SoundbiteApiConfig.ApiPrefixUrl + `/organizations/${encodeURIComponent(orgRoute)}/Sessions/${encodeURIComponent(sessionRoute)}/Participants/Page?includeRoles=${encodeURIComponent(includeRoles !== null && includeRoles !== void 0 ? includeRoles : "")}&&filter=${encodeURIComponent((_a = page === null || page === void 0 ? void 0 : page.filter) !== null && _a !== void 0 ? _a : "")}&includesCounts=${encodeURIComponent((_b = page === null || page === void 0 ? void 0 : page.includesCounts) !== null && _b !== void 0 ? _b : "")}&skip=${encodeURIComponent((_c = page === null || page === void 0 ? void 0 : page.skip) !== null && _c !== void 0 ? _c : "")}&take=${encodeURIComponent((_d = page === null || page === void 0 ? void 0 : page.take) !== null && _d !== void 0 ? _d : "")}`;
            const response = yield HttpService_1.HttpService.get(url, options);
            return response;
        });
    }
    /**
    * Automatically generated API call
    */
    readPariticpantsInGroup(orgRoute, sessionRoute, groupRoute, includeRoles, page, options) {
        var _a, _b, _c, _d;
        return __awaiter(this, void 0, void 0, function* () {
            const url = SoundbiteApiConfig_1.SoundbiteApiConfig.ApiPrefixUrl + `/organizations/${encodeURIComponent(orgRoute)}/Sessions/${encodeURIComponent(sessionRoute)}/Participants/Groups/${encodeURIComponent(groupRoute)}/Page?includeRoles=${encodeURIComponent(includeRoles !== null && includeRoles !== void 0 ? includeRoles : "")}&&filter=${encodeURIComponent((_a = page === null || page === void 0 ? void 0 : page.filter) !== null && _a !== void 0 ? _a : "")}&includesCounts=${encodeURIComponent((_b = page === null || page === void 0 ? void 0 : page.includesCounts) !== null && _b !== void 0 ? _b : "")}&skip=${encodeURIComponent((_c = page === null || page === void 0 ? void 0 : page.skip) !== null && _c !== void 0 ? _c : "")}&take=${encodeURIComponent((_d = page === null || page === void 0 ? void 0 : page.take) !== null && _d !== void 0 ? _d : "")}`;
            const response = yield HttpService_1.HttpService.get(url, options);
            return response;
        });
    }
}
exports.SessionsService = new SessionsServiceClass();
