"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SessionReactions = void 0;
/** @jsx jsx */
const react_1 = require("@emotion/react");
const react_2 = __importStar(require("react"));
const react_fontawesome_1 = require("@fortawesome/react-fontawesome");
const free_solid_svg_icons_1 = require("@fortawesome/free-solid-svg-icons");
const api_1 = require("@soundbite/api");
const controls_1 = require("./controls");
const reactstrap_1 = require("reactstrap");
/***************************************************************************************************
 *  Constants / Global Variables
 **************************************************************************************************/
const getStyles = () => {
    const ret = (0, react_1.css) `
    .btn-inner--text {
      font-size: 1rem;
      margin-left: 0.25rem;
      font-weight: 300;
    }

    svg {
      margin-top: 1px;
      width: 1.25rem;
      height: 1.25rem;
    }
  `;
    return ret;
};
let styles = undefined;
/**
 * Provides a standard way of creating buttons in Soundbite Studio.
 */
const SessionReactions = (props) => {
    //////////[ Methods ]///////////////////////////////////////////////////////////////////
    var _a, _b, _c, _d, _e, _f;
    const [reactions, setReactions] = (0, react_2.useState)((_a = props.session) === null || _a === void 0 ? void 0 : _a.reactions);
    const [myReaction, setMyReaction] = (0, react_2.useState)((_c = (_b = props.session) === null || _b === void 0 ? void 0 : _b.myParticipation[0]) === null || _c === void 0 ? void 0 : _c.reactionType);
    const sessionRoute = (_d = props.sessionRoute) !== null && _d !== void 0 ? _d : (_e = props.session) === null || _e === void 0 ? void 0 : _e.route;
    const isReadOnly = (_f = props.readOnly) !== null && _f !== void 0 ? _f : false;
    const applyReactions = (reactions) => {
        setReactions(reactions);
        setMyReaction(undefined);
    };
    // Load the session, but only at the scope of this control in case we need changes or they didn't load it before showing this
    const load = (forceLoad = false) => __awaiter(void 0, void 0, void 0, function* () {
        // If we have not context, then we can't load
        if (props.orgRoute == null) {
            return;
        }
        if (sessionRoute == null) {
            return;
        }
        // We only load if we haven't already OR they're making us do it
        const needToLoad = reactions == null && props.orgRoute != null && props.sessionRoute != null;
        if (forceLoad || needToLoad) {
            const newReactions = yield api_1.SessionsService.readReactionsAsync(props.orgRoute, sessionRoute);
            applyReactions(newReactions);
        }
    });
    (0, react_2.useEffect)(() => {
        const doLoad = () => {
            load();
        };
        doLoad();
    }, []);
    const removeReaction = (reactionType) => {
        if (props.session == null) {
            return;
        }
        setMyReaction(undefined);
        const oldReaction = props.session.reactions.find((r) => r.reactionType === reactionType);
        if (oldReaction == null) {
            return;
        }
        else {
            if (oldReaction.count <= 0) {
                return;
            }
            oldReaction.count -= 1;
        }
    };
    const addReaction = (reactionType) => {
        if (props.session == null) {
            return;
        }
        setMyReaction(reactionType);
        const oldReaction = props.session.reactions.find((r) => r.reactionType === reactionType);
        if (oldReaction == null) {
            props.session.reactions.push({ reactionType, count: 1 });
        }
        else {
            oldReaction.count += 1;
        }
    };
    const calcNewReaction = (reactionType) => {
        var _a;
        if (props.session == null) {
            return reactionType;
        }
        // Having no reactions also mean they don't verifiably have participant records on this session, though they might have a host role
        const oldReaction = (_a = props.session.myParticipation.find((p) => p.participantRole === api_1.ParticipantRole.Audience ||
            p.participantRole === api_1.ParticipantRole.Participant)) === null || _a === void 0 ? void 0 : _a.reactionType;
        if (oldReaction == null) {
            // It's a new reaction with no old one
            addReaction(reactionType);
            return reactionType;
        }
        else if (oldReaction === reactionType) {
            // It's the same reaction, so remove entirely
            removeReaction(oldReaction);
            return api_1.ParticipantReactionType.None;
        }
        else {
            // It's a novel reaction, so change it
            removeReaction(oldReaction);
            addReaction(reactionType);
            return reactionType;
        }
    };
    const onReaction = (reactionType) => __awaiter(void 0, void 0, void 0, function* () {
        if (props.orgRoute == null || sessionRoute == null) {
            return;
        }
        reactionType = calcNewReaction(reactionType);
        yield api_1.SessionsService.updateReactionAsync(props.orgRoute, sessionRoute, reactionType);
        yield load(true);
    });
    //////////[ Build Component UI ]//////////////////////////////////////////////////////////////////
    const iconForReactionType = (reactionType) => {
        switch (reactionType) {
            case api_1.ParticipantReactionType.None:
                return undefined;
            case api_1.ParticipantReactionType.Like:
                return free_solid_svg_icons_1.faThumbsUp;
            case api_1.ParticipantReactionType.Love:
                return free_solid_svg_icons_1.faHeart;
            case api_1.ParticipantReactionType.Laugh:
                return free_solid_svg_icons_1.faFaceLaugh;
            case api_1.ParticipantReactionType.Wow:
                return free_solid_svg_icons_1.faFaceFlushed;
            case api_1.ParticipantReactionType.Sad:
                return free_solid_svg_icons_1.faFrown;
            default:
                return undefined;
        }
    };
    const reactionBtn = (reactionType) => {
        var _a, _b;
        const iconName = iconForReactionType(reactionType);
        if (iconName == null) {
            return (0, react_1.jsx)(react_2.default.Fragment, null);
        }
        const number = (_b = (_a = reactions === null || reactions === void 0 ? void 0 : reactions.find((r) => r.reactionType === reactionType)) === null || _a === void 0 ? void 0 : _a.count) !== null && _b !== void 0 ? _b : 0;
        let classes = "";
        if (isReadOnly) {
            classes += "dark";
        }
        else {
            classes += "secondary";
        }
        let handler = isReadOnly
            ? undefined
            : () => {
                onReaction(reactionType);
            };
        const isMyReaction = myReaction === reactionType;
        return ((0, react_1.jsx)(reactstrap_1.Button, { outline: true, size: "sm", color: classes, className: classes, onClick: handler, disabled: isReadOnly, active: isMyReaction },
            (0, react_1.jsx)("span", { className: "btn-inner--icon" },
                (0, react_1.jsx)(react_fontawesome_1.FontAwesomeIcon, { icon: iconName })),
            (0, react_1.jsx)(controls_1.ShowWhen, { is: isReadOnly || number > 0 },
                (0, react_1.jsx)("span", { className: "btn-inner--text" }, number))));
    };
    if (styles == null) {
        styles = getStyles();
    }
    return ((0, react_1.jsx)("div", { className: "sb-reaction-summary mt-2 mb-2", css: styles },
        reactionBtn(api_1.ParticipantReactionType.Like),
        reactionBtn(api_1.ParticipantReactionType.Love),
        reactionBtn(api_1.ParticipantReactionType.Laugh),
        reactionBtn(api_1.ParticipantReactionType.Wow),
        reactionBtn(api_1.ParticipantReactionType.Sad)));
};
exports.SessionReactions = SessionReactions;
