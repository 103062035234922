"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ThemeConfig = void 0;
/** @jsx jsx */
const react_1 = require("@emotion/react");
const reactstrap_1 = require("reactstrap");
const api_1 = require("@soundbite/api");
const styles_1 = require("../../styles");
const ColorConfig_1 = require("./ColorConfig");
const controls_1 = require("../controls");
const WidgetStore_1 = require("../../store/WidgetStore");
const baseStyles = (0, react_1.css) `
  position: fixed;
  bottom: 0;
  left: 0;
  margin-right: auto;
  border-top: 1px solid ${styles_1.GlobalTheme.current.colors.neutrals.max};
  border-right: 1px solid ${styles_1.GlobalTheme.current.colors.neutrals.max};
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 0;
  max-height: 90%;
  max-width: 25rem;
  z-index: 2;

  .sb-theme-toggle label {
    margin: 0 0.5rem;
  }
`;
/** A card fixed in the view of the browser that enables changing theme elements */
const ThemeConfig = () => {
    var _a;
    const orgRoute = (_a = WidgetStore_1.WidgetStore.organizations.currentOrg) === null || _a === void 0 ? void 0 : _a.details.route;
    function onPreview() {
        if (orgRoute != null) {
            styles_1.GlobalTheme.current.name = api_1.ThemeName.Draft;
            styles_1.GlobalTheme.setDraft(styles_1.GlobalTheme.current, orgRoute);
            styles_1.GlobalTheme.apply();
        }
    }
    return ((0, react_1.jsx)(reactstrap_1.Card, { className: "sb-theme-config", css: baseStyles },
        (0, react_1.jsx)(reactstrap_1.CardBody, null,
            (0, react_1.jsx)("h1", { className: "text-sm text-uppercase" }, "Configure Theme"),
            (0, react_1.jsx)(ColorConfig_1.ColorConfig, null)),
        (0, react_1.jsx)(controls_1.ShowWhen, { is: orgRoute != null },
            (0, react_1.jsx)(reactstrap_1.CardFooter, null,
                (0, react_1.jsx)("div", { className: "text-right" },
                    (0, react_1.jsx)(reactstrap_1.Button, { color: "primary", onClick: onPreview }, "Preview"))))));
};
exports.ThemeConfig = ThemeConfig;
