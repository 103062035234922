"use strict";
/***************************************************************************************************
 * Auto-Generated File - do not modify because all changes will be lost
 **************************************************************************************************/
Object.defineProperty(exports, "__esModule", { value: true });
exports.SessionSecurityType = void 0;
/**
* Automatically generated enum for Masticore.SessionSecurityType
*/
var SessionSecurityType;
(function (SessionSecurityType) {
    SessionSecurityType[SessionSecurityType["Protected"] = 0] = "Protected";
    SessionSecurityType[SessionSecurityType["Public"] = 10] = "Public";
})(SessionSecurityType = exports.SessionSecurityType || (exports.SessionSecurityType = {}));
