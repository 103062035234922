"use strict";
/***************************************************************************************************
 * Auto-Generated File - do not modify because all changes will be lost
 **************************************************************************************************/
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MembersService = void 0;
const HttpService_1 = require("../../code/HttpService");
const SoundbiteApiConfig_1 = require("../../code/SoundbiteApiConfig");
/**
 * Automatically generated endpoint API for the Soundbite.Api.Controllers.MembersController
 **/
class MembersServiceClass {
    /**
    * Automatically generated API call
    * DO NOT USE; please check the documentation for its replacement
    * @deprecated
    */
    readAllAsync_Obsolete(orgRoute, groupRoute, options) {
        return __awaiter(this, void 0, void 0, function* () {
            const url = SoundbiteApiConfig_1.SoundbiteApiConfig.ApiPrefixUrl + `/Organizations/${encodeURIComponent(orgRoute)}/Groups/${encodeURIComponent(groupRoute)}/Members/`;
            const response = yield HttpService_1.HttpService.get(url, options);
            return response;
        });
    }
    /**
    * Automatically generated API call
    */
    readAllAsync(orgRoute, groupRoute, pageRequest, options) {
        var _a, _b, _c, _d;
        return __awaiter(this, void 0, void 0, function* () {
            const url = SoundbiteApiConfig_1.SoundbiteApiConfig.ApiPrefixUrl + `/Organizations/${encodeURIComponent(orgRoute)}/Groups/${encodeURIComponent(groupRoute)}/Members/Page?&filter=${encodeURIComponent((_a = pageRequest === null || pageRequest === void 0 ? void 0 : pageRequest.filter) !== null && _a !== void 0 ? _a : "")}&includesCounts=${encodeURIComponent((_b = pageRequest === null || pageRequest === void 0 ? void 0 : pageRequest.includesCounts) !== null && _b !== void 0 ? _b : "")}&skip=${encodeURIComponent((_c = pageRequest === null || pageRequest === void 0 ? void 0 : pageRequest.skip) !== null && _c !== void 0 ? _c : "")}&take=${encodeURIComponent((_d = pageRequest === null || pageRequest === void 0 ? void 0 : pageRequest.take) !== null && _d !== void 0 ? _d : "")}`;
            const response = yield HttpService_1.HttpService.get(url, options);
            return response;
        });
    }
    /**
    * Automatically generated API call
    */
    inviteAsync(orgRoute, groupRoute, invites, options) {
        return __awaiter(this, void 0, void 0, function* () {
            const url = SoundbiteApiConfig_1.SoundbiteApiConfig.ApiPrefixUrl + `/Organizations/${encodeURIComponent(orgRoute)}/Groups/${encodeURIComponent(groupRoute)}/Members/`;
            yield HttpService_1.HttpService.post(url, invites, options);
        });
    }
    /**
    * Automatically generated API call
    */
    updateAsync(orgRoute, groupRoute, memberRoute, member, options) {
        return __awaiter(this, void 0, void 0, function* () {
            const url = SoundbiteApiConfig_1.SoundbiteApiConfig.ApiPrefixUrl + `/Organizations/${encodeURIComponent(orgRoute)}/Groups/${encodeURIComponent(groupRoute)}/Members/${encodeURIComponent(memberRoute)}`;
            const response = yield HttpService_1.HttpService.patch(url, member, options);
            return response;
        });
    }
    /**
    * Automatically generated API call
    */
    deleteAsync(orgRoute, groupRoute, memberRoute, options) {
        return __awaiter(this, void 0, void 0, function* () {
            const url = SoundbiteApiConfig_1.SoundbiteApiConfig.ApiPrefixUrl + `/Organizations/${encodeURIComponent(orgRoute)}/Groups/${encodeURIComponent(groupRoute)}/Members/${encodeURIComponent(memberRoute)}`;
            yield HttpService_1.HttpService.delete(url, options);
        });
    }
}
exports.MembersService = new MembersServiceClass();
