"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PageUtils = void 0;
/** Utility class for page utilities */
class PageUtils {
    /**
     * Loads the given page into the given map, using the determined index for the page
     * @param page
     * @param map
     */
    static addPageToMap(page, map) {
        var _a;
        const skip = (_a = page.request.skip) !== null && _a !== void 0 ? _a : 0;
        for (let i = 0; i < page.result.length; ++i) {
            const person = page.result[i];
            map.set(skip + i, person);
        }
    }
    /**
     * Returns true if the given page response likely has more pages ahead of it
     * @param response
     */
    static hasMorePages(response) {
        var _a, _b;
        const request = response.request;
        // Be pessimistic for this one
        const skip = (_a = request === null || request === void 0 ? void 0 : request.skip) !== null && _a !== void 0 ? _a : 0;
        const take = (_b = request === null || request === void 0 ? void 0 : request.take) !== null && _b !== void 0 ? _b : 1;
        // At the end of all pages
        if (skip >= response.maxTotalResults) {
            return false;
        }
        if (response.totalCount != null) {
            return response.totalCount > skip + take;
        }
        return response.result.length >= take;
    }
}
exports.PageUtils = PageUtils;
