"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.VideoEditor = void 0;
const react_1 = __importStar(require("react"));
const VideoEditorContext_1 = require("./VideoEditorContext");
const VideoEditorButtons_1 = require("./VideoEditorButtons");
const VideoRecorderButtons_1 = require("./VideoRecorderButtons");
const mobx_react_lite_1 = require("mobx-react-lite");
const controls_1 = require("../controls");
const GlobalTheme_1 = require("../../styles/GlobalTheme");
/***************************************************************************************************
 *  Component
 **************************************************************************************************/
/**
 * VideoEditor is the top-level editor component for users to create and manipulate videos.
 * @param props
 */
exports.VideoEditor = (0, mobx_react_lite_1.observer)((props) => {
    //////////[ State ]///////////////////////////////////////////////////////////////////////////////
    const [editorContext] = (0, react_1.useState)(new VideoEditorContext_1.VideoEditorContext());
    const videoRef = react_1.default.createRef();
    const [isVideoLoading, setIsVideoLoading] = (0, react_1.useState)(true);
    //////////[ Initialize ]//////////////////////////////////////////////////////////////////////////
    (0, react_1.useEffect)(() => {
        // It has not which implies this is a new context
        editorContext.VideoElement = videoRef.current;
        editorContext.onClipAdded = (clip) => {
            editorContext.showEditor();
            editorContext.playClip(clip);
            if (props.onRecorded) {
                props.onRecorded(clip);
            }
        };
        editorContext.showRecorder();
        editorContext.startWebCam();
        return function cleanup() {
            editorContext.stopWebCam();
        };
    }, []);
    //////////[ Utility Methods ]/////////////////////////////////////////////////////////////////////
    //////////[ Event Handlers ]//////////////////////////////////////////////////////////////////////
    function onLoaded() {
        setIsVideoLoading(false);
    }
    function onLoading() {
        setIsVideoLoading(true);
    }
    //////////[ Build Component UI ]//////////////////////////////////////////////////////////////////
    /**
     * Renders a message informing the user that video recording is not supported by the browser.
     */
    function VideoNotSupported() {
        return (react_1.default.createElement(react_1.default.Fragment, null, "This browser does not appear to support video recording. Please upload a video or use a different browser."));
    }
    /**
     * Renders the video editor.
     */
    function VideoSupported() {
        const isEditing = (editorContext === null || editorContext === void 0 ? void 0 : editorContext.isEditing) === true;
        const backColor = GlobalTheme_1.GlobalTheme.current.colors.neutrals.n900;
        return (react_1.default.createElement(react_1.default.Fragment, null,
            react_1.default.createElement(controls_1.Loader, { isLoadedWhen: !isVideoLoading, isDisplayBased: true, style: { height: "184px" }, className: props.className },
                react_1.default.createElement("video", { autoPlay: true, playsInline: true, className: "rounded", controls: isEditing, ref: videoRef, style: {
                        width: "100%",
                        height: "100%",
                        backgroundColor: backColor,
                        transform: !editorContext.isEditing ? "scaleX(-1)" : "",
                    }, onLoadStart: onLoading, onLoadedMetadata: onLoaded }),
                react_1.default.createElement("div", { className: "d-flex align-items-center justify-content-center" }, (editorContext === null || editorContext === void 0 ? void 0 : editorContext.isEditing) === true ? (react_1.default.createElement(VideoEditorButtons_1.VideoEditorButtons, { Context: editorContext, onReset: props.onReset })) : (react_1.default.createElement(VideoRecorderButtons_1.VideoRecorderButtons, { Context: editorContext }))))));
    }
    return VideoEditorContext_1.VideoEditorContext.isVideoCaptureSupported()
        ? VideoSupported()
        : VideoNotSupported();
});
