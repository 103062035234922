"use strict";
/***************************************************************************************************
 * Auto-Generated File - do not modify because all changes will be lost
 **************************************************************************************************/
Object.defineProperty(exports, "__esModule", { value: true });
exports.JobStatusType = void 0;
/**
* Automatically generated enum for Masticore.JobStatusType
*/
var JobStatusType;
(function (JobStatusType) {
    JobStatusType[JobStatusType["None"] = 0] = "None";
    JobStatusType[JobStatusType["Requesting"] = 1] = "Requesting";
    JobStatusType[JobStatusType["Requested"] = 2] = "Requested";
    JobStatusType[JobStatusType["Processing"] = 3] = "Processing";
    JobStatusType[JobStatusType["Complete"] = 4] = "Complete";
    JobStatusType[JobStatusType["Failed"] = 5] = "Failed";
})(JobStatusType = exports.JobStatusType || (exports.JobStatusType = {}));
