"use strict";
/***************************************************************************************************
 * Auto-Generated File - do not modify because all changes will be lost
 **************************************************************************************************/
Object.defineProperty(exports, "__esModule", { value: true });
exports.ClipType = void 0;
/**
* Automatically generated enum for Soundbite.ClipType
*/
var ClipType;
(function (ClipType) {
    ClipType[ClipType["Prompt"] = 0] = "Prompt";
    ClipType[ClipType["Contribution"] = 1] = "Contribution";
    ClipType[ClipType["Comment"] = 2] = "Comment";
    ClipType[ClipType["RawClip"] = 3] = "RawClip";
    ClipType[ClipType["RawTranscript"] = 4] = "RawTranscript";
})(ClipType = exports.ClipType || (exports.ClipType = {}));
