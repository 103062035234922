"use strict";
/***************************************************************************************************
 * Auto-Generated File - do not modify because all changes will be lost
 **************************************************************************************************/
Object.defineProperty(exports, "__esModule", { value: true });
exports.ParticipantState = void 0;
/**
* Automatically generated enum for Soundbite.ParticipantState
*/
var ParticipantState;
(function (ParticipantState) {
    ParticipantState[ParticipantState["Unknown"] = 0] = "Unknown";
    ParticipantState[ParticipantState["Pending"] = 1] = "Pending";
    ParticipantState[ParticipantState["ContributionRequested"] = 2] = "ContributionRequested";
    ParticipantState[ParticipantState["Contributed"] = 3] = "Contributed";
    ParticipantState[ParticipantState["ConsumptionRequested"] = 4] = "ConsumptionRequested";
    ParticipantState[ParticipantState["Consumed"] = 5] = "Consumed";
    ParticipantState[ParticipantState["Unsubscribed"] = 6] = "Unsubscribed";
})(ParticipantState = exports.ParticipantState || (exports.ParticipantState = {}));
