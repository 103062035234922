"use strict";
/***************************************************************************************************
 * Auto-Generated File - do not modify because all changes will be lost
 **************************************************************************************************/
Object.defineProperty(exports, "__esModule", { value: true });
exports.ClipState = void 0;
/**
* Automatically generated enum for Soundbite.Models.ClipState
*/
var ClipState;
(function (ClipState) {
    ClipState[ClipState["Unknown"] = 0] = "Unknown";
    ClipState[ClipState["Obsolete"] = 10] = "Obsolete";
    ClipState[ClipState["Started"] = 100] = "Started";
    ClipState[ClipState["Committed"] = 200] = "Committed";
    ClipState[ClipState["Validated"] = 300] = "Validated";
    ClipState[ClipState["Ready"] = 400] = "Ready";
})(ClipState = exports.ClipState || (exports.ClipState = {}));
