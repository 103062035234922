"use strict";
//NOTE: content in the following section of code is automatically generated.  Do not remove comments 
//      defining the start and end of the section.  Do not add any custom code inside the section of
//      code because it will be removed during the code generation process.
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
/***** [GENERATED FILES::START] *******************************************************************/
__exportStar(require("./AuthProviderType.enum"), exports);
__exportStar(require("./AutoFlagStateType.enum"), exports);
__exportStar(require("./CalendarInteractionType.enum"), exports);
__exportStar(require("./ClipEventType.enum"), exports);
__exportStar(require("./ClipHostingType.enum"), exports);
__exportStar(require("./ClipOperationStateType.enum"), exports);
__exportStar(require("./ClipOperationType.enum"), exports);
__exportStar(require("./ClipSource.enum"), exports);
__exportStar(require("./ClipState.enum"), exports);
__exportStar(require("./ClipType.enum"), exports);
__exportStar(require("./CodeGenScenarioType.enum"), exports);
__exportStar(require("./CollectionSyncMode.enum"), exports);
__exportStar(require("./EventType.enum"), exports);
__exportStar(require("./FileType.enum"), exports);
__exportStar(require("./HttpStatusCode.enum"), exports);
__exportStar(require("./JobStatusType.enum"), exports);
__exportStar(require("./MediaProcessingState.enum"), exports);
__exportStar(require("./MemberRole.enum"), exports);
__exportStar(require("./MemberSyncOpType.enum"), exports);
__exportStar(require("./NotificationChannel.enum"), exports);
__exportStar(require("./NotificationStatus.enum"), exports);
__exportStar(require("./OrgSyncState.enum"), exports);
__exportStar(require("./ParticipantReactionType.enum"), exports);
__exportStar(require("./ParticipantRole.enum"), exports);
__exportStar(require("./ParticipantState.enum"), exports);
__exportStar(require("./PersonRole.enum"), exports);
__exportStar(require("./ProviderType.enum"), exports);
__exportStar(require("./Recurrence.enum"), exports);
__exportStar(require("./SecurityOpType.enum"), exports);
__exportStar(require("./SessionCommentPolicy.enum"), exports);
__exportStar(require("./SessionLifecycleStage.enum"), exports);
__exportStar(require("./SessionNotificationType.enum"), exports);
__exportStar(require("./SessionSecurityType.enum"), exports);
__exportStar(require("./SessionSummaryType.enum"), exports);
__exportStar(require("./SessionType.enum"), exports);
__exportStar(require("./SyncOpType.enum"), exports);
__exportStar(require("./SyncScope.enum"), exports);
__exportStar(require("./TokenSecurityLevel.enum"), exports);
__exportStar(require("./TokenSecurityType.enum"), exports);
__exportStar(require("./TranscriptState.enum"), exports);
__exportStar(require("./UserRole.enum"), exports);
/***** [GENERATED FILES::END] *********************************************************************/
