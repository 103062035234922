"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.VideoRecorderButtons = void 0;
const mobx_react_lite_1 = require("mobx-react-lite");
const react_1 = __importStar(require("react"));
const free_solid_svg_icons_1 = require("@fortawesome/free-solid-svg-icons");
const RecorderState_1 = require("./RecorderState");
const VideoEditorContext_1 = require("./VideoEditorContext");
const SbButton_1 = require("../SbButton");
const api_1 = require("@soundbite/api");
const controls_1 = require("../controls");
/**
 * VideoEditor is the top-level editor component for users to create and manipulate videos.
 * @param props
 */
exports.VideoRecorderButtons = (0, mobx_react_lite_1.observer)((props) => {
    //////////[ State ]///////////////////////////////////////////////////////////////////////////////
    const context = props.Context
        ? props.Context
        : new VideoEditorContext_1.VideoEditorContext();
    //////////[ Initialize ]//////////////////////////////////////////////////////////////////////////
    (0, react_1.useEffect)(() => { }, [props.Context]);
    //////////[ Utility Methods ]/////////////////////////////////////////////////////////////////////
    //function isRecordDisabled(): boolean {
    //  return !noContext || !context.isWebCamOn;
    //}
    //////////[ Event Handlers ]//////////////////////////////////////////////////////////////////////
    //function onToggleWebCam() {
    //  if (context.isWebCamOn) {
    //    context.stopWebCam();
    //  } else {
    //    context.startWebCam();
    //  }
    //}
    function onToggleRecording() {
        switch (context.recorderState) {
            case RecorderState_1.RecorderState.NotRecording:
                context.startRecording();
                break;
            case RecorderState_1.RecorderState.Recording:
                context.stopRecording();
                break;
            case RecorderState_1.RecorderState.Paused:
                context.resumeRecording();
                break;
        }
    }
    function onStopRecording() {
        if (context.isRecording || context.isPaused) {
            context.stopRecording();
        }
        else {
            api_1.Logger.LogWarning("Cannot stop recording because component is not currently recording.");
        }
    }
    /**
     * Deletes the current video buffer and allows the user to re-record a video.
     */
    //function onResetVideo() {
    //  context.clearVideoBuffer();
    //}
    function onSwitchCameras() {
        context.switchWebCam();
    }
    //////////[ Build Component UI ]//////////////////////////////////////////////////////////////////
    /**
     * Renders the video editor.
     */
    function Render() {
        return (react_1.default.createElement(react_1.default.Fragment, null,
            react_1.default.createElement("div", { className: "" },
                react_1.default.createElement(controls_1.ShowWhen, { is: VideoEditorContext_1.VideoEditorContext.isMultiDevice && !context.isRecording },
                    react_1.default.createElement(SbButton_1.SbButton, { type: SbButton_1.SbButtonType.PrimaryOutline, size: SbButton_1.SbButtonSize.Small, icon: free_solid_svg_icons_1.faCameraRotate, title: "Switch Cameras", onClick: () => onSwitchCameras() })),
                react_1.default.createElement(SbButton_1.SbButton, { type: SbButton_1.SbButtonType.PrimaryOutline, size: SbButton_1.SbButtonSize.Large, icon: context.isRecording ? free_solid_svg_icons_1.faStop : free_solid_svg_icons_1.faVideo, title: context.isRecording ? "Stop Recording" : "Record", onClick: () => onToggleRecording(), disabled: !context }))));
    }
    return Render();
});
