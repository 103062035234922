"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Loader = void 0;
const react_1 = __importStar(require("react"));
const reactstrap_1 = require("reactstrap");
const ShowWhen_1 = require("./ShowWhen");
/**
 * Wraps an element with a Bootstrap spinner, which shows the contents when isLoadedWhen === true
 * @param props
 */
const Loader = (props) => {
    var _a, _b, _c;
    let show = true;
    if (props.isLoadedWhen)
        show = false;
    const isMessageInstant = !props.isLoadedWhen && props.message != null && props.messageDelay == null;
    const [isShowingMessage, setShowingMessage] = (0, react_1.useState)(isMessageInstant);
    const [timeoutHandle, setTimeoutHandle] = (0, react_1.useState)(undefined);
    const isMessageEnabled = !!props.message || !!props.messageDelay;
    const showMessage = () => {
        setShowingMessage(!props.isLoadedWhen);
        clearTimer();
    };
    const clearTimer = () => {
        // if we're loaded now, but still counting time, then stop
        if (timeoutHandle != null) {
            clearTimeout(timeoutHandle);
            setTimeoutHandle(undefined);
        }
    };
    const startTimer = () => {
        if (timeoutHandle == null) {
            const delayTimeoutMillis = (props.messageDelay !== undefined ? props.messageDelay : 2) * 1000;
            const handle = setTimeout(showMessage, delayTimeoutMillis);
            setTimeoutHandle(handle);
        }
    };
    const checkMessage = () => {
        // If we're loaded, then we don't need to delay
        if (props.isLoadedWhen || !isMessageEnabled)
            return;
        if (isMessageInstant) {
            showMessage();
        }
        else {
            // If we're not loaded yet and we're not counting time, then start
            if (props.messageDelay && timeoutHandle == null) {
                startTimer();
            }
        }
    };
    const clearMessage = () => {
        setShowingMessage(false);
        clearTimer();
    };
    (0, react_1.useEffect)(() => {
        if (props.isLoadedWhen) {
            clearMessage();
        }
        else {
            checkMessage();
        }
    }, [props.message, props.messageDelay, props.isLoadedWhen]);
    const defaultStyles = {
        minHeight: "4em",
    };
    const smallClassName = props.isSmall ? "spinner-grow-sm " : "";
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(ShowWhen_1.ShowWhen, { is: show },
            react_1.default.createElement("div", { className: "w-100 d-table", style: (_a = props.style) !== null && _a !== void 0 ? _a : defaultStyles },
                react_1.default.createElement("div", { className: "text-center d-table-cell align-middle sb-loader" },
                    react_1.default.createElement(ShowWhen_1.ShowWhen, { is: isShowingMessage },
                        react_1.default.createElement("div", { className: "text-muted mb-3" },
                            react_1.default.createElement("small", null, (_b = props.message) !== null && _b !== void 0 ? _b : "Tuning Up The Orchestra..."))),
                    react_1.default.createElement(reactstrap_1.Spinner, { type: "grow", className: smallClassName + ((_c = props.className) !== null && _c !== void 0 ? _c : "sb-spinner-default") })))),
        react_1.default.createElement(ShowWhen_1.ShowWhen, { is: !show, isDisplayBased: props.isDisplayBased }, props.children)));
};
exports.Loader = Loader;
