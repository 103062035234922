"use strict";
/***************************************************************************************************
 * Auto-Generated File - do not modify because all changes will be lost
 **************************************************************************************************/
Object.defineProperty(exports, "__esModule", { value: true });
exports.AuthProviderType = void 0;
/**
* Automatically generated enum for Masticore.AuthProviderType
*/
var AuthProviderType;
(function (AuthProviderType) {
    AuthProviderType[AuthProviderType["Unknown"] = 0] = "Unknown";
    AuthProviderType[AuthProviderType["Azure"] = 20] = "Azure";
    AuthProviderType[AuthProviderType["Okta"] = 40] = "Okta";
})(AuthProviderType = exports.AuthProviderType || (exports.AuthProviderType = {}));
