"use strict";
/***************************************************************************************************
 * Auto-Generated File - do not modify because all changes will be lost
 **************************************************************************************************/
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReportService = void 0;
const HttpService_1 = require("../../code/HttpService");
const SoundbiteApiConfig_1 = require("../../code/SoundbiteApiConfig");
/**
 * Automatically generated endpoint API for the Soundbite.Api.Controllers.ReportController
 **/
class ReportServiceClass {
    /**
    * Automatically generated API call
    */
    activityReportAsync(orgRoute, startUtc, endUtc, options) {
        return __awaiter(this, void 0, void 0, function* () {
            const url = SoundbiteApiConfig_1.SoundbiteApiConfig.ApiPrefixUrl + `/organizations/${encodeURIComponent(orgRoute)}/reports/activity?startUtc=${encodeURIComponent(startUtc !== null && startUtc !== void 0 ? startUtc : "")}&endUtc=${encodeURIComponent(endUtc !== null && endUtc !== void 0 ? endUtc : "")}`;
            const response = yield HttpService_1.HttpService.post(url, null, options);
            return response;
        });
    }
    /**
    * Automatically generated API call
    */
    contentReportAsync(orgRoute, startUtc, endUtc, options) {
        return __awaiter(this, void 0, void 0, function* () {
            const url = SoundbiteApiConfig_1.SoundbiteApiConfig.ApiPrefixUrl + `/organizations/${encodeURIComponent(orgRoute)}/reports/content?startUtc=${encodeURIComponent(startUtc !== null && startUtc !== void 0 ? startUtc : "")}&endUtc=${encodeURIComponent(endUtc !== null && endUtc !== void 0 ? endUtc : "")}`;
            const response = yield HttpService_1.HttpService.post(url, null, options);
            return response;
        });
    }
    /**
    * Automatically generated API call
    */
    sessionReportAsync(orgRoute, sessionRoute, options) {
        return __awaiter(this, void 0, void 0, function* () {
            const url = SoundbiteApiConfig_1.SoundbiteApiConfig.ApiPrefixUrl + `/organizations/${encodeURIComponent(orgRoute)}/sessions/${encodeURIComponent(sessionRoute)}/reports/content`;
            const response = yield HttpService_1.HttpService.post(url, null, options);
            return response;
        });
    }
    /**
    * Automatically generated API call
    */
    sessionDetailsReportAsync(orgRoute, sessionRoute, options) {
        return __awaiter(this, void 0, void 0, function* () {
            const url = SoundbiteApiConfig_1.SoundbiteApiConfig.ApiPrefixUrl + `/organizations/${encodeURIComponent(orgRoute)}/sessions/${encodeURIComponent(sessionRoute)}/reports/content/details`;
            const response = yield HttpService_1.HttpService.post(url, null, options);
            return response;
        });
    }
}
exports.ReportService = new ReportServiceClass();
