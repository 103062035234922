"use strict";
/***************************************************************************************************
 * Auto-Generated File - do not modify because all changes will be lost
 **************************************************************************************************/
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PeopleService = void 0;
const HttpService_1 = require("../../code/HttpService");
const SoundbiteApiConfig_1 = require("../../code/SoundbiteApiConfig");
/**
 * Automatically generated endpoint API for the Soundbite.Api.Controllers.PeopleController
 **/
class PeopleServiceClass {
    /**
    * Automatically generated API call
    */
    readMeAsync(orgRoute, options) {
        return __awaiter(this, void 0, void 0, function* () {
            const url = SoundbiteApiConfig_1.SoundbiteApiConfig.ApiPrefixUrl + `/Organizations/${encodeURIComponent(orgRoute)}/me`;
            const response = yield HttpService_1.HttpService.get(url, options);
            return response;
        });
    }
    /**
    * Automatically generated API call
    */
    inviteAsync(orgRoute, invites, options) {
        return __awaiter(this, void 0, void 0, function* () {
            const url = SoundbiteApiConfig_1.SoundbiteApiConfig.ApiPrefixUrl + `/Organizations/${encodeURIComponent(orgRoute)}/People/Invite`;
            const response = yield HttpService_1.HttpService.post(url, invites, options);
            return response;
        });
    }
    /**
    * Automatically generated API call
    */
    updateAsync(orgRoute, personRoute, personFields, options) {
        return __awaiter(this, void 0, void 0, function* () {
            const url = SoundbiteApiConfig_1.SoundbiteApiConfig.ApiPrefixUrl + `/Organizations/${encodeURIComponent(orgRoute)}/People/${encodeURIComponent(personRoute)}`;
            const response = yield HttpService_1.HttpService.patch(url, personFields, options);
            return response;
        });
    }
    /**
    * Automatically generated API call
    */
    readAllAsync(orgRoute, pageRequest, options) {
        var _a, _b, _c, _d, _e;
        return __awaiter(this, void 0, void 0, function* () {
            const url = SoundbiteApiConfig_1.SoundbiteApiConfig.ApiPrefixUrl + `/Organizations/${encodeURIComponent(orgRoute)}/People/Page?&filter=${encodeURIComponent((_a = pageRequest === null || pageRequest === void 0 ? void 0 : pageRequest.filter) !== null && _a !== void 0 ? _a : "")}&includesCounts=${encodeURIComponent((_b = pageRequest === null || pageRequest === void 0 ? void 0 : pageRequest.includesCounts) !== null && _b !== void 0 ? _b : "")}&personRole=${encodeURIComponent((_c = pageRequest === null || pageRequest === void 0 ? void 0 : pageRequest.personRole) !== null && _c !== void 0 ? _c : "")}&skip=${encodeURIComponent((_d = pageRequest === null || pageRequest === void 0 ? void 0 : pageRequest.skip) !== null && _d !== void 0 ? _d : "")}&take=${encodeURIComponent((_e = pageRequest === null || pageRequest === void 0 ? void 0 : pageRequest.take) !== null && _e !== void 0 ? _e : "")}`;
            const response = yield HttpService_1.HttpService.get(url, options);
            return response;
        });
    }
    /**
    * Automatically generated API call
    */
    delete(orgRoute, personRoute, options) {
        return __awaiter(this, void 0, void 0, function* () {
            const url = SoundbiteApiConfig_1.SoundbiteApiConfig.ApiPrefixUrl + `/Organizations/${encodeURIComponent(orgRoute)}/People/${encodeURIComponent(personRoute)}`;
            yield HttpService_1.HttpService.delete(url, options);
        });
    }
}
exports.PeopleService = new PeopleServiceClass();
