"use strict";
/***************************************************************************************************
 * Auto-Generated File - do not modify because all changes will be lost
 **************************************************************************************************/
Object.defineProperty(exports, "__esModule", { value: true });
exports.TokenSecurityType = void 0;
/**
* Automatically generated enum for Masticore.TokenSecurityType
*/
var TokenSecurityType;
(function (TokenSecurityType) {
    TokenSecurityType[TokenSecurityType["None"] = 0] = "None";
    TokenSecurityType[TokenSecurityType["SecretKey"] = 10] = "SecretKey";
    TokenSecurityType[TokenSecurityType["Certificate"] = 20] = "Certificate";
})(TokenSecurityType = exports.TokenSecurityType || (exports.TokenSecurityType = {}));
