"use strict";
/***************************************************************************************************
 * Auto-Generated File - do not modify because all changes will be lost
 **************************************************************************************************/
Object.defineProperty(exports, "__esModule", { value: true });
exports.OrgSyncState = void 0;
/**
* Automatically generated enum for Masticore.DirectorySync.OrgSyncState
*/
var OrgSyncState;
(function (OrgSyncState) {
    OrgSyncState[OrgSyncState["Unknown"] = 0] = "Unknown";
    OrgSyncState[OrgSyncState["Pending"] = 10] = "Pending";
    OrgSyncState[OrgSyncState["Processing"] = 20] = "Processing";
    OrgSyncState[OrgSyncState["Done"] = 30] = "Done";
    OrgSyncState[OrgSyncState["Cancelled"] = 40] = "Cancelled";
})(OrgSyncState = exports.OrgSyncState || (exports.OrgSyncState = {}));
