"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PastSessionsWidget = void 0;
const react_1 = __importStar(require("react"));
const mobx_react_lite_1 = require("mobx-react-lite");
const reactstrap_1 = require("reactstrap");
const react_fontawesome_1 = require("@fortawesome/react-fontawesome");
const free_solid_svg_icons_1 = require("@fortawesome/free-solid-svg-icons");
const api_1 = require("@soundbite/api");
const store_1 = require("../store");
const controls_1 = require("../components/controls");
const components_1 = require("../components");
const SessionTable_1 = require("../components/SessionTable");
/**
 * Starts the request for more SessionPreview objects
 * @param orgRoute
 * @param groupRoute
 * @param page
 */
function readPastSessions(orgRoute, groupRoute, page) {
    if (groupRoute == null) {
        return api_1.SessionsService.readPastAsync(orgRoute, page);
    }
    else {
        return api_1.SessionsService.readGroupPastAsync(orgRoute, groupRoute, page);
    }
}
/** Display an expandable list of sessions that are pending for the given org or group */
exports.PastSessionsWidget = (0, mobx_react_lite_1.observer)((props) => {
    var _a, _b;
    let { orgRoute, groupRoute } = props;
    // Component state
    const [count, setCount] = (0, react_1.useState)(undefined);
    const [isCreateEnabled, setCreateEnabled] = (0, react_1.useState)(!props.isReadOnly);
    const [title, setTitle] = (0, react_1.useState)("");
    const tableRef = react_1.default.useRef(null);
    // Calculated
    const showTitle = props.showTitle === false ? false : true;
    const icon = (_a = props.icon) !== null && _a !== void 0 ? _a : free_solid_svg_icons_1.faCalendarCheck;
    const currentPerson = (_b = store_1.WidgetStore.organizations.currentOrg) === null || _b === void 0 ? void 0 : _b.details.me;
    /**
     *  Async retrieve the title given the current context and optionally a current group
     * @param group
     */
    function getTitle(group) {
        return __awaiter(this, void 0, void 0, function* () {
            if (props.title) {
                return props.title;
            }
            if (group) {
                return `${group.name} History`;
            }
            if (props.orgRoute) {
                const org = yield store_1.WidgetStore.organizations.readOrgAsync(props.orgRoute);
                if (org) {
                    return `${org.details.name} History`;
                }
            }
            return "My History";
        });
    }
    react_1.default.useEffect(() => {
        const load = () => __awaiter(void 0, void 0, void 0, function* () {
            var _a;
            const grp = groupRoute != null && groupRoute.trim().length > 0
                ? yield store_1.WidgetStore.groups.readGroupAsync(orgRoute, groupRoute)
                : undefined;
            const isCreateEnabled = !props.isReadOnly && ((_a = props.showCreateButton) !== null && _a !== void 0 ? _a : true);
            setCreateEnabled(isCreateEnabled);
            const newTitle = yield getTitle(grp);
            setTitle(newTitle);
        });
        load();
        // eslint-disable-next-line
    }, [
        props.orgRoute,
        props.groupRoute,
        store_1.WidgetStore.sessions.groupFeed,
        store_1.WidgetStore.sessions.orgFeed,
        store_1.WidgetStore.organizations.currentOrg,
    ]);
    // Events
    const onRefresh = () => __awaiter(void 0, void 0, void 0, function* () {
        var _c;
        (_c = tableRef.current) === null || _c === void 0 ? void 0 : _c.refresh();
    });
    function onOpenNewSession() {
        if (props.orgRoute) {
            store_1.WidgetStore.showNewSession(props.orgRoute, props.groupRoute, undefined, () => {
                onRefresh();
            });
        }
    }
    const onFirstLoad = (allowCache, page) => __awaiter(void 0, void 0, void 0, function* () {
        var _d;
        page.includesCounts = true;
        const ret = yield readPastSessions(orgRoute, groupRoute, page);
        setCount((_d = ret.totalCount) !== null && _d !== void 0 ? _d : 0);
        return ret;
    });
    const onFurtherLoad = (allowCache, page) => __awaiter(void 0, void 0, void 0, function* () {
        const ret = yield readPastSessions(orgRoute, groupRoute, page);
        return ret;
    });
    const Body = () => {
        let emptyMsg = "No Past Sessions";
        if (isCreateEnabled) {
            emptyMsg +=
                ". Create a new session and it will appear here after publishing.";
        }
        if (count === 0) {
            return (react_1.default.createElement(components_1.EmptyCardBody, { hasBorder: true, prompt: emptyMsg, showImage: true },
                react_1.default.createElement(controls_1.ShowWhen, { is: isCreateEnabled },
                    react_1.default.createElement("button", { className: "btn btn-icon btn-primary", type: "button", onClick: onOpenNewSession, title: "Create Soundbite Session" },
                        react_1.default.createElement("span", { className: "btn-inner--icon" },
                            react_1.default.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: free_solid_svg_icons_1.faPlus })),
                        react_1.default.createElement("span", { className: "btn-inner--text" },
                            react_1.default.createElement(react_1.Fragment, null, "\u00A0"),
                            "Create Soundbite Session")))));
        }
        return (react_1.default.createElement(SessionTable_1.SessionTable, { currentPerson: currentPerson, itemSize: 54, onFirstLoad: onFirstLoad, onFurtherLoad: onFurtherLoad, orgRoute: orgRoute, groupRoute: groupRoute, ref: tableRef }));
    };
    function Header() {
        return (react_1.default.createElement(controls_1.ShowWhen, { is: showTitle },
            react_1.default.createElement(reactstrap_1.CardHeader, { className: "bg-transparent" },
                react_1.default.createElement(reactstrap_1.Row, { className: "align-items-center" },
                    react_1.default.createElement(reactstrap_1.Col, { xs: "8", md: "6" },
                        react_1.default.createElement("h1", { className: "mb-0" },
                            react_1.default.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: icon, className: "d-none d-sm-inline mr-1" }),
                            title)),
                    react_1.default.createElement(reactstrap_1.Col, { className: "text-right", xs: "4", md: "6" },
                        react_1.default.createElement(reactstrap_1.Button, { color: "secondary", outline: true, onClick: onRefresh, size: "sm", title: "Refresh Session History" },
                            react_1.default.createElement("span", { className: "btn-inner--icon" },
                                react_1.default.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: free_solid_svg_icons_1.faSyncAlt }))),
                        react_1.default.createElement(controls_1.ShowWhen, { is: !props.isReadOnly && isCreateEnabled },
                            react_1.default.createElement("button", { className: "btn btn-icon btn-primary btn-sm", type: "button", onClick: onOpenNewSession, title: "Create Soundbite Session" },
                                react_1.default.createElement("span", { className: "btn-inner--icon" },
                                    react_1.default.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: free_solid_svg_icons_1.faPlus })),
                                react_1.default.createElement("span", { className: "btn-inner--text" },
                                    react_1.default.createElement("span", { className: "d-none d-sm-inline" },
                                        react_1.default.createElement(react_1.Fragment, null, "\u00A0"),
                                        "Create"),
                                    react_1.default.createElement("span", { className: "d-none d-lg-inline" },
                                        react_1.default.createElement(react_1.Fragment, null, "\u00A0"),
                                        "Soundbite"),
                                    react_1.default.createElement("span", { className: "d-none d-md-inline" },
                                        react_1.default.createElement(react_1.Fragment, null, "\u00A0"),
                                        "Session")))))))));
    }
    return (react_1.default.createElement(reactstrap_1.Card, { className: "sb-feed-card" },
        react_1.default.createElement(Header, null),
        react_1.default.createElement(Body, null)));
});
