"use strict";
/***************************************************************************************************
 * Auto-Generated File - do not modify because all changes will be lost
 **************************************************************************************************/
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.OrganizationsService = void 0;
const HttpService_1 = require("../../code/HttpService");
const SoundbiteApiConfig_1 = require("../../code/SoundbiteApiConfig");
/**
 * Automatically generated endpoint API for the Soundbite.App.Controllers.OrganizationsController
 **/
class OrganizationsServiceClass {
    /**
    * Gets a list of all the active organizations to which the current user has access.
    */
    readAllAsync(options) {
        return __awaiter(this, void 0, void 0, function* () {
            const url = SoundbiteApiConfig_1.SoundbiteApiConfig.ApiPrefixUrl + `/organizations`;
            const response = yield HttpService_1.HttpService.get(url, options);
            return response;
        });
    }
    /**
    * Automatically generated API call
    */
    createAsync(organization, options) {
        return __awaiter(this, void 0, void 0, function* () {
            const url = SoundbiteApiConfig_1.SoundbiteApiConfig.ApiPrefixUrl + `/organizations/`;
            const response = yield HttpService_1.HttpService.post(url, organization, options);
            return response;
        });
    }
    /**
    * Gets an array containing all of the active organizations in the system.
    */
    readAllAsGodAsync(options) {
        return __awaiter(this, void 0, void 0, function* () {
            const url = SoundbiteApiConfig_1.SoundbiteApiConfig.ApiPrefixUrl + `/organizations/all`;
            const response = yield HttpService_1.HttpService.get(url, options);
            return response;
        });
    }
    /**
    * Gets a list of all the archived organizations to which the current user has access.
    */
    readAllArchivedAsync(options) {
        return __awaiter(this, void 0, void 0, function* () {
            const url = SoundbiteApiConfig_1.SoundbiteApiConfig.ApiPrefixUrl + `/organizations/archived`;
            const response = yield HttpService_1.HttpService.get(url, options);
            return response;
        });
    }
    /**
    * Automatically generated API call
    */
    readAsync(orgRoute, options) {
        return __awaiter(this, void 0, void 0, function* () {
            const url = SoundbiteApiConfig_1.SoundbiteApiConfig.ApiPrefixUrl + `/organizations/${encodeURIComponent(orgRoute)}`;
            const response = yield HttpService_1.HttpService.get(url, options);
            return response;
        });
    }
    /**
    * Automatically generated API call
    */
    deleteAsync(orgRoute, options) {
        return __awaiter(this, void 0, void 0, function* () {
            const url = SoundbiteApiConfig_1.SoundbiteApiConfig.ApiPrefixUrl + `/organizations/${encodeURIComponent(orgRoute)}`;
            yield HttpService_1.HttpService.delete(url, options);
        });
    }
    /**
    * Automatically generated API call
    */
    readNotificationSettingsAsync(orgRoute, options) {
        return __awaiter(this, void 0, void 0, function* () {
            const url = SoundbiteApiConfig_1.SoundbiteApiConfig.ApiPrefixUrl + `/organizations/${encodeURIComponent(orgRoute)}/settings/notifications`;
            const response = yield HttpService_1.HttpService.get(url, options);
            return response;
        });
    }
    /**
    * Automatically generated API call
    */
    readSessionSettingsAsync(orgRoute, options) {
        return __awaiter(this, void 0, void 0, function* () {
            const url = SoundbiteApiConfig_1.SoundbiteApiConfig.ApiPrefixUrl + `/organizations/${encodeURIComponent(orgRoute)}/settings/sessions`;
            const response = yield HttpService_1.HttpService.get(url, options);
            return response;
        });
    }
    /**
    * Automatically generated API call
    */
    updateNotificationSettingsAsync(orgRoute, settings, options) {
        return __awaiter(this, void 0, void 0, function* () {
            const url = SoundbiteApiConfig_1.SoundbiteApiConfig.ApiPrefixUrl + `/organizations/${encodeURIComponent(orgRoute)}/settings/notifications`;
            const response = yield HttpService_1.HttpService.put(url, settings, options);
            return response;
        });
    }
    /**
    * Automatically generated API call
    */
    updateSessionSettingsAsync(orgRoute, settings, options) {
        return __awaiter(this, void 0, void 0, function* () {
            const url = SoundbiteApiConfig_1.SoundbiteApiConfig.ApiPrefixUrl + `/organizations/${encodeURIComponent(orgRoute)}/settings/sessions`;
            const response = yield HttpService_1.HttpService.put(url, settings, options);
            return response;
        });
    }
    /**
    * Automatically generated API call
    */
    readAzureSettingsAsync(orgRoute, options) {
        return __awaiter(this, void 0, void 0, function* () {
            const url = SoundbiteApiConfig_1.SoundbiteApiConfig.ApiPrefixUrl + `/organizations/${encodeURIComponent(orgRoute)}/settings/azure`;
            const response = yield HttpService_1.HttpService.get(url, options);
            return response;
        });
    }
    /**
    * Automatically generated API call
    */
    updateAzureSettingsAsync(orgRoute, settings, options) {
        return __awaiter(this, void 0, void 0, function* () {
            const url = SoundbiteApiConfig_1.SoundbiteApiConfig.ApiPrefixUrl + `/organizations/${encodeURIComponent(orgRoute)}/settings/azure`;
            const response = yield HttpService_1.HttpService.put(url, settings, options);
            return response;
        });
    }
    /**
    * Automatically generated API call
    */
    readPermissionsAsync(orgRoute, options) {
        return __awaiter(this, void 0, void 0, function* () {
            const url = SoundbiteApiConfig_1.SoundbiteApiConfig.ApiPrefixUrl + `/organizations/${encodeURIComponent(orgRoute)}/settings/permissions`;
            const response = yield HttpService_1.HttpService.get(url, options);
            return response;
        });
    }
    /**
    * Automatically generated API call
    */
    updatePermissionsAsync(orgRoute, settings, options) {
        return __awaiter(this, void 0, void 0, function* () {
            const url = SoundbiteApiConfig_1.SoundbiteApiConfig.ApiPrefixUrl + `/organizations/${encodeURIComponent(orgRoute)}/settings/permissions`;
            const response = yield HttpService_1.HttpService.put(url, settings, options);
            return response;
        });
    }
    /**
    * Automatically generated API call
    */
    restoreAsync(orgRoute, options) {
        return __awaiter(this, void 0, void 0, function* () {
            const url = SoundbiteApiConfig_1.SoundbiteApiConfig.ApiPrefixUrl + `/organizations/${encodeURIComponent(orgRoute)}/restore`;
            const response = yield HttpService_1.HttpService.post(url, null, options);
            return response;
        });
    }
    /**
    * Automatically generated API call
    */
    patchAsync(orgRoute, changes, options) {
        return __awaiter(this, void 0, void 0, function* () {
            const url = SoundbiteApiConfig_1.SoundbiteApiConfig.ApiPrefixUrl + `/organizations/${encodeURIComponent(orgRoute)}`;
            const response = yield HttpService_1.HttpService.patch(url, changes, options);
            return response;
        });
    }
    /**
    * Automatically generated API call
    */
    uploadImageAsync(orgRoute, formData, options) {
        return __awaiter(this, void 0, void 0, function* () {
            const url = SoundbiteApiConfig_1.SoundbiteApiConfig.ApiPrefixUrl + `/organizations/${encodeURIComponent(orgRoute)}/image`;
            const response = yield HttpService_1.HttpService.post(url, formData, options);
            return response;
        });
    }
    /**
    * Automatically generated API call
    */
    updateThemeAsync(orgRoute, theme, options) {
        return __awaiter(this, void 0, void 0, function* () {
            const url = SoundbiteApiConfig_1.SoundbiteApiConfig.ApiPrefixUrl + `/organizations/${encodeURIComponent(orgRoute)}/theme`;
            const response = yield HttpService_1.HttpService.post(url, theme !== null && theme !== void 0 ? theme : null, options);
            return response;
        });
    }
    /**
    * Automatically generated API call
    */
    setOrgToken(orgRoute, newToken, options) {
        return __awaiter(this, void 0, void 0, function* () {
            const url = SoundbiteApiConfig_1.SoundbiteApiConfig.ApiPrefixUrl + `/organizations/${encodeURIComponent(orgRoute)}/setOrgToken`;
            yield HttpService_1.HttpService.post(url, newToken, options);
        });
    }
    /**
    * Automatically generated API call
    */
    syncOrgTokenWithAmsContentKeyPolicy(orgRoute, options) {
        return __awaiter(this, void 0, void 0, function* () {
            const url = SoundbiteApiConfig_1.SoundbiteApiConfig.ApiPrefixUrl + `/organizations/${encodeURIComponent(orgRoute)}/syncOrgTokenWithAmsContentKeyPolicy`;
            yield HttpService_1.HttpService.put(url, null, options);
        });
    }
    /**
    * Automatically generated API call
    */
    ensureAmsContentKeyPolicy(orgRoute, options) {
        return __awaiter(this, void 0, void 0, function* () {
            const url = SoundbiteApiConfig_1.SoundbiteApiConfig.ApiPrefixUrl + `/organizations/${encodeURIComponent(orgRoute)}/EnsureAmsContentKeyPolicy`;
            const response = yield HttpService_1.HttpService.post(url, null, options);
            return response;
        });
    }
    /**
    * Automatically generated API call
    */
    readAmsStreamingUrl(orgRoute, options) {
        return __awaiter(this, void 0, void 0, function* () {
            const url = SoundbiteApiConfig_1.SoundbiteApiConfig.ApiPrefixUrl + `/organizations/${encodeURIComponent(orgRoute)}/readAmsStreamingUrl`;
            const response = yield HttpService_1.HttpService.get(url, options);
            return response;
        });
    }
}
exports.OrganizationsService = new OrganizationsServiceClass();
