"use strict";
/***************************************************************************************************
 * Auto-Generated File - do not modify because all changes will be lost
 **************************************************************************************************/
Object.defineProperty(exports, "__esModule", { value: true });
exports.CalendarInteractionType = void 0;
/**
* Automatically generated enum for Masticore.CalendarInteractionType
*/
var CalendarInteractionType;
(function (CalendarInteractionType) {
    CalendarInteractionType[CalendarInteractionType["None"] = 0] = "None";
    CalendarInteractionType[CalendarInteractionType["Provider"] = 10] = "Provider";
    CalendarInteractionType[CalendarInteractionType["ICal"] = 20] = "ICal";
})(CalendarInteractionType = exports.CalendarInteractionType || (exports.CalendarInteractionType = {}));
