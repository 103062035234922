"use strict";
/***************************************************************************************************
 * Auto-Generated File - do not modify because all changes will be lost
 **************************************************************************************************/
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.GroupsService = void 0;
const HttpService_1 = require("../../code/HttpService");
const SoundbiteApiConfig_1 = require("../../code/SoundbiteApiConfig");
/**
 * Automatically generated endpoint API for the Soundbite.Api.Controllers.GroupsController
 **/
class GroupsServiceClass {
    /**
    * Automatically generated API call
    */
    readAllGroupsAsync(orgRoute, options) {
        return __awaiter(this, void 0, void 0, function* () {
            const url = SoundbiteApiConfig_1.SoundbiteApiConfig.ApiPrefixUrl + `/organizations/${encodeURIComponent(orgRoute)}/Groups`;
            const response = yield HttpService_1.HttpService.get(url, options);
            return response;
        });
    }
    /**
    * Automatically generated API call
    */
    readMyGroupsAsync(orgRoute, options) {
        return __awaiter(this, void 0, void 0, function* () {
            const url = SoundbiteApiConfig_1.SoundbiteApiConfig.ApiPrefixUrl + `/organizations/${encodeURIComponent(orgRoute)}/Me/Groups`;
            const response = yield HttpService_1.HttpService.get(url, options);
            return response;
        });
    }
    /**
    * Automatically generated API call
    */
    readMyTargetGroupsAsync(orgRoute, options) {
        return __awaiter(this, void 0, void 0, function* () {
            const url = SoundbiteApiConfig_1.SoundbiteApiConfig.ApiPrefixUrl + `/organizations/${encodeURIComponent(orgRoute)}/Me/Groups/Targets`;
            const response = yield HttpService_1.HttpService.get(url, options);
            return response;
        });
    }
    /**
    * Automatically generated API call
    */
    createAsync(orgRoute, newGroup, options) {
        return __awaiter(this, void 0, void 0, function* () {
            const url = SoundbiteApiConfig_1.SoundbiteApiConfig.ApiPrefixUrl + `/Organizations/${encodeURIComponent(orgRoute)}/Groups/`;
            const response = yield HttpService_1.HttpService.post(url, newGroup, options);
            return response;
        });
    }
    /**
    * Automatically generated API call
    */
    readAsync(orgRoute, groupRoute, options) {
        return __awaiter(this, void 0, void 0, function* () {
            const url = SoundbiteApiConfig_1.SoundbiteApiConfig.ApiPrefixUrl + `/Organizations/${encodeURIComponent(orgRoute)}/Groups/${encodeURIComponent(groupRoute)}`;
            const response = yield HttpService_1.HttpService.get(url, options);
            return response;
        });
    }
    /**
    * Automatically generated API call
    */
    deleteAsync(orgRoute, groupRoute, options) {
        return __awaiter(this, void 0, void 0, function* () {
            const url = SoundbiteApiConfig_1.SoundbiteApiConfig.ApiPrefixUrl + `/Organizations/${encodeURIComponent(orgRoute)}/Groups/${encodeURIComponent(groupRoute)}`;
            yield HttpService_1.HttpService.delete(url, options);
        });
    }
    /**
    * Automatically generated API call
    */
    patchAsync(orgRoute, groupRoute, changes, options) {
        return __awaiter(this, void 0, void 0, function* () {
            const url = SoundbiteApiConfig_1.SoundbiteApiConfig.ApiPrefixUrl + `/Organizations/${encodeURIComponent(orgRoute)}/Groups/${encodeURIComponent(groupRoute)}`;
            const response = yield HttpService_1.HttpService.patch(url, changes, options);
            return response;
        });
    }
}
exports.GroupsService = new GroupsServiceClass();
