"use strict";
/***************************************************************************************************
 * Auto-Generated File - do not modify because all changes will be lost
 **************************************************************************************************/
Object.defineProperty(exports, "__esModule", { value: true });
exports.AutoFlagStateType = void 0;
/**
* Automatically generated enum for Masticore.AutoFlagStateType
*/
var AutoFlagStateType;
(function (AutoFlagStateType) {
    AutoFlagStateType[AutoFlagStateType["UserDisabled"] = 0] = "UserDisabled";
    AutoFlagStateType[AutoFlagStateType["AutoEnabled"] = 1] = "AutoEnabled";
    AutoFlagStateType[AutoFlagStateType["UserEnabled"] = 2] = "UserEnabled";
    AutoFlagStateType[AutoFlagStateType["AutoDisabled"] = -1] = "AutoDisabled";
})(AutoFlagStateType = exports.AutoFlagStateType || (exports.AutoFlagStateType = {}));
