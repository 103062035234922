"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PlayDlg = void 0;
/** @jsx jsx */
const react_1 = require("@emotion/react");
const react_2 = require("react");
const reactstrap_1 = require("reactstrap");
const api_1 = require("@soundbite/api");
const HashHelper_1 = require("../modules/HashHelper");
const WidgetStore_1 = require("../store/WidgetStore");
const PlayerWidget_1 = require("../widgets/PlayerWidget");
const ErrorDlg_1 = require("./ErrorDlg");
const controls_1 = require("./controls");
class PlayDlg extends react_2.Component {
    constructor(props) {
        super(props);
        this.state = {
            session: undefined,
            isOpen: false,
            hasBeenClosed: false,
            isVideo: false,
        };
        this.open = this.open.bind(this);
        this.onClose = this.onClose.bind(this);
        this.onContext = this.onContext.bind(this);
    }
    componentDidMount() {
        if (this.props.openImmediately &&
            this.props.sessionRoute !== undefined &&
            !this.state.hasBeenClosed) {
            this.open();
        }
    }
    open() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                // Validate
                if (!this.props.sessionRoute) {
                    this.setState({ session: undefined, isOpen: false });
                    return;
                }
                api_1.Utils.setHash(`${HashHelper_1.DialogHash.Play}=${this.props.sessionRoute}`);
                const orgRoute = this.props.orgRoute;
                if (!orgRoute)
                    throw new Error("Must have initialized organization to load session");
                // Load
                this.setState({ session: undefined, isOpen: true });
                const session = yield WidgetStore_1.WidgetStore.sessions.readSessionDetailsAsync(orgRoute, this.props.sessionRoute);
                // Set returned value
                this.setState({ session });
            }
            catch (err) {
                this.setState({ isOpen: false });
                ErrorDlg_1.ErrorDlg.show(err, "Error Loading Soundbite", "Likely could not make contact with server");
            }
        });
    }
    onClose() {
        api_1.Utils.setHash();
        this.setState({ isOpen: false, hasBeenClosed: true });
        if (this.props.onClose)
            this.props.onClose(this.state.session);
    }
    getTitle() {
        if (this.state.session === undefined)
            return "Loading Soundbite Session...";
        return this.state.session.name;
    }
    getSubtitle() {
        if (this.state.session === undefined)
            return (0, react_1.jsx)("span", null, " ");
        return ((0, react_1.jsx)("span", { className: "sb-modal-header-subtitle" }, api_1.Utils.formatRelativeDate(this.state.session.publish)));
    }
    onContext(context) {
        this.setState({ isVideo: context.isVideo });
    }
    render() {
        let isOpen = this.state.isOpen;
        if (this.props.openImmediately && this.state.hasBeenClosed)
            isOpen = false;
        const className = this.state.isVideo ? "sb-cover-dialog" : "";
        return ((0, react_1.jsx)(reactstrap_1.Modal, { isOpen: isOpen, toggle: this.onClose, backdrop: "static", className: className },
            (0, react_1.jsx)(reactstrap_1.ModalHeader, { toggle: this.onClose, className: "bg-gradient-primary" },
                this.getTitle(),
                this.getSubtitle()),
            (0, react_1.jsx)(reactstrap_1.ModalBody, null,
                (0, react_1.jsx)(controls_1.Loader, { isLoadedWhen: !!this.props.sessionRoute && !!this.state.session },
                    (0, react_1.jsx)(PlayerWidget_1.PlayerWidget, { orgRoute: this.props.orgRoute, sessionRoute: this.props.sessionRoute, session: this.state.session, currentUserRoute: this.props.currentUserRoute, onClose: this.onClose, showButtons: true, autoPlay: true, onContext: this.onContext })))));
    }
}
exports.PlayDlg = PlayDlg;
