"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SbProgress = void 0;
/** @jsx jsx */
const react_1 = require("@emotion/react");
const __1 = require("../..");
let styles;
const getStyles = () => {
    if (styles == null) {
        styles = (0, react_1.css) `
      width: 100%;
      height: auto;
      min-height: 8px;
      background-color: ${__1.GlobalTheme.current.colors.neutrals.n600};

      .progress-bar {
        height: auto;
        overflow: visible;
      }

      .sb-progress-bar-title {
        text-align: left;
        padding: 1rem;
      }
    `;
    }
    return styles;
};
/**
 * A progress bar with optional title
 * @param props
 */
const SbProgress = (props) => {
    const width = { width: `${props.progress}%` };
    return ((0, react_1.jsx)("div", { className: "progress sb-progress", css: getStyles() },
        (0, react_1.jsx)("div", { className: "progress-bar progress-bar-animated sb-progress-background sb-progress-background-go", role: "progressbar", style: width, "aria-valuenow": props.progress },
            (0, react_1.jsx)(__1.ShowWhen, { is: props.title != null },
                (0, react_1.jsx)("div", { className: "sb-progress-bar-title" }, props.title)))));
};
exports.SbProgress = SbProgress;
