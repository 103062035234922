"use strict";
/***************************************************************************************************
 * Auto-Generated File - do not modify because all changes will be lost
 **************************************************************************************************/
Object.defineProperty(exports, "__esModule", { value: true });
exports.MemberRole = void 0;
/**
* Automatically generated enum for Masticore.MemberRole
*/
var MemberRole;
(function (MemberRole) {
    MemberRole[MemberRole["Unknown"] = 0] = "Unknown";
    MemberRole[MemberRole["Member"] = 50] = "Member";
    MemberRole[MemberRole["Owner"] = 100] = "Owner";
})(MemberRole = exports.MemberRole || (exports.MemberRole = {}));
