"use strict";
/***************************************************************************************************
 * Auto-Generated File - do not modify because all changes will be lost
 **************************************************************************************************/
Object.defineProperty(exports, "__esModule", { value: true });
exports.ClipOperationStateType = void 0;
/**
* Automatically generated enum for Soundbite.ClipOperationStateType
*/
var ClipOperationStateType;
(function (ClipOperationStateType) {
    ClipOperationStateType[ClipOperationStateType["Unknown"] = 0] = "Unknown";
    ClipOperationStateType[ClipOperationStateType["NotRequested"] = 100] = "NotRequested";
    ClipOperationStateType[ClipOperationStateType["Requested"] = 200] = "Requested";
    ClipOperationStateType[ClipOperationStateType["Error"] = 600] = "Error";
    ClipOperationStateType[ClipOperationStateType["Complete"] = 1000] = "Complete";
})(ClipOperationStateType = exports.ClipOperationStateType || (exports.ClipOperationStateType = {}));
