"use strict";
/***************************************************************************************************
 * Auto-Generated File - do not modify because all changes will be lost
 **************************************************************************************************/
Object.defineProperty(exports, "__esModule", { value: true });
exports.ClipEventType = void 0;
/**
* Automatically generated enum for Soundbite.Models.ClipEventType
*/
var ClipEventType;
(function (ClipEventType) {
    ClipEventType[ClipEventType["Unknown"] = 0] = "Unknown";
    ClipEventType[ClipEventType["ServerUpload"] = 100] = "ServerUpload";
    ClipEventType[ClipEventType["ServerUnlockForWrite"] = 200] = "ServerUnlockForWrite";
    ClipEventType[ClipEventType["ServerUnlockForRead"] = 300] = "ServerUnlockForRead";
    ClipEventType[ClipEventType["ServerShareLinkSecure"] = 400] = "ServerShareLinkSecure";
    ClipEventType[ClipEventType["ServerShareLinkPublic"] = 500] = "ServerShareLinkPublic";
    ClipEventType[ClipEventType["ClientRecord"] = 500] = "ClientRecord";
    ClipEventType[ClipEventType["ClientLoad"] = 600] = "ClientLoad";
    ClipEventType[ClipEventType["ClientStart"] = 700] = "ClientStart";
    ClipEventType[ClipEventType["ClientPause"] = 800] = "ClientPause";
    ClipEventType[ClipEventType["ClientScrub"] = 900] = "ClientScrub";
    ClipEventType[ClipEventType["ClientFinish"] = 1000] = "ClientFinish";
})(ClipEventType = exports.ClipEventType || (exports.ClipEventType = {}));
