"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VideoClip = void 0;
/**
 * Video clips reprent a piece of video media.  Depending on how far we want to take the video
 * editor, the editor could be responsible for multiple clips to help arrange and piece together
 * various pieces of content.
 */
class VideoClip {
    constructor(id, name, url, isLocalRecording, durationInMs) {
        this.isLocalRecording = false;
        this.durationInMs = 0;
        this.id = id;
        this.name = name;
        this.url = url;
        this.isLocalRecording = isLocalRecording;
        this.durationInMs = durationInMs;
    }
}
exports.VideoClip = VideoClip;
