"use strict";
/***************************************************************************************************
 * Auto-Generated File - do not modify because all changes will be lost
 **************************************************************************************************/
Object.defineProperty(exports, "__esModule", { value: true });
exports.SessionCommentPolicy = void 0;
/**
* Automatically generated enum for Masticore.SessionCommentPolicy
*/
var SessionCommentPolicy;
(function (SessionCommentPolicy) {
    SessionCommentPolicy[SessionCommentPolicy["Allowed"] = 0] = "Allowed";
    SessionCommentPolicy[SessionCommentPolicy["Disallowed"] = 10] = "Disallowed";
})(SessionCommentPolicy = exports.SessionCommentPolicy || (exports.SessionCommentPolicy = {}));
