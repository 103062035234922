"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.GroupPicker = exports.GroupPickerLoadType = void 0;
const react_1 = __importStar(require("react"));
const store_1 = require("../../store");
const ItemPicker_1 = require("./ItemPicker");
const react_2 = require("react");
/***************************************************************************************************
 *  Enums
 **************************************************************************************************/
/**
 * Enumeration of the varoius automatic group loading options for the group picker.
 */
var GroupPickerLoadType;
(function (GroupPickerLoadType) {
    /** Do not automatically load groups */
    GroupPickerLoadType[GroupPickerLoadType["None"] = 0] = "None";
    /** Only groups to which the user is a member should  be loaded */
    GroupPickerLoadType[GroupPickerLoadType["MyGroups"] = 1] = "MyGroups";
    /** All groups should be loaded */
    GroupPickerLoadType[GroupPickerLoadType["AllGroups"] = 2] = "AllGroups";
})(GroupPickerLoadType = exports.GroupPickerLoadType || (exports.GroupPickerLoadType = {}));
/**
 * An interactive list of groups in the given org
 * @param props
 */
const GroupPicker = (props) => {
    //////////[ Define State ]////////////////////////////////////////////////////////////////////////
    var _a;
    let [isLoaded, setIsLoaded] = (0, react_1.useState)(false);
    let [groups, setGroups] = (0, react_1.useState)([]);
    //////////[ Variables ]///////////////////////////////////////////////////////////////////////////
    const loadMode = (_a = props.loadMode) !== null && _a !== void 0 ? _a : GroupPickerLoadType.MyGroups;
    const placeHolder = isLoaded == true ? props.placeHolder : "Loading...";
    const disabled = isLoaded == true ? props.disabled : true;
    const isMulti = props.isMulti === true;
    //////////[ Initialize ]//////////////////////////////////////////////////////////////////////////
    function initialize() {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            // Only auto initialize when no groups specified and load mode is not none
            if (!props.groups && loadMode !== GroupPickerLoadType.None) {
                setIsLoaded(false);
                if (store_1.WidgetStore.isInitialized) {
                    const groups = loadMode === GroupPickerLoadType.AllGroups
                        ? yield store_1.WidgetStore.organizations.readMyGroupsAsync(props.orgRoute, true)
                        : yield store_1.WidgetStore.organizations.readGroupsAsync(props.orgRoute, true);
                    setIsLoaded(true);
                    setGroups(groups);
                }
            }
            else {
                setGroups((_a = props.groups) !== null && _a !== void 0 ? _a : []);
                setIsLoaded(true);
            }
        });
    }
    (0, react_2.useEffect)(() => {
        initialize();
    }, [store_1.WidgetStore.isInitialized, props.groups]);
    //////////[ Render ]//////////////////////////////////////////////////////////////////////////////
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(ItemPicker_1.ItemPicker, { isMulti: isMulti, items: groups, onItemSelected: props.onItemSelected, onChange: props.onChange, placeHolder: placeHolder, disabled: disabled, getName: (i) => i.name, getValue: (i) => i })));
};
exports.GroupPicker = GroupPicker;
