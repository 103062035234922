"use strict";
/***************************************************************************************************
 * Auto-Generated File - do not modify because all changes will be lost
 **************************************************************************************************/
Object.defineProperty(exports, "__esModule", { value: true });
exports.NotificationStatus = void 0;
/**
* Automatically generated enum for Soundbite.NotificationStatus
*/
var NotificationStatus;
(function (NotificationStatus) {
    NotificationStatus[NotificationStatus["Unknown"] = 0] = "Unknown";
    NotificationStatus[NotificationStatus["Success"] = 10] = "Success";
    NotificationStatus[NotificationStatus["Failed"] = 20] = "Failed";
    NotificationStatus[NotificationStatus["Pending"] = 30] = "Pending";
    NotificationStatus[NotificationStatus["Processing"] = 40] = "Processing";
    NotificationStatus[NotificationStatus["Retry"] = 50] = "Retry";
})(NotificationStatus = exports.NotificationStatus || (exports.NotificationStatus = {}));
