"use strict";
/***************************************************************************************************
 * Auto-Generated File - do not modify because all changes will be lost
 **************************************************************************************************/
Object.defineProperty(exports, "__esModule", { value: true });
exports.MemberSyncOpType = void 0;
/**
* Automatically generated enum for Masticore.DirectorySync.MemberSyncOpType
*/
var MemberSyncOpType;
(function (MemberSyncOpType) {
    MemberSyncOpType[MemberSyncOpType["SyncNoMembers"] = 0] = "SyncNoMembers";
    MemberSyncOpType[MemberSyncOpType["SyncAllMembers"] = 1] = "SyncAllMembers";
    MemberSyncOpType[MemberSyncOpType["SyncExistingMembers"] = 2] = "SyncExistingMembers";
})(MemberSyncOpType = exports.MemberSyncOpType || (exports.MemberSyncOpType = {}));
