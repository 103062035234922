"use strict";
/***************************************************************************************************
 * Auto-Generated File - do not modify because all changes will be lost
 **************************************************************************************************/
Object.defineProperty(exports, "__esModule", { value: true });
exports.ClipSource = void 0;
/**
* Automatically generated enum for Soundbite.Models.ClipSource
*/
var ClipSource;
(function (ClipSource) {
    ClipSource[ClipSource["Unknown"] = 0] = "Unknown";
    ClipSource[ClipSource["Upload"] = 100] = "Upload";
    ClipSource[ClipSource["WidgetRecorderV1"] = 200] = "WidgetRecorderV1";
})(ClipSource = exports.ClipSource || (exports.ClipSource = {}));
