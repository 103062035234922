"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DialogManagerLink = void 0;
/**
 * Allows the DialogManager and the DialogManagerUi to communicate.  This could technically be on
 * the DialogManager directly but this hides the linkage so it does not appear in intellisense and
 * makes it harder to accidentally break.
 */
class DialogManagerLink {
}
exports.DialogManagerLink = DialogManagerLink;
