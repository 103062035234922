"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.GroupStore = void 0;
const mobx_1 = require("mobx");
const api_1 = require("@soundbite/api");
const OrganizationStore_1 = require("./OrganizationStore");
/**
 * MobX store class containing states and actions for groups.
 */
class GroupStoreClass {
    //////////[ Constructor ]/////////////////////////////////////////////////////////////////////////////
    constructor() {
        //////////[ Cache Members ]////////////////////////////////////////////////////////////////////////
        this.groupDetailsCache = {};
        //////////[ Observable Data Properties ]//////////////////////////////////////////////////////////
        this.lastOrgRoute = undefined;
        this.lastGroupRoute = undefined;
        /**
         * Stores a reference to the current group context under which the application is operating.
         */
        this.currentGroup = undefined;
        (0, mobx_1.makeObservable)(this, {
            refresh: mobx_1.observable,
            currentGroup: mobx_1.observable,
            deleteGroupAsync: mobx_1.action,
            updateGroupAsync: mobx_1.action,
        });
    }
    /**
     * Responsible for clearing ALL cached data in the store.
     */
    clearCache() {
        this.groupDetailsCache = Object.create(null);
    }
    /**
     * Creates a key used for caching operations
     * @param orgRoute - route of the organization
     * @param groupRoute - route of the group
     */
    getCacheKey(orgRoute, groupRoute) {
        return `${orgRoute}-${groupRoute}`;
    }
    //////////[ Actions ]/////////////////////////////////////////////////////////////////////////////
    reset() {
        this.clearCache();
        this.lastOrgRoute = undefined;
        this.lastGroupRoute = undefined;
        this.currentGroup = undefined;
    }
    /** Reloads the data in this context */
    refresh() {
        return __awaiter(this, void 0, void 0, function* () {
            this.clearCache();
            if (this.currentGroup == null ||
                this.lastOrgRoute == null ||
                this.lastGroupRoute == null) {
                this.currentGroup = undefined;
                this.lastOrgRoute = undefined;
                this.lastGroupRoute = undefined;
                return;
            }
            yield this.readGroupAsync(this.lastOrgRoute, this.lastGroupRoute, false);
        });
    }
    /**
     * Creates a new group.
     * @param orgRoute - route of the organization with which the group is associated.
     * @param newGroup - information about the group to create.
     */
    createGroupAsync(orgRoute, newGroup) {
        return __awaiter(this, void 0, void 0, function* () {
            const group = yield api_1.GroupsService.createAsync(orgRoute, newGroup);
            this.currentGroup = group;
            this.lastOrgRoute = orgRoute;
            this.lastGroupRoute = group.route;
            const refreshPromise = this.refresh();
            const orgChangePromise = OrganizationStore_1.OrganizationStore.onGroupChanged(group);
            yield Promise.all([refreshPromise, orgChangePromise]);
            return group;
        });
    }
    /**
     * Deletes the specified group.
     * @param orgRoute - route of the organization with which the group is associated.
     * @param groupRoute - route of the group to delete.
     */
    deleteGroupAsync(orgRoute, groupRoute) {
        return __awaiter(this, void 0, void 0, function* () {
            api_1.GroupsService.deleteAsync(orgRoute, groupRoute);
            delete this.groupDetailsCache[this.getCacheKey(orgRoute, groupRoute)];
            yield OrganizationStore_1.OrganizationStore.onGroupRemoved(orgRoute, groupRoute);
        });
    }
    /**
     * Gets the specified group.
     * @param orgRoute - route of the organization with which the group is associated.
     * @param groupRoute - route of the group to get
     * @param allowCache - (optional) flag indicating whether caching is allowed (default:true)
     */
    readGroupAsync(orgRoute, groupRoute, allowCache = true) {
        return __awaiter(this, void 0, void 0, function* () {
            const cacheKey = this.getCacheKey(orgRoute, groupRoute);
            let group = allowCache
                ? this.groupDetailsCache[cacheKey]
                : null;
            if (!group) {
                group = yield api_1.GroupsService.readAsync(orgRoute, groupRoute);
                if (group) {
                    this.groupDetailsCache[cacheKey] = group;
                }
            }
            if (group) {
                this.lastOrgRoute = orgRoute;
                this.lastGroupRoute = groupRoute;
                this.currentGroup = group;
            }
            return group;
        });
    }
    /**
     * Updates the specified group.
     * @param orgRoute - route of the organization with which the group is associated.
     * @param groupRoute - route of the group to get
     * @param patch - HTTP patch instructions identifying the group properties to update.
     */
    updateGroupAsync(orgRoute, groupRoute, patch) {
        return __awaiter(this, void 0, void 0, function* () {
            const group = yield api_1.GroupsService.patchAsync(orgRoute, groupRoute, patch);
            yield OrganizationStore_1.OrganizationStore.onGroupChanged(group);
            return group;
        });
    }
    //////////[ Event Methods ]///////////////////////////////////////////////////////////////////////
    onAnyMemberChanged(orgRoute, groupRoute) {
        return __awaiter(this, void 0, void 0, function* () {
            // If the updated member matches the current org/group, then we need to refresh
            // This ensures we update any showing lists of members, etc
            if (orgRoute !== this.lastOrgRoute || groupRoute !== this.lastGroupRoute) {
                return;
            }
            yield this.refresh();
        });
    }
}
// Export a singleton instance of the store
exports.GroupStore = new GroupStoreClass();
