"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MemberStore = void 0;
const api_1 = require("@soundbite/api");
const mobx_1 = require("mobx");
const GroupStore_1 = require("./GroupStore");
/**
 * MobX store class containing states and actions for members
 */
class MemberStoreClass {
    //////////[ Constructor ]/////////////////////////////////////////////////////////////////////////////
    constructor() {
        (0, mobx_1.makeObservable)(this, {});
    }
    //////////[ Observable Data Properties ]//////////////////////////////////////////////////////////
    /* None */
    //////////[ Actions ]/////////////////////////////////////////////////////////////////////////////
    /**
     * Async read the members of the given group in the given org
     * @param orgRoute
     * @param groupRoute
     */
    readMembersAsync(orgRoute, groupRoute) {
        return __awaiter(this, void 0, void 0, function* () {
            const members = yield api_1.MembersService.readAllAsync_Obsolete(orgRoute, groupRoute);
            return members;
        });
    }
    /**
     * Adds a new group member
     * @param orgRoute - route of the organization with which the group/member are associated.
     * @param groupRoute - route of the group with which to associated the user.
     * @param invites - identifies the user to invite to the group.
     */
    addMembersAsync(orgRoute, groupRoute, invites) {
        return __awaiter(this, void 0, void 0, function* () {
            yield api_1.MembersService.inviteAsync(orgRoute, groupRoute, invites);
            yield GroupStore_1.GroupStore.onAnyMemberChanged(orgRoute, groupRoute);
        });
    }
    /**
     * Stores updates to the specified member.
     * @param orgRoute - route of the organization with which the group/member are associated.
     * @param groupRoute - route of the group with which the user is associated.
     * @param member - member with updates to persist.
     */
    updateMemberAsync(orgRoute, groupRoute, member) {
        return __awaiter(this, void 0, void 0, function* () {
            const updatedMember = yield api_1.MembersService.updateAsync(orgRoute, groupRoute, member.route, member);
            yield GroupStore_1.GroupStore.onAnyMemberChanged(orgRoute, groupRoute);
            return updatedMember;
        });
    }
    /**
     * Deletes the specified member from a group.
     * @param orgRoute - route of the organization with which the group/member are associated.
     * @param groupRoute - route of the group from which the member should be deleted.
     * @param memberRoute - route of the member to delete.
     */
    deleteMemberAsync(orgRoute, groupRoute, memberRoute) {
        return __awaiter(this, void 0, void 0, function* () {
            yield api_1.MembersService.deleteAsync(orgRoute, groupRoute, memberRoute);
            yield GroupStore_1.GroupStore.onAnyMemberChanged(orgRoute, groupRoute);
        });
    }
}
// Export a singleton instance of the store
exports.MemberStore = new MemberStoreClass();
