"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.HlsMediaPlayerContext = void 0;
const api_1 = require("@soundbite/api");
const store_1 = require("../../../store");
const MediaPlayerContext_1 = require("./MediaPlayerContext");
// References the Hls library
let Hls = window.Hls;
/**
 * Azure Media Player (AMP) IMediaPlayerContext implementation
 */
class HlsMediaPlayerContext extends MediaPlayerContext_1.MediaPlayerContext {
    //////////[ Constructor ]/////////////////////////////////////////////////////////////////////////
    constructor() {
        super("hlsstreaming");
        this.isNative = false;
    }
    //////////[ Overrides ]///////////////////////////////////////////////////////////////////////////
    jumpTo(time) {
        if (this.mediaElement) {
            this.mediaElement.currentTime = time;
        }
    }
    load(clip, mediaUrl, autoPlay, isPublic) {
        return __awaiter(this, void 0, void 0, function* () {
            if (clip) {
                this.isPublic = isPublic !== null && isPublic !== void 0 ? isPublic : false;
                // Validate that we have an Hls reference
                Hls = Hls !== null && Hls !== void 0 ? Hls : window.Hls;
                if (!Hls) {
                    const msg = "Hls library is not loaded or is otherwise inexcessible.";
                    api_1.Logger.LogError(msg);
                    throw new Error(msg);
                }
                // Acquire the URL
                mediaUrl = api_1.Utils.isNullOrEmpty(mediaUrl)
                    ? yield this.buildMediaUrl(clip)
                    : mediaUrl;
                // Determine whether the browser has the native ability to play an apple formatted M3U8 file
                if (this.mediaElement.canPlayType("application/vnd.apple.mpegurl")) {
                    this.isNative = true;
                    this.initializeVideoElement(autoPlay !== null && autoPlay !== void 0 ? autoPlay : false);
                    this.mediaElement.src = mediaUrl;
                }
                else if (Hls.isSupported()) {
                    this.isNative = false;
                    this.initializeHlsPlayer(autoPlay !== null && autoPlay !== void 0 ? autoPlay : false);
                    this.hlsPlayer = new Hls();
                    this.hlsPlayer.loadSource(mediaUrl);
                    this.hlsPlayer.attachMedia(this.mediaElement);
                }
                else {
                    // No way to play video so log/throw an exception
                    const msg = "Hls and Native Video is not supported. Cannot play video.";
                    api_1.Logger.LogError(msg);
                    throw new Error(msg);
                }
            }
        });
    }
    play() {
        if (this.mediaElement) {
            this.mediaElement.play();
            this.isPlaying = true;
            if (this.onPlay) {
                this.onPlay();
            }
        }
    }
    pause() {
        if (this.mediaElement) {
            this.mediaElement.pause();
            this.isPlaying = false;
        }
    }
    //////////[ Methods ]/////////////////////////////////////////////////////////////////////////////
    /**
     * Builds out the URL to the M38u proxy API call
     */
    buildMediaUrl(clip) {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            // Acquire the Azure Media Service url prefix
            let prefix = (_a = store_1.WidgetStore.organizations.currentOrg) === null || _a === void 0 ? void 0 : _a.settings.azure.streamingEndpointUrlPrefix;
            // If we do not have a prefix it may simply mean that we are accessing the public player
            // anonymously or via a route that does not load organization settings.  When this occurs,
            // attempt to load the AMS media URL using the current organization route.
            if (api_1.Utils.isNullOrEmpty(prefix) &&
                store_1.WidgetStore.organizations.currentOrgRoute != null) {
                prefix = yield api_1.OrganizationsService.readAmsStreamingUrl(store_1.WidgetStore.organizations.currentOrgRoute);
                if (api_1.Utils.isNullOrEmpty(prefix)) {
                    const msg = "Organization does not have an AMS Streaming URL prefix configured or the setting could not be obtained.";
                    api_1.Logger.LogError(msg);
                    throw new Error(msg);
                }
            }
            if (api_1.Utils.isNullOrEmpty(prefix) &&
                api_1.Utils.isNullOrEmpty(store_1.WidgetStore.organizations.currentOrgRoute)) {
                const msg = "Canont build media URL because the current organization route is unknown.";
                api_1.Logger.LogError(msg);
                throw new Error(msg);
            }
            // Make sure we have a valid prefix
            if (api_1.Utils.isNullOrEmpty(prefix)) {
                const msg = "Cannot build media URL because organization does not have an Azure Media URL prefix defined.";
                api_1.Logger.LogError(msg);
                throw new Error(msg);
            }
            // Build the URL - we are currently always asking for an apple formatted M3U8 file
            const azureMediaUrl = `${prefix}${clip === null || clip === void 0 ? void 0 : clip.hostingData}/clip.ism/manifest(format=m3u8-aapl)`;
            // Determine if this is a public soundbite (i.e. not encrypted)
            if (this.isPublic) {
                // Send the direct azure media service URL
                return azureMediaUrl;
            }
            else {
                // Send the proxy URL
                return yield this.buildMediaProxyUrl(clip, prefix, azureMediaUrl);
            }
        });
    }
    buildMediaProxyUrl(clip, prefix, azureMediaUrl) {
        return __awaiter(this, void 0, void 0, function* () {
            const token = (yield api_1.SoundbiteApiConfig.getToken());
            // Ensure the token is present
            if (api_1.Utils.isNullOrEmpty(token)) {
                const msg = "Cannot build media URL without Soundbite token.";
                api_1.Logger.LogError(msg);
                throw new Error(msg);
            }
            // Ensure the org route is loaded
            if (!prefix && !store_1.WidgetStore.organizations.currentOrg) {
                prefix = yield api_1.OrganizationsService.readAmsStreamingUrl(store_1.WidgetStore.organizations.currentOrgRoute);
            }
            const azureMediaUrlEnc = encodeURI(azureMediaUrl)
                .replace("(", "%28")
                .replace(")", "%29");
            const mediaUrl = `${api_1.SoundbiteApiConfig.ApiPrefixUrl}/mediaproxy/manifest?sourceUrl=${azureMediaUrlEnc}&token=${encodeURIComponent(token)}`;
            return mediaUrl;
        });
    }
    initializeHlsPlayer(autoPlay) {
        this.initializeVideoElement(autoPlay);
    }
    initializeVideoElement(autoPlay) {
        if (this.mediaElement) {
            // Setup auto play (if requested)
            this.mediaElement.addEventListener("canplay", () => __awaiter(this, void 0, void 0, function* () {
                const autoPlayAllowed = yield this.autoPlayAllowed();
                if (autoPlay && autoPlayAllowed) {
                    this.play();
                }
            }));
            // Handle durationchange event
            this.mediaElement.addEventListener("durationchange", () => {
                this.handleDurationChange();
            });
            // Handle timeupdate event
            this.mediaElement.addEventListener("timeupdate", () => {
                this.handleTimeUpdate();
            });
            // Handle play event
            this.mediaElement.addEventListener("play", () => {
                this.handleOnPlay();
            });
            // Handle pause event
            this.mediaElement.addEventListener("pause", () => {
                this.handleOnPause();
            });
            // Handle ended event
            this.mediaElement.addEventListener("ended", () => {
                this.handleEnded();
            });
        }
        else {
            const msg = "Cannot initialize video element because the Video element is not referenced.";
            api_1.Logger.LogError(msg);
            throw new Error(msg);
        }
    }
    handleTimeUpdate() {
        this.time = this.mediaElement.currentTime;
    }
    handleDurationChange() {
        const newDuration = this.mediaElement.duration;
        if (!Number.isNaN(newDuration) && Number.isFinite(newDuration)) {
            this.duration = newDuration;
        }
    }
    handleOnPlay() {
        if (!this.isPlaying) {
            this.isPlaying = true;
        }
    }
    handleOnPause() {
        if (this.isPlaying) {
            this.isPlaying = false;
        }
    }
    handleEnded() {
        // Ended can fire when the end of the media is encountered OR the data stream stops. When
        // encoutering the end of the video, the Azure Media Player seems to rewind the video to
        // the zero mark.  I have NOT encountered a data stream termianting the player, so my
        // ASSUMPTION is that the player does not reset when encountering a data issue, but I may
        // very well be wrong.
        if (this.mediaElement.currentTime === 0) {
            if (this.onPlaybackComplete) {
                this.onPlaybackComplete();
            }
        }
    }
}
exports.HlsMediaPlayerContext = HlsMediaPlayerContext;
