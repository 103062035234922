"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Scrubber2 = void 0;
/** @jsx jsx */
const react_1 = require("@emotion/react");
const react_2 = require("react");
const react_scrubber_1 = require("react-scrubber");
const styles_1 = require("../styles");
const controls_1 = require("./controls");
const mobx_react_lite_1 = require("mobx-react-lite");
/***************************************************************************************************
 *  Component Styles
 **************************************************************************************************/
function getStyles() {
    const styles = (0, react_1.css) `
    width: 100%;
    height: 1.5rem;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    background-color: ${styles_1.GlobalTheme.current.colors.neutrals.n500};
    border-radius: 4px;
    overflow: hidden;
    position: relative;

    .sb-time-summary {
      color: ${styles_1.GlobalTheme.current.colors.neutrals.n200};
      right: 0.5rem;
      pointer-events: none;
      position: absolute;
      top: 1px;
      z-index: 1;
    }

    &[aria-disabled="true"] {
      pointer-events: none;
    }

    &[aria-disabled="true"] .sb-time-summary {
      color: ${styles_1.GlobalTheme.current.colors.neutrals.n300};
    }

    .scrubber.horizontal .bar {
      height: 100%;
    }

    .scrubber.horizontal .bar .bar__thumb {
      height: 1.5rem;
      width: 1.5rem;
      transform: translate(-50%, -50%);
      top: 50%;
    }

    .scrubber {
      width: 100%;
      height: 100%;
      position: relative;
      -webkit-user-select: none;
      user-select: none;
      touch-action: none;
    }

    .scrubber .bar {
      background-color: transparent;
      width: 100;
      position: relative;
      transition: height 0.2s linear, width 0.2s linear;
    }

    .scrubber * {
      -webkit-user-select: none;
      user-select: none;
    }

    .scrubber .bar__progress {
      position: absolute;
      background-color: ${styles_1.GlobalTheme.current.colors.neutrals.n600};
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      left: -0.75rem;
    }

    .scrubber .bar__buffer {
      position: absolute;
    }

    .scrubber .bar__thumb {
      position: absolute;
      width: 0px;
      height: 0px;
      border-radius: 4px;
      transition: height 0.2s linear, width 0.2s linear;
      background-color: ${styles_1.GlobalTheme.current.colors.bootstrap.primary};
    }

    &[aria-disabled="true"] .scrubber .bar__thumb {
      background-color: ${styles_1.GlobalTheme.current.colors.neutrals.n700};
    }

    .scrubber .bar__marker {
      position: absolute;
    }

    .scrubber.horizontal .bar__progress,
    .scrubber.horizontal .bar__marker,
    .scrubber.horizontal .bar__buffer {
      height: 100%;
    }

    .scrubber.horizontal .bar__progress::after {
      position: absolute;
      background-color: ${styles_1.GlobalTheme.current.colors.neutrals.n600};
      right: -4px;
      width: 5px;
      height: 100%;
      content: " ";
    }

    .scrubber.horizontal .bar__marker {
      width: 12px;
    }

    .scrubber.horizontal .bar {
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      width: 100%;
    }

    .scrubber.vertical .bar__progress,
    .scrubber.vertical .bar__marker,
    .scrubber.vertical .bar__buffer {
      width: 100%;
      bottom: 0;
    }

    .scrubber.vertical .bar {
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 100%;
      height: 100%;
    }

    .scrubber.vertical .bar__thumb {
      transform: translate(-50%, 50%);
      left: 50%;
    }

    .scrubber.hover.vertical .bar {
      width: 6px;
    }

    .scrubber.vertical .bar__marker {
      height: 100%;
    }
  `;
    return styles;
}
/***************************************************************************************************
 *  Component
 **************************************************************************************************/
exports.Scrubber2 = (0, mobx_react_lite_1.observer)((props) => {
    //////////[ State ]///////////////////////////////////////////////////////////////////////////////
    const [context, setContext] = (0, react_2.useState)(props.context);
    (0, react_2.useEffect)(() => {
        if (context != props.context) {
            setContext(props.context);
        }
    }, [props.context]);
    //////////[ Build Component UI ]//////////////////////////////////////////////////////////////////
    const styles = getStyles();
    const scrubberClassName = "sb-sound-scrubber" +
        (props.disabled === true ? " sb-sound-scrubber-disabled" : "");
    return ((0, react_1.jsx)(controls_1.Loader, { isLoadedWhen: (context === null || context === void 0 ? void 0 : context.duration) > 0 || (context === null || context === void 0 ? void 0 : context.hasError), isSmall: true },
        (0, react_1.jsx)("div", { css: styles, className: scrubberClassName, "aria-disabled": props.disabled === true },
            (0, react_1.jsx)("span", { className: "sb-time-summary" }, context.timeSummary),
            (0, react_1.jsx)(react_scrubber_1.Scrubber, { min: 0, max: context.duration, value: context.time, onScrubStart: (time) => context.jumpTo(time), onScrubEnd: (time) => context.jumpTo(time), onScrubChange: (time) => context.jumpTo(time) }))));
});
