"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SessionComments = exports.CommentMode = void 0;
/** @jsx jsx */
const react_1 = require("@emotion/react");
const react_2 = __importStar(require("react"));
const mobx_react_lite_1 = require("mobx-react-lite");
const reactstrap_1 = require("reactstrap");
const fontawesome_svg_core_1 = require("@fortawesome/fontawesome-svg-core");
const free_solid_svg_icons_1 = require("@fortawesome/free-solid-svg-icons");
const ShowWhen_1 = require("./ShowWhen");
const Loader_1 = require("./Loader");
const api_1 = require("@soundbite/api");
const ErrorDlg_1 = require("../ErrorDlg");
const Avatar_1 = require("./Avatar");
const styles_1 = require("../../styles");
const SessionCommentCreate_1 = require("./SessionCommentCreate");
const store_1 = require("../../store");
const SessionCommentEdit_1 = require("./SessionCommentEdit");
const SessionCommentDelete_1 = require("./SessionCommentDelete");
/***************************************************************************************************
 *  Constants / Global Variables
 **************************************************************************************************/
fontawesome_svg_core_1.library.add(free_solid_svg_icons_1.faPlus, free_solid_svg_icons_1.faTrash, free_solid_svg_icons_1.faComment);
const getStyles = () => {
    const ret = (0, react_1.css) `
    .sb-comment-list {
      max-height: 300px;
      overflow-y: auto;
      width: 100%;

      table {
      width: 100%;
        
      tr {
        width: 100%;

        form-group {
          margin-bottom: 0;
        }
      }

      .sb-row-delete {
        border-left: 2px solid ${styles_1.GlobalTheme.current.colors.bootstrap.danger};
      }
    }

    .sb-clear-comment-btn {
      border: 0 !important;
    }
    .sb-addon-btn {
      transform: translateY(0px) !important;
      box-shadow: none !important;

      &:active {
        outline: 1px;
      }

      svg {
        height: 1.25rem;
        padding: 3px 3px 0 3px;
      }
    }

    .sb-comments-table .table th,
    .sb-comments-table .table td {
      border: 0;
    }

    .sb-infinite-table {
      min-height: 150px;
      height: auto;
      max-height: 300px;
    }

    .alert .text-muted {
      color: ${styles_1.GlobalTheme.current.colors.neutrals.min} !important;
    }
  `;
    return ret;
};
let styles = undefined;
var CommentMode;
(function (CommentMode) {
    CommentMode["Create"] = "create";
    CommentMode["Edit"] = "edit";
    CommentMode["Delete"] = "delete";
})(CommentMode = exports.CommentMode || (exports.CommentMode = {}));
/** React component for showing a session's transcript */
exports.SessionComments = (0, mobx_react_lite_1.observer)((props) => {
    //////////[ Properties ]//////////////////////////////////////////////////////////////////////////
    var _a, _b;
    // State
    const [isLoading, setIsLoading] = (0, react_2.useState)(true);
    const [comments, setComments] = (0, react_2.useState)([]);
    const [targetComment, setTargetComment] = (0, react_2.useState)();
    const [mode, setMode] = (0, react_2.useState)(CommentMode.Create);
    const [count, setCount] = (0, react_2.useState)(undefined);
    // Refs
    const scrollableList = (0, react_2.useRef)(null);
    const actionElem = (0, react_2.useRef)(null);
    // Calculated
    const hasComments = count != null && count > 0;
    const currentPersonRoute = (_b = (_a = store_1.WidgetStore.organizations.currentOrg) === null || _a === void 0 ? void 0 : _a.details.me) === null || _b === void 0 ? void 0 : _b.route;
    //////////[ Component UI Helper Methods ]/////////////////////////////////////////////////////////
    const restoreScroll = () => {
        if (scrollableList.current) {
            const top = scrollableList.current.scrollTop;
            setTimeout(() => {
                if (scrollableList.current) {
                    scrollableList.current.scrollTop = top;
                }
            });
        }
    };
    const ensureActionsInView = () => {
        if (actionElem.current) {
            actionElem.current.scrollIntoView({ behavior: "smooth" });
        }
    };
    const setCreateMode = () => {
        setTargetComment(undefined);
        setMode(CommentMode.Create);
    };
    const setEditMode = (comment) => {
        setMode(CommentMode.Edit);
        setTargetComment(comment);
        ensureActionsInView();
        restoreScroll();
    };
    const setDeleteMode = (comment) => {
        setMode(CommentMode.Delete);
        setTargetComment(comment);
        ensureActionsInView();
        restoreScroll();
    };
    const loadComments = (page) => {
        setComments(page.result);
        scrollToBottom();
    };
    const load = () => __awaiter(void 0, void 0, void 0, function* () {
        if (props.orgRoute == null || props.sessionRoute == null) {
            return;
        }
        try {
            setIsLoading(true);
            const firstCommentsPage = yield api_1.SessionCommentsService.readAllSessionComments(props.orgRoute, props.sessionRoute, { includesCounts: true });
            loadComments(firstCommentsPage);
            setCount(firstCommentsPage.totalCount);
        }
        catch (err) {
            ErrorDlg_1.ErrorDlg.show(err, "Error Loading Session Comments");
        }
        setIsLoading(false);
    });
    function scrollToBottom() {
        setTimeout(() => {
            if (scrollableList.current) {
                const scrollHeight = scrollableList.current.scrollHeight;
                scrollableList.current.scrollTop = scrollHeight;
            }
        });
    }
    //////////[ Event Handlers ]//////////////////////////////////////////////////////////////////////
    (0, react_2.useEffect)(() => {
        const firstLoad = () => {
            load();
        };
        firstLoad();
    }, []);
    (0, react_2.useEffect)(() => { }, [targetComment]);
    // Whenever comments is set, scroll to the bottom of the list
    (0, react_2.useEffect)(() => {
        scrollToBottom();
    }, [comments]);
    //////////[ Build Component UI ]//////////////////////////////////////////////////////////////////
    function onEdit(comment) {
        setEditMode(comment);
    }
    function onDelete(comment) {
        setDeleteMode(comment);
    }
    function onCommit() {
        return __awaiter(this, void 0, void 0, function* () {
            yield load();
            setCreateMode();
        });
    }
    function onCancel() {
        setCreateMode();
    }
    function commentRowContent(comment, person, isActionable = true) {
        return ((0, react_1.jsx)(react_2.default.Fragment, null,
            (0, react_1.jsx)("span", { className: "font-weight-bold pr-1" }, api_1.Utils.userDisplay(person.user)),
            comment.content,
            (0, react_1.jsx)("div", { className: "sb-comment-subheader text-muted text-sm", title: api_1.Utils.formatDateTime(comment.createdUtc) },
                api_1.Utils.formatRelativeDate(comment.createdUtc),
                (0, react_1.jsx)(ShowWhen_1.ShowWhen, { is: currentPersonRoute === comment.person.route && isActionable },
                    (0, react_1.jsx)("button", { onClick: () => onEdit(comment), className: "sb-link-btn text-muted", title: "Edit Comment" }, "Edit"),
                    (0, react_1.jsx)("button", { onClick: () => onDelete(comment), className: "sb-link-btn text-muted", title: "Delete Comment" }, "Delete")))));
    }
    function wrappedCommentRowContent(comment, person) {
        const isCurrent = comment.route === (targetComment === null || targetComment === void 0 ? void 0 : targetComment.route);
        const isEdit = mode === CommentMode.Edit;
        const isDelete = mode === CommentMode.Delete;
        if (isCurrent && (isEdit || isDelete)) {
            return ((0, react_1.jsx)(reactstrap_1.Alert, { color: isEdit ? "warning" : "danger" }, commentRowContent(comment, person, false)));
        }
        else {
            return commentRowContent(comment, person);
        }
    }
    function commentsRow(comment, index) {
        const person = comment === null || comment === void 0 ? void 0 : comment.person;
        return ((0, react_1.jsx)("tr", { key: comment.route },
            (0, react_1.jsx)("th", { scope: "row" },
                (0, react_1.jsx)(Avatar_1.Avatar, { user: person.user, showName: false, sizingClassName: "" })),
            (0, react_1.jsx)("td", { className: "" }, wrappedCommentRowContent(comment, person))));
    }
    const Comments = () => {
        return ((0, react_1.jsx)("table", null,
            (0, react_1.jsx)("tbody", null, comments.map((comment, index) => {
                return commentsRow(comment, index);
            }))));
    };
    const List = () => {
        return ((0, react_1.jsx)("div", { className: "sb-comment-list pb-2", ref: scrollableList },
            (0, react_1.jsx)(ShowWhen_1.ShowWhen, { is: !hasComments },
                (0, react_1.jsx)("span", { className: "text-muted" }, "Be The First To Comment")),
            (0, react_1.jsx)(ShowWhen_1.ShowWhen, { is: hasComments },
                (0, react_1.jsx)(Comments, null))));
    };
    // Setting up render state
    if (styles == null) {
        styles = getStyles();
    }
    const commentClass = "rounded border-1";
    const combinedClass = props.className != null
        ? `${props.className} ${commentClass}`
        : commentClass;
    return ((0, react_1.jsx)("div", { className: combinedClass, css: styles },
        (0, react_1.jsx)("h4", null, "Comments"),
        (0, react_1.jsx)(Loader_1.Loader, { isLoadedWhen: !isLoading, isDisplayBased: true },
            (0, react_1.jsx)(List, null),
            (0, react_1.jsx)("div", { ref: actionElem },
                (0, react_1.jsx)(ShowWhen_1.ShowWhen, { is: mode === CommentMode.Create },
                    (0, react_1.jsx)(SessionCommentCreate_1.SessionCommentCreate, { orgRoute: props.orgRoute, sessionRoute: props.sessionRoute, onCommit: onCommit, maxCharacters: 150 })),
                (0, react_1.jsx)(ShowWhen_1.ShowWhen, { is: mode === CommentMode.Edit },
                    (0, react_1.jsx)(SessionCommentEdit_1.SessionCommentEdit, { orgRoute: props.orgRoute, sessionRoute: props.sessionRoute, comment: targetComment, onCommit: onCommit, onCancel: onCancel, maxCharacters: 150 })),
                (0, react_1.jsx)(ShowWhen_1.ShowWhen, { is: mode === CommentMode.Delete },
                    (0, react_1.jsx)(SessionCommentDelete_1.SessionCommentDelete, { orgRoute: props.orgRoute, sessionRoute: props.sessionRoute, comment: targetComment, onCommit: onCommit, onCancel: onCancel }))))));
});
