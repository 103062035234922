"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AuthState = exports.DialogState = exports.ClipSource = void 0;
/** The origin of the clip */
var ClipSource;
(function (ClipSource) {
    /** Recorded live audio on the device */
    ClipSource[ClipSource["RecordAudio"] = 0] = "RecordAudio";
    /** Recorded live video on device */
    ClipSource[ClipSource["RecordVideo"] = 1] = "RecordVideo";
    /** Uploaded audio - potentially a fully produced recording */
    ClipSource[ClipSource["Upload"] = 2] = "Upload";
    /** Uploaded audio - potentially a fully produced recording */
    ClipSource[ClipSource["UploadVideo"] = 3] = "UploadVideo";
})(ClipSource = exports.ClipSource || (exports.ClipSource = {}));
var DialogState;
(function (DialogState) {
    DialogState[DialogState["Empty"] = 0] = "Empty";
    DialogState[DialogState["Waiting"] = 1] = "Waiting";
    DialogState[DialogState["Ready"] = 2] = "Ready";
})(DialogState = exports.DialogState || (exports.DialogState = {}));
var AuthState;
(function (AuthState) {
    // Application is awaiting initialization payload
    AuthState[AuthState["Initializing"] = 0] = "Initializing";
    // [Failed] - Initialization call failed so the application cannot initialize.
    AuthState[AuthState["InitializationFailed"] = 1] = "InitializationFailed";
    // User is not logged in and needs to do so
    AuthState[AuthState["AuthRequired"] = 2] = "AuthRequired";
    // Process for acquiring a third-paty auth token is taking place
    AuthState[AuthState["ThirdPartyAuthorizing"] = 3] = "ThirdPartyAuthorizing";
    // Third party token has been received and application is waiting for organization info for user.
    AuthState[AuthState["ThirdPartyAuthorized"] = 4] = "ThirdPartyAuthorized";
    // [Failed] - Third-party authentication token retrieval failed
    AuthState[AuthState["ThirdPartyAuthFailed"] = 5] = "ThirdPartyAuthFailed";
    // Displayed when user is in multiple organizations and needs to choose one to login to.
    AuthState[AuthState["OrganizationSelect"] = 6] = "OrganizationSelect";
    // Process for acquiring a Soundbite API token is taking place
    AuthState[AuthState["Authorizing"] = 7] = "Authorizing";
    // [Failed] - Soundbite API token retrieval failed
    AuthState[AuthState["AuthFailed"] = 8] = "AuthFailed";
    // Soundbite API token has been received and is available for use
    AuthState[AuthState["Authorized"] = 9] = "Authorized";
    // Occurs when the user is switching to another organization to which they have access but no token
    AuthState[AuthState["OrganizationSwitch"] = 10] = "OrganizationSwitch";
})(AuthState = exports.AuthState || (exports.AuthState = {}));
