"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserStore = void 0;
const mobx_1 = require("mobx");
const api_1 = require("@soundbite/api");
/**
 * MobX store class containing states and actions for users
 */
class UserStoreClass {
    //////////[ Constructor ]/////////////////////////////////////////////////////////////////////////////
    constructor() {
        //////////[ Observable Data Properties ]//////////////////////////////////////////////////////////
        /**
         * Stores a reference to the current user's information.
         */
        this.currentUser = undefined;
        /**
         * Flag indicating whether the current user's information is loaded.
         */
        this.currentUserIsLoaded = false;
        (0, mobx_1.makeObservable)(this, {
            currentUser: mobx_1.observable,
            currentUserIsLoaded: mobx_1.observable,
            inviteUsersAsync: mobx_1.action,
            updateCurrentUserImageUrl: mobx_1.action,
        });
    }
    //////////[ Actions ]/////////////////////////////////////////////////////////////////////////////
    /**
     * Allows the system to update the current user image URL with a new value.
     * @param userImgageUrl - new user image URL
     */
    updateCurrentUserImageUrl(userImgageUrl) {
        if (this.currentUser) {
            this.currentUser.imageSrc = userImgageUrl;
        }
    }
    /**
     * Invites a user to the platform
     * @param invites - invite information (e.g. email address)
     */
    inviteUsersAsync(invites) {
        return __awaiter(this, void 0, void 0, function* () {
            yield api_1.UsersService.inviteAsync(invites);
        });
    }
}
// Export a singleton instance of the store
exports.UserStore = new UserStoreClass();
