"use strict";
// This file is directly imported from the react-select-async-paginate project due to React version conflicts
// https://github.com/vtaits/react-select-async-paginate
// At some point, we should catch up to React 17, then it should be possible to replace these files with the real npm package again
Object.defineProperty(exports, "__esModule", { value: true });
exports.useComponents = exports.useComponentsPure = exports.MenuList = void 0;
const react_1 = require("react");
const react_select_1 = require("react-select");
const wrapMenuList_1 = require("./wrapMenuList");
exports.MenuList = (0, wrapMenuList_1.wrapMenuList)(react_select_1.components.MenuList);
const useComponentsPure = (useMemoParam, components) => useMemoParam(() => (Object.assign({ MenuList: exports.MenuList }, components)), [components]);
exports.useComponentsPure = useComponentsPure;
const useComponents = (components) => (0, exports.useComponentsPure)(react_1.useMemo, components);
exports.useComponents = useComponents;
