"use strict";
/***************************************************************************************************
 * Auto-Generated File - do not modify because all changes will be lost
 **************************************************************************************************/
Object.defineProperty(exports, "__esModule", { value: true });
exports.ClipHostingType = void 0;
/**
* Automatically generated enum for Soundbite.ClipHostingType
*/
var ClipHostingType;
(function (ClipHostingType) {
    ClipHostingType[ClipHostingType["None"] = 0] = "None";
    ClipHostingType[ClipHostingType["AzureStorage"] = 10] = "AzureStorage";
    ClipHostingType[ClipHostingType["AzureStreaming"] = 20] = "AzureStreaming";
    ClipHostingType[ClipHostingType["SbStreaming"] = 30] = "SbStreaming";
})(ClipHostingType = exports.ClipHostingType || (exports.ClipHostingType = {}));
