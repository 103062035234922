"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SingletonDialogBase = void 0;
const react_1 = __importStar(require("react"));
const reactstrap_1 = require("reactstrap");
/** Abstract base class for a dialog that is always on the page and able to dynamically accept input to simplify its display */
class SingletonDialogBase extends react_1.Component {
    constructor() {
        super(...arguments);
        this.state = {
            isOpen: false,
            data: undefined,
        };
        this.isBig = false;
        this.headerClassname = "bg-gradient-primary";
    }
    close() {
        this.setState({ isOpen: false });
        this.state.onClose && this.state.onClose(this.state.data);
    }
    submit() {
        this.setState({ isOpen: false });
        this.state.onSubmit && this.state.onSubmit(this.state.data);
    }
    open(data, onSubmit, onClose) {
        this.setState({
            isOpen: true,
            data: data,
            onSubmit: onSubmit,
            onClose: onClose,
        });
    }
    modalFooter() {
        return (react_1.default.createElement(react_1.default.Fragment, null,
            react_1.default.createElement("button", { type: "button", className: "btn btn-outline-secondary", onClick: this.close.bind(this), title: "Close" }, "Close"),
            " ",
            react_1.default.createElement("button", { type: "button", className: "btn btn-primary", onClick: this.submit.bind(this), title: "Add" }, "Add")));
    }
    renderFooter() {
        const childFooter = this.modalFooter();
        if (childFooter) {
            return react_1.default.createElement(reactstrap_1.ModalFooter, null, childFooter);
        }
        else
            return react_1.default.createElement(react_1.default.Fragment, null);
    }
    render() {
        let modalClassName = this.isBig ? "sb-big-dialog" : "";
        return (react_1.default.createElement(reactstrap_1.Modal, { isOpen: this.state.isOpen, toggle: this.close.bind(this), className: modalClassName, backdrop: "static" },
            react_1.default.createElement(reactstrap_1.ModalHeader, { toggle: this.close.bind(this), className: this.headerClassname }, this.modalHeader()),
            react_1.default.createElement(reactstrap_1.ModalBody, null, this.modalBody()),
            this.renderFooter()));
    }
}
exports.SingletonDialogBase = SingletonDialogBase;
