"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MediaPlayerContextFactory = void 0;
const api_1 = require("@soundbite/api");
const AzureMediaPlayerContext_1 = require("./AzureMediaPlayerContext");
const HlsMediaPlayerContext_1 = require("./HlsMediaPlayerContext");
const MediaPlayerContext_1 = require("./MediaPlayerContext");
const SbMediaPlayerContext_1 = require("./SbMediaPlayerContext");
/**
 * MediaPlayerContext represents media playback operations and player state information.
 */
class MediaPlayerContextFactoryClass {
    //////////[ Methods ]/////////////////////////////////////////////////////////////////////////////
    getContext(clip, mediaUrl) {
        const typeName = this.getTypeName(clip, mediaUrl);
        switch (typeName) {
            case "audio":
                return new MediaPlayerContext_1.MediaPlayerContext("audio");
            case "video":
                return new MediaPlayerContext_1.MediaPlayerContext("video");
            case "azurestreaming":
                return new AzureMediaPlayerContext_1.AzureMediaPlayerContext();
            case "hlsstreaming":
                return new HlsMediaPlayerContext_1.HlsMediaPlayerContext();
            case "sbstreaming":
                return new SbMediaPlayerContext_1.SbMediaPlayerContext();
            default:
                throw new Error(`Failed to create a MediaPlayerContext instance for the specifid clip/url. (typename=${typeName})`);
        }
    }
    /**
     * Determines the appropriate media context for the clip
     * @clip - reference to the clip for which the context is being constructed.
     * @mediaUrl - reference to the media URL for which the context is being constructed. Note that
     *   the mediaUrl is primarily used for displaying local file blobs recorded from the browser.
     */
    getTypeName(clip, mediaUrl) {
        let result;
        // Make sure we have a clip with which to work
        if (clip) {
            result = this.getTypeNameByClip(clip);
        }
        else if (mediaUrl) {
            result = this.getTypeNameByMediaUrl(mediaUrl);
        }
        else {
            throw new Error("Cannot get MediaPlayerContext type name without a clip or media URL.");
        }
        return result;
    }
    getTypeNameByClip(clip) {
        let result = "UNKNOWN";
        if (api_1.Utils.IsVideo(clip.fileType)) {
            switch (clip.hostingType) {
                case api_1.ClipHostingType.AzureStreaming:
                    result = "hlsstreaming";
                    break;
                case api_1.ClipHostingType.AzureStorage:
                    result = "video";
                    break;
                case api_1.ClipHostingType.SbStreaming:
                    result = "sbstreaming";
                    break;
                default:
                    throw new Error(`Cannot determine MediaPlayerContext type name because the hosting type is not implemented (type=${clip.hostingType}).`);
            }
        }
        else {
            result = "audio";
        }
        return result;
    }
    getTypeNameByMediaUrl(mediaUrl) {
        if (!api_1.Utils.isNullOrEmpty(mediaUrl)) {
            const parts = mediaUrl.split(".");
            const ext = parts[parts.length - 1].toLowerCase();
            switch (ext) {
                case "mp3":
                case "mpg":
                    return "audio";
                case "webm":
                case "mp4":
                    return "video";
                default:
                    throw new Error("Cannot determine MediaPlayerContext type name because the media URL extension is unrecognized.");
            }
        }
        else {
            throw new Error("Cannot determine MediaPlayerContext type name because the media URL is missing.");
        }
    }
}
exports.MediaPlayerContextFactory = new MediaPlayerContextFactoryClass();
