"use strict";
/***************************************************************************************************
 * Auto-Generated File - do not modify because all changes will be lost
 **************************************************************************************************/
Object.defineProperty(exports, "__esModule", { value: true });
exports.TokenSecurityLevel = void 0;
/**
* Automatically generated enum for Masticore.TokenSecurityLevel
*/
var TokenSecurityLevel;
(function (TokenSecurityLevel) {
    TokenSecurityLevel[TokenSecurityLevel["Default"] = 0] = "Default";
    TokenSecurityLevel[TokenSecurityLevel["Tenant"] = 10] = "Tenant";
    TokenSecurityLevel[TokenSecurityLevel["Organization"] = 20] = "Organization";
})(TokenSecurityLevel = exports.TokenSecurityLevel || (exports.TokenSecurityLevel = {}));
