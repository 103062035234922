"use strict";
/***************************************************************************************************
 * Auto-Generated File - do not modify because all changes will be lost
 **************************************************************************************************/
Object.defineProperty(exports, "__esModule", { value: true });
exports.SessionSummaryType = void 0;
/**
* Automatically generated enum for Soundbite.Services.SessionSummaryType
*/
var SessionSummaryType;
(function (SessionSummaryType) {
    SessionSummaryType[SessionSummaryType["Paragraph"] = 0] = "Paragraph";
    SessionSummaryType[SessionSummaryType["Social"] = 10] = "Social";
    SessionSummaryType[SessionSummaryType["Tweet"] = 20] = "Tweet";
    SessionSummaryType[SessionSummaryType["Blog"] = 30] = "Blog";
    SessionSummaryType[SessionSummaryType["Newsletter"] = 40] = "Newsletter";
})(SessionSummaryType = exports.SessionSummaryType || (exports.SessionSummaryType = {}));
