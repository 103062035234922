"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.VideoEditorService = void 0;
const ffmpeg_1 = require("@ffmpeg/ffmpeg");
class VideoEditorServiceClass {
    constructor() {
        this.ffmpeg = (0, ffmpeg_1.createFFmpeg)({
            log: true,
            corePath: `${window.location.origin}/ffmpeg-core.js`,
        });
        this.loadPromise = undefined;
    }
    /**
     * Retrieves a promise that indicates when ffmpeg is loaded.  When first called, the method calls
     * ffmpeg.load which downloads the ffmpeg WASM libraries.  When ffmpeg is loaded and ready to
     * run video editing commands, the promise resolves.
     */
    ensureLoaded() {
        if (!this.loadPromise) {
            this.loadPromise = new Promise((resolve, reject) => {
                this.ffmpeg
                    .load()
                    .then(() => resolve())
                    .catch((ex) => reject(ex));
            });
        }
        return this.loadPromise;
    }
    /**
     * Trims the start of the video clip by the specified duration.
     * @videoClip - the video clip to update
     * @duration - the amount of time to trim from the front of the video
     */
    trimStart(videoClip, duration) {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            // TODO: Convert this to use mp4 instead of web
            if (videoClip === null || videoClip === void 0 ? void 0 : videoClip.file) {
                // Save off original data (if not already set)
                videoClip.originalFile = (_a = videoClip.originalFile) !== null && _a !== void 0 ? _a : videoClip.file;
                yield this.ensureLoaded();
                // TODO: Convert this to use mp4 instead of web
                this.ffmpeg.FS("writeFile", "temp_in.webm", yield (0, ffmpeg_1.fetchFile)(videoClip.file));
                yield this.ffmpeg.run("-ss", `${duration}`, "-i", "temp_in.webm", "-c:a", "copy", "-c:v", "copy", "temp_out.webm");
                // TODO: Convert this to use mp4 instead of web
                const output = this.ffmpeg.FS("readFile", "temp_out.webm");
                const blob = new Blob([output.buffer], { type: "video/webm" });
                videoClip.file = new File([blob], "clip.webm");
                videoClip.url = URL.createObjectURL(videoClip.file);
            }
        });
    }
}
exports.VideoEditorService = new VideoEditorServiceClass();
