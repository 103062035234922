"use strict";
/***************************************************************************************************
 * Auto-Generated File - do not modify because all changes will be lost
 **************************************************************************************************/
Object.defineProperty(exports, "__esModule", { value: true });
exports.SessionType = void 0;
/**
* Automatically generated enum for Soundbite.SessionType
*/
var SessionType;
(function (SessionType) {
    SessionType[SessionType["Memo"] = 0] = "Memo";
    SessionType[SessionType["Message"] = 1] = "Message";
    SessionType[SessionType["Announcement"] = 2] = "Announcement";
    SessionType[SessionType["Survey"] = 3] = "Survey";
    SessionType[SessionType["Meeting"] = 4] = "Meeting";
})(SessionType = exports.SessionType || (exports.SessionType = {}));
