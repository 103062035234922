"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FeedWidget = exports.FeedMode = void 0;
/** @jsx jsx */
const react_1 = require("@emotion/react");
const mobx_react_lite_1 = require("mobx-react-lite");
const TableFeedWidget_1 = require("./TableFeedWidget");
const CardFeedWidget_1 = require("./CardFeedWidget");
var FeedMode;
(function (FeedMode) {
    FeedMode[FeedMode["Table"] = 0] = "Table";
    FeedMode[FeedMode["Card"] = 1] = "Card";
})(FeedMode = exports.FeedMode || (exports.FeedMode = {}));
/***************************************************************************************************
 *  Component
 **************************************************************************************************/
exports.FeedWidget = (0, mobx_react_lite_1.observer)((props) => {
    if (props.mode === FeedMode.Card) {
        return (0, react_1.jsx)(CardFeedWidget_1.CardFeedWidget, Object.assign({}, props));
    }
    return (0, react_1.jsx)(TableFeedWidget_1.TableFeedWidget, Object.assign({}, props));
});
