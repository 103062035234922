"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ThemePicker = void 0;
/** @jsx jsx */
const react_1 = require("@emotion/react");
const react_2 = require("react");
const reactstrap_1 = require("reactstrap");
const api_1 = require("@soundbite/api");
const controls_1 = require("../controls");
const styles_1 = require("../../styles");
const store_1 = require("../../store");
const ThemeConfig_1 = require("./ThemeConfig");
const dialogs_1 = require("../../dialogs");
const baseStyles = (0, react_1.css) `
  .sb-theme-toggle {
    display: flex;
    align-items: center;
    padding: 0.5rem;
    background-color: ${styles_1.GlobalTheme.current.colors.neutrals.midground};
  }

  .sb-theme-toggle label {
    margin: 0 0.5rem;
  }
`;
class ThemePicker extends react_2.Component {
    constructor(props) {
        super(props);
        this.onCancel = this.onCancel.bind(this);
        this.onEdit = this.onEdit.bind(this);
        this.state = {
            isEditing: styles_1.GlobalTheme.current.name === api_1.ThemeName.Draft,
        };
    }
    onEdit() {
        this.setState({ isEditing: true });
    }
    onPreview() {
        var _a;
        // TODO: Replace with currentOrgTheme ?? lightTheme logic
        const lightThemeJson = JSON.stringify(styles_1.lightTheme);
        const currentThemeJson = JSON.stringify(styles_1.GlobalTheme.current);
        if (lightThemeJson !== currentThemeJson) {
            styles_1.GlobalTheme.current.name = api_1.ThemeName.Draft;
            const orgRoute = (_a = store_1.WidgetStore.organizations.currentOrg) === null || _a === void 0 ? void 0 : _a.details.route;
            if (orgRoute == null) {
                return;
            }
            styles_1.GlobalTheme.set(styles_1.GlobalTheme.current, orgRoute, true);
            styles_1.GlobalTheme.apply();
        }
    }
    onCancel() {
        return __awaiter(this, void 0, void 0, function* () {
            this.setState({ isEditing: false });
        });
    }
    onReset() {
        dialogs_1.DialogManager.ShowConfirmDialog("Reset Theme", "Are you sure you want to reset the theme for your organization? All users will see the reset them after their next full refresh", () => __awaiter(this, void 0, void 0, function* () {
            var _a;
            const orgRoute = (_a = store_1.WidgetStore.organizations.currentOrg) === null || _a === void 0 ? void 0 : _a.details.route;
            if (orgRoute == null) {
                return;
            }
            yield api_1.OrganizationsService.updateThemeAsync(orgRoute, undefined);
            styles_1.GlobalTheme.clear(orgRoute);
            styles_1.GlobalTheme.apply();
        }), "Reset", dialogs_1.DialogStyleType.Danger);
    }
    render() {
        var _a;
        const isDraft = styles_1.GlobalTheme.current.name === api_1.ThemeName.Draft;
        return ((0, react_1.jsx)(reactstrap_1.Card, { css: baseStyles, className: "mb-2" },
            (0, react_1.jsx)(reactstrap_1.CardHeader, null,
                (0, react_1.jsx)("h1", { className: "mb-0" }, `${(_a = store_1.WidgetStore.organizations.currentOrg) === null || _a === void 0 ? void 0 : _a.details.name} Theme: ${styles_1.GlobalTheme.current.name}`),
                (0, react_1.jsx)("p", { className: "mt-0 text-muted" }, "Customize the visual styling of your organization on Soundbite"),
                (0, react_1.jsx)(controls_1.ShowWhen, { is: this.state.isEditing && !isDraft },
                    (0, react_1.jsx)(ThemeConfig_1.ThemeConfig, null))),
            (0, react_1.jsx)(controls_1.ShowWhen, { is: !isDraft },
                (0, react_1.jsx)(reactstrap_1.CardFooter, { className: "text-right" },
                    (0, react_1.jsx)(controls_1.ShowWhen, { is: !this.state.isEditing },
                        (0, react_1.jsx)(reactstrap_1.Button, { color: "primary", onClick: this.onEdit }, "Edit"),
                        (0, react_1.jsx)(controls_1.ShowWhen, { is: styles_1.GlobalTheme.current.name === api_1.ThemeName.Custom },
                            (0, react_1.jsx)(reactstrap_1.Button, { color: "secondary", className: "sb-link-simple-btn", onClick: this.onReset }, "Reset"))),
                    (0, react_1.jsx)(controls_1.ShowWhen, { is: this.state.isEditing },
                        (0, react_1.jsx)(reactstrap_1.Button, { color: "secondary", outline: true, onClick: this.onCancel }, "Cancel"))))));
    }
}
exports.ThemePicker = ThemePicker;
