"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ThemeBanner = void 0;
/** @jsx jsx */
const react_1 = require("@emotion/react");
const react_2 = __importStar(require("react"));
const reactstrap_1 = require("reactstrap");
const api_1 = require("@soundbite/api");
const styles_1 = require("../../styles");
const ShowWhen_1 = require("../controls/ShowWhen");
const ThemeConfig_1 = require("./ThemeConfig");
const dialogs_1 = require("../../dialogs");
const WidgetStore_1 = require("../../store/WidgetStore");
const styles = (0, react_1.css) `
  background-color: ${styles_1.GlobalTheme.current.colors.neutrals.midground};
  border-bottom: 3px solid ${styles_1.GlobalTheme.current.colors.neutrals.background};
  color: ${styles_1.GlobalTheme.current.colors.neutrals.max};
  z-index: 3;
  padding: 1rem;

  @media (min-width: 768px) {
    margin-left: 250px;
  }
`;
/** A bar for the top of the page used to indicate theme configuration status and actions */
const ThemeBanner = () => {
    var _a, _b;
    const [isEditing, setIsEditing] = (0, react_2.useState)(false);
    function onEdit() {
        setIsEditing(!isEditing);
    }
    function onPublish() {
        return __awaiter(this, void 0, void 0, function* () {
            dialogs_1.DialogManager.ShowConfirmDialog("Publish Theme", "Are you sure you want to publish this theme? The next time users fully refresh Soundbite, they will see your new theme", () => __awaiter(this, void 0, void 0, function* () {
                var _a;
                const orgRoute = (_a = WidgetStore_1.WidgetStore.organizations.currentOrg) === null || _a === void 0 ? void 0 : _a.details.route;
                if (orgRoute == null) {
                    return;
                }
                styles_1.GlobalTheme.current.name = api_1.ThemeName.Custom;
                const newTheme = yield api_1.OrganizationsService.updateThemeAsync(orgRoute, styles_1.GlobalTheme.current);
                styles_1.GlobalTheme.set(newTheme, orgRoute, true);
                styles_1.GlobalTheme.apply();
            }));
        });
    }
    function onAbandon() {
        return __awaiter(this, void 0, void 0, function* () {
            dialogs_1.DialogManager.ShowConfirmDialog("Abandon Draft", "Are you sure you want to abandon your draft theme?", () => {
                var _a, _b, _c, _d;
                const orgTheme = (_b = (_a = WidgetStore_1.WidgetStore.organizations.currentOrg) === null || _a === void 0 ? void 0 : _a.settings) === null || _b === void 0 ? void 0 : _b.theme;
                if (orgTheme != null) {
                    const orgRoute = (_c = WidgetStore_1.WidgetStore.organizations.currentOrg) === null || _c === void 0 ? void 0 : _c.details.route;
                    if (orgRoute != null) {
                        styles_1.GlobalTheme.set(orgTheme, orgRoute, true);
                    }
                }
                else {
                    styles_1.GlobalTheme.clear((_d = WidgetStore_1.WidgetStore.organizations.currentOrg) === null || _d === void 0 ? void 0 : _d.details.route);
                }
                styles_1.GlobalTheme.apply();
            }, "Abandon", dialogs_1.DialogStyleType.Danger);
        });
    }
    const asserts = ((_b = (_a = styles_1.GlobalTheme.lastTester) === null || _a === void 0 ? void 0 : _a.asserts) !== null && _b !== void 0 ? _b : []).filter((assert) => assert.message != null);
    return ((0, react_1.jsx)(react_2.default.Fragment, null,
        (0, react_1.jsx)(ShowWhen_1.ShowWhen, { is: styles_1.GlobalTheme.isNeedReload },
            (0, react_1.jsx)("div", { className: "", css: styles },
                (0, react_1.jsx)(reactstrap_1.Row, null,
                    (0, react_1.jsx)(reactstrap_1.Col, null, "New Theme is Available; Reload to Apply"),
                    (0, react_1.jsx)(reactstrap_1.Col, { className: "text-right" },
                        (0, react_1.jsx)(reactstrap_1.Button, { className: "btn-sm", color: "primary", onClick: () => {
                                styles_1.GlobalTheme.apply();
                            } }, "Reload"))))),
        (0, react_1.jsx)(ShowWhen_1.ShowWhen, { is: styles_1.GlobalTheme.current.name === api_1.ThemeName.Draft },
            (0, react_1.jsx)("div", { className: "", css: styles },
                (0, react_1.jsx)("h1", { className: "text-muted text-sm uppercase" }, "DRAFT THEME"),
                (0, react_1.jsx)("p", { className: "text-muted" }, "You are previewing this draft theme. Until you publish your changes, no one else will see these changes."),
                (0, react_1.jsx)(ShowWhen_1.ShowWhen, { is: asserts.length > 0 },
                    (0, react_1.jsx)("div", { className: "bg-warning p-2 rounded mb-2" },
                        (0, react_1.jsx)("h2", { className: "text-white text-sm text-uppercase" }, "Theme Warnings"),
                        (0, react_1.jsx)("ul", null, asserts.map((assert) => {
                            return (0, react_1.jsx)("li", null, assert.message);
                        })))),
                (0, react_1.jsx)(ShowWhen_1.ShowWhen, { is: isEditing },
                    (0, react_1.jsx)(ThemeConfig_1.ThemeConfig, null)),
                (0, react_1.jsx)("div", { className: "text-right" },
                    (0, react_1.jsx)(reactstrap_1.Button, { color: "secondary", onClick: onPublish }, "Publish"),
                    (0, react_1.jsx)(reactstrap_1.Button, { color: "secondary", onClick: onEdit, outline: !isEditing }, "Edit"),
                    (0, react_1.jsx)(reactstrap_1.Button, { color: "secondary", outline: true, onClick: onAbandon }, "Abandon"))))));
};
exports.ThemeBanner = ThemeBanner;
